import React, { useState, useEffect } from 'react';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import 'pdfjs-dist/web/pdf_viewer.css';
import ArrowLeft from '../../assets/PetPage/arrow_left_dashed_small.svg';
import ArrowRight from '../../assets/PetPage/arrow_right_dashed.svg';
import { useTranslation } from 'react-i18next';

pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const PDFViewer = ({ pdfUrl }) => {
  const { t } = useTranslation();
  const [pdf, setPdf] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const loadPdf = async () => {
      const loadingTask = pdfjsLib.getDocument(pdfUrl);
      const loadedPdf = await loadingTask.promise;
      setPdf(loadedPdf);
      setTotalPages(loadedPdf.numPages);
    };
    loadPdf();
  }, [pdfUrl]);

  const renderPage = async (pageNum) => {
    if (pdf) {
      const page = await pdf.getPage(pageNum);
      const viewport = page.getViewport({ scale: 1.5 });
      const canvas = document.getElementById('pdf-canvas');
      const context = canvas.getContext('2d');
      canvas.width = viewport.width;
      canvas.height = viewport.height;

      const renderContext = {
        canvasContext: context,
        viewport,
      };

      page.render(renderContext);
    }
  };

  useEffect(() => {
    if (pdf) {
      renderPage(pageNumber);
    }
  }, [pdf, pageNumber]);

  const handlePreviousPage = () => {
    setPageNumber((prevPageNumber) => Math.max(prevPageNumber - 1, 1));
  };

  const handleNextPage = () => {
    setPageNumber((prevPageNumber) => Math.min(prevPageNumber + 1, totalPages));
  };

  return (
    <div>
      <canvas
        id="pdf-canvas"
        style={{ width: '100%', border: '1px solid black' }}
      ></canvas>
      {totalPages > 1 && (
        <div className="pdf-viewer pagination">
          <div
            className="policy-table-mobile__back"
            style={pageNumber <= 1 ? { opacity: '0.5' } : {}}
          >
            <div
              className="button_red_mobile back margin-0"
              onClick={handlePreviousPage}
            >
              <img src={ArrowLeft} alt="ArrowLeft" />
            </div>
          </div>
          <span style={{ margin: '0 10px' }}>
            {t('page')} {pageNumber} / {totalPages}
          </span>
          <div
            className="policy-table-mobile__next"
            style={pageNumber >= totalPages ? { opacity: '0.5' } : {}}
          >
            <div
              className="button_red_mobile back margin-0"
              onClick={handleNextPage}
            >
              <img src={ArrowRight} alt="ArrowRight" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PDFViewer;
