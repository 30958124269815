import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { showToast } from '../../../../config/helpers';
import ModalWrapper from '../../../../components/modalWrapper';
import Attach from '../../../../assets/iconsSmall/paper_clip.svg';
import Remove from '../../../../assets/iconsSmall/cross_black.svg';
import Upload from '../../../../assets/iconsSmall/upload_icon.svg';
import { Request } from '../../../../config/requests';
import { Markup } from 'interweave';
import CustomSelect from '../../../../components/inputs/select/CustomSelect';
import Loading from '../../../../components/common/Loading';
import { useNavigate, useParams } from 'react-router-dom';
import Datepicker from '../../../../components/common/components/Datepicker';
import breedListDogs from '../../../../assets/dog_breeds.json';
import breedListCats from '../../../../assets/cat_breeds.json';
import moment from 'moment';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { CHANGE_PET_DATA_FLOW } from '../../../../constants/Flow';

function ModalPetData({
  openModalPetData,
  setOpenModalPetData,
  initialPetType,
  petData,
  refetchPetData,
}) {
  // console.log('ModalPetData --> initialPetType', initialPetType)
  // console.log('ModalPetData --> petData', petData)
  const { t } = useTranslation();
  const router = useNavigate();
  const [dialog, setDialog] = useState('main');
  // console.log('ModalPetData --> dialog', dialog)
  const [dataType, setDataType] = useState('');
  // console.log('ModalPetData --> dataType', dataType)
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [breedOptions, setBreedOptions] = useState([]);
  const [chipError, setChipError] = useState(false);
  const [petType, setPetType] = useState(petData.data.petType || 'Dog');
  const [petName, setPetName] = useState('');
  const [petBreedType, setPetBreedType] = useState('');
  // console.log('ModalPetData --> petBreedType', petBreedType)
  const [petBreed, setPetBreed] = useState('');
  const [petFirstBreed, setPetFirstBreed] = useState('');
  // console.log('ModalPetData --> petFirstBreed', petFirstBreed)
  const [petSecondBreed, setPetSecondBreed] = useState('');
  // console.log('ModalPetData --> petSecondBreed', petSecondBreed)
  const [petBD, setPetBD] = useState('');
  const [petChip, setPetChip] = useState('');
  const [petGender, setPetGender] = useState('');
  const [dogHeight, setDogHeight] = useState('');
  const [catHabitat, setCatHabitat] = useState('');
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const { id } = useParams();
  const [selectedDataToChange, setSelectedDataToChange] = React.useState(null);

  // Calculate date limits
  const today = new Date();

  // 8 weeks ago
  const minDate = new Date(today);
  minDate.setDate(today.getDate() - 8 * 7); // 8 weeks = 8 * 7 days

  // 9 years ago
  const maxDate = new Date(today);
  maxDate.setFullYear(today.getFullYear() - 9); // Go back 9 years

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (dialog != 'main') setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    } else {
      setSelectedDataToChange(null);
      setDialog('main');
    }
  };

  const headerLabels = {
    main: t('changeData'),
    petNameChange: t('petNameChange'),
    petBreedChange: t('petBreedChange'),
    petBDChange: t('petBDChange'),
    petChipChange: t('petChipChange'),
    petGenderChange: t('petGenderChange'),
    dogHeightChange: t('dogHeightChange'),
    catHabitatChange: t('catHabitatChange'),
  };

  const changeDataTypesDog = [
    { label: t('Pet name'), value: 'petNameChange' },
    { label: t('Pet breed'), value: 'petBreedChange' },
    { label: t('Pet birthday'), value: 'petBDChange' },
    { label: t('Pet chipnumber'), value: 'petChipChange' },
    { label: t('Pet gender'), value: 'petGenderChange' },
    { label: t('Dog height'), value: 'dogHeightChange' },
  ];

  const changeDataTypesCat = [
    { label: t('Pet name'), value: 'petNameChange' },
    { label: t('Pet breed'), value: 'petBreedChange' },
    { label: t('Pet birthday'), value: 'petBDChange' },
    { label: t('Pet chipnumber'), value: 'petChipChange' },
    { label: t('Pet gender'), value: 'petGenderChange' },
    { label: t('Cat habitat'), value: 'catHabitatChange' },
  ];

  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const files = event.target.files;
    const newFiles = Array.from(files).filter((file) => {
      return !selectedFiles.some(
        (selectedFile) => selectedFile.name === file.name
      );
    });
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    const newFiles = Array.from(files).filter((file) => {
      return !selectedFiles.some(
        (selectedFile) => selectedFile.name === file.name
      );
    });
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handlePetType = (e) => {
    setPetType(e.target.value);
    setPetBreed('');
    setPetFirstBreed('');
    setPetSecondBreed('');
  };

  const isValidNumber = (str) => {
    return /^[0-9]{15}$/.test(str);
  };

  const handleChip = (e) => {
    const { value } = e.target;
    if (value.length <= 15) {
      if (isValidNumber(value)) {
        setPetChip(value);
        setChipError(false);
      } else {
        setPetChip(value);
        setChipError(true);
      }
    }
  };

  function handleResetFlowValues() {
    setPetGender('');
    setDogHeight('');
    setCatHabitat('');
    setPetBreedType('');
    setPetBreed('');
    setPetFirstBreed('');
    setPetSecondBreed('');
  }

  function handleResetBreedValues() {
    setPetBreed('');
    setPetFirstBreed('');
    setPetSecondBreed('');
    setDogHeight('');
  }

  function handleClose() {
    setDataType(null);
    setSelectedFiles([]);
    setDialog('main');
    setOpenModalPetData(false);
  }

  async function handleSubmit() {
    setSubmitting(true);
    const fd = new FormData();
    selectedFiles.forEach((i) => fd.append('files', i));
    fd.append('reportType', 'Change pet data');
    fd.append('customerEmail', petData?.quote?.customer?.email);
    fd.append('policyNo', petData?.policy?.policyNo);
    fd.append('policyId', petData?.quote?.policy?.id);
    fd.append('petType', petType);
    fd.append('breedType', petBreedType);
    fd.append('breed', petBreed?.label || '');
    fd.append('hybridBreed1', petFirstBreed?.label || '');
    fd.append('hybridBreed2', petSecondBreed?.label || '');
    fd.append('nameOfPet', petName);
    fd.append('dateOfBirth', petBD ? moment(petBD).format('DD.MM.YYYY') : '');
    fd.append('chipNumber', petChip);
    fd.append('petGender', petGender);
    fd.append('sizeOfPet', dogHeight);
    fd.append('catType', catHabitat);

    try {
      await Request({
        method: 'put',
        route: `api/pet/${id}?isFromCustomerPortal=true`,
        formData: fd,
      });
      setDialog('thankYouYes');
      setSubmitting(false);
      await refetchPetData();
    } catch (e) {
      console.error(e);
      setSubmitting(false);
      handleClose();
      showToast(t('somethingWentWrong'), { error: true });
    }
  }

  async function requestChange() {
    setSubmitting(true);
    const fd = new FormData();
    selectedFiles.forEach((i) => fd.append('files', i));
    fd.append('reportType', 'Change pet data');
    fd.append('customerEmail', petData?.quote?.customer?.email);
    fd.append('policyNo', petData?.policy?.policyNo);
    fd.append('policyId', petData?.quote?.policy?.id);
    fd.append('petType', petType);
    fd.append('breedType', petBreedType);
    fd.append('breed', petBreed?.label || '');
    fd.append('hybridBreed1', petFirstBreed?.label || '');
    fd.append('hybridBreed2', petSecondBreed?.label || '');
    fd.append('nameOfPet', petName);
    fd.append('dateOfBirth', petBD ? moment(petBD).format('DD.MM.YYYY') : '');
    fd.append('chipNumber', petChip);
    fd.append('petGender', petGender);
    fd.append('sizeOfPet', dogHeight);
    fd.append('catType', catHabitat);

    // To send request (Email to Panda Mail)
    return Request({
      method: 'post',
      route: 'api/users/change_data_request',
      formData: fd,
    })
      .then((res) => {
        if (!res.ok)
          throw new Error(`Request failed with status ${res.status}`);
      })
      .catch(() => {
        showToast(t('somethingWentWrong'), { error: true });
        router('/');
      })
      .finally(() => {
        setSubmitting(false);
        setDialog('thankYouYes');
      });
  }

  useEffect(() => {
    let options = [];
    let breedList = petType === 'Dog' ? breedListDogs : breedListCats;

    breedList.forEach((el) => {
      options.push({ label: el.value_name, value: el.value });
    });
    if (petBreedType === 'Pure') options.shift();

    if (petFirstBreed) {
      options = options.filter((breed) => breed.value !== petFirstBreed.value);
    }
    if (petSecondBreed) {
      options = options.filter((breed) => breed.value !== petSecondBreed.value);
    }
    setBreedOptions(options);
  }, [petType, petBreedType, petFirstBreed, petSecondBreed]);

  useEffect(() => {
    if (dialog != 'main' && dialog != 'thankYouNo' && dialog != 'thankYouYes') {
      setSelectedDataToChange(
        Object.values(CHANGE_PET_DATA_FLOW).find((el) => el.title == dialog)
      );
    }

    if (dialog === 'main') {
      handleResetFlowValues();
    }

    if (dialog === 'Pet breed') {
      initialPetType === 'DOG' ? setPetType('Dog') : setPetType('Cat');
    }
  }, [dialog]);

  return (
    <ModalWrapper
      headerTitle={headerLabels[dialog === 'main' ? ' main' : dataType?.value]}
      openModal={openModalPetData}
      setModalWrapper={() => handleClose()}
      topic="changePetData"
      petData={petData}
      selectedDataToChange={selectedDataToChange}
    >
      {dialog === 'main' && (
        <>
          <div
            className="modal_container__body__text"
            style={{ maxWidth: 'unset', margin: '0' }}
          >
            {t('whichPetDataToChange')}
          </div>
          <div
            className="modal_container__body__inputs"
            style={{ margin: '20px 0' }}
          >
            <CustomSelect
              variant="modal-cancel-contract"
              options={
                initialPetType === 'DOG'
                  ? changeDataTypesDog
                  : changeDataTypesCat
              }
              formatCreateLabel={(i) => i}
              placeholder={t('pleaseSelectHere')}
              value={dataType}
              onChange={(e) => setDataType(e)}
              isClearable={true}
            />
          </div>
          <div
            className={
              dataType !== null
                ? `button_red_small ${isMobileOnly && 'mobile'}`
                : `button_red_smal_disabled ${isMobileOnly && 'mobile'}`
            }
            onClick={
              dataType !== null
                ? () => {
                  setDialog(dataType.value);
                  handleNext();
                }
                : (e) => e.stopPropagation()
            }
          >
            {t('Next')}
          </div>
        </>
      )}

      {selectedDataToChange &&
        selectedDataToChange?.steps &&
        Object.values(selectedDataToChange.steps).map((el, index) => {
          if (index == activeStep) {
            return (
              <>
                <div
                  key={el.id}
                  className="modal_container__body__text"
                  style={{ maxWidth: '540px', margin: '0' }}
                >
                  {t(el.text)}
                </div>

                {el.type == 'multiSteps' && (
                  <div
                    className="modal_container__body__inputs"
                    style={{ maxWidth: '540px', gap: '0' }}
                  >
                    {Object.values(el.steps).map((innerStep, innerIndex) => {
                      return (
                        <div key={innerIndex}>
                          {t(innerStep.title)}
                          {innerStep.type == 'radio' && (
                            <div className="modal_container__body__inputs">
                              {innerStep.options.map((option, optionIndex) => (
                                <div
                                  key={optionIndex}
                                  className="modal_container__body__radio"
                                  onClick={() => {
                                    if (innerStep.id == 'breedType') {
                                      handleResetBreedValues();
                                      setPetBreedType(option.value);
                                    } else if (innerStep.id == 'petType') {
                                      handlePetType(option.value);
                                    }
                                  }}
                                >
                                  <input
                                    type="radio"
                                    style={{ marginRight: '8px' }}
                                    value={option.value}
                                    checked={
                                      innerStep.id == 'breedType'
                                        ? petBreedType === option.value
                                        : innerStep.id == 'petType' &&
                                        petType === option.value
                                    }
                                  />
                                  <label
                                    style={{
                                      display: 'flex',
                                      marginBottom: '8px',
                                      alignItems: 'center'
                                    }}
                                  >
                                    {t(option.label)}
                                  </label>
                                </div>
                              ))}
                            </div>
                          )}
                          {innerStep.type == 'breeds-select' &&
                            petBreedType == 'Pure' &&
                            innerStep.dependency == petBreedType && (
                              <div className="modal_container__body__inputs">
                                {
                                  <>
                                    <CustomSelect
                                      variant="modal-cancel-contract"
                                      options={breedOptions}
                                      formatCreateLabel={(i) => i}
                                      placeholder={t('Bitte Rasse eintippen')}
                                      value={petBreed}
                                      onChange={(e) => setPetBreed(e)}
                                      isClearable={true}
                                      toTranslate={false}
                                    />
                                  </>
                                }
                              </div>
                            )}
                          {innerStep.type == 'breeds-select' &&
                            petBreedType == 'Hybrid' &&
                            innerStep.dependency == petBreedType && (
                              <div className="modal_container__body__inputs">
                                {
                                  <>
                                    <CustomSelect
                                      variant="modal-edit-data"
                                      options={breedOptions}
                                      formatCreateLabel={(i) => i}
                                      placeholder={t('Bitte Rasse eintippen')}
                                      value={petFirstBreed}
                                      onChange={(e) => setPetFirstBreed(e)}
                                      isClearable={true}
                                      toTranslate={false}
                                    />
                                    <CustomSelect
                                      variant="modal-cancel-contract"
                                      options={breedOptions}
                                      formatCreateLabel={(i) => i}
                                      placeholder={t('Bitte Rasse eintippen')}
                                      value={petSecondBreed}
                                      onChange={(e) => setPetSecondBreed(e)}
                                      isClearable={true}
                                      toTranslate={false}
                                    />
                                  </>
                                }
                              </div>
                            )}
                          {innerStep.type == 'radio-options' &&
                            (petType === 'Dog' &&
                              petBreedType == 'Unknown' ||
                              (petBreedType === 'Hybrid' && (petFirstBreed?.label === 'Unbekannt' || petSecondBreed?.label === 'Unbekannt'))) && (
                              <div
                                className="modal_container__body__row-modal"
                                style={{ margin: '0 0 20px 0' }}
                              >
                                <div
                                  className={`modal_container__body__button-modal ${isMobile ? 'wide' : ''
                                    } ${dogHeight === '0-44cm' ? '' : 'disabled'
                                    }`}
                                  onClick={() => setDogHeight('0-44cm')}
                                >
                                  0 - 44 cm
                                </div>
                                <div
                                  className={`modal_container__body__button-modal ${isMobile ? 'wide' : ''
                                    } ${dogHeight === '> 44cm' ? '' : 'disabled'
                                    }`}
                                  onClick={() => setDogHeight('> 44cm')}
                                >
                                  &gt; 44 cm
                                </div>
                              </div>
                            )}
                        </div>
                      );
                    })}
                    <div className="modal_container__body__row-modal ">
                      <div
                        className={`button_red_small ${isMobileOnly ? 'mobile' : ''}`}
                        style={{ width: 'unset' }}
                        onClick={() => handleBack()}
                      >
                        {t('Back')}
                      </div>
                      <div
                        className={`button_red_small ${isMobileOnly ? 'mobile' : ''
                          } ${petBreed ||
                            (petFirstBreed && petSecondBreed) ||
                            dogHeight ||
                            (petType === 'Cat' && petBreedType === 'Unknown')
                            ? ''
                            : 'disabled'
                          }`}
                        style={{ width: 'unset' }}
                        onClick={
                          petBreed ||
                            (petFirstBreed && petSecondBreed) ||
                            dogHeight ||
                            (petType === 'Cat' && petBreedType === 'Unknown')
                            ? () => {
                              handleSubmit();
                              handleNext();
                            }
                            : (e) => e.stopPropagation()
                        }
                      >
                        {t('sendChange')}
                      </div>
                    </div>
                  </div>
                )}

                {el.type == 'confirmation' && (
                  <>
                    <div
                      className="modal_container__body__text "
                      style={{ maxWidth: '540px' }}
                    >
                      {t(el.title)}
                    </div>
                    <div className="modal_container__body__row-modal ">
                      <div
                        className={`button_red_small ${isMobileOnly ? 'mobile' : ''
                          }`}
                        onClick={() => {
                          handleSubmit();
                          handleNext();
                        }}
                      >
                        {t('yes')}
                      </div>
                      <div
                        className={`button_red_small ${isMobileOnly ? 'mobile' : ''
                          }`}
                        onClick={() => {
                          setDialog('thankYouNo');
                          handleNext();
                        }}
                      >
                        {t('no')}
                      </div>
                    </div>
                  </>
                )}

                {el.type == 'radio-options' && (
                  <div
                    className="modal_container__body__inputs"
                    style={{ maxWidth: '540px' }}
                  >
                    <div className="modal_container__body__row-modal">
                      {el.options.map((option, optionKey) => {
                        return (
                          <div
                            key={optionKey}
                            className={`modal_container__body__button-modal ${isMobile ? 'wide' : ''
                              } ${el.id == 'height'
                                ? dogHeight === option.value
                                  ? ''
                                  : 'disabled'
                                : ''
                              } ${el.id == 'gender'
                                ? petGender === option.value
                                  ? ''
                                  : 'disabled'
                                : ''
                              }
                              ${el.id == 'habitat'
                                ? catHabitat === option.value
                                  ? ''
                                  : 'disabled'
                                : ''
                              }`}
                            onClick={() => {
                              el.id == 'height' && setDogHeight(option.value);
                              el.id == 'gender' && setPetGender(option.value);
                              el.id == 'habitat' && setCatHabitat(option.value);
                            }}
                          >
                            {t(option.label)}
                          </div>
                        );
                      })}
                    </div>
                    {el.btnType && (
                      <div className="modal_container__body__row-modal mt-3 mx-auto ">
                        <div
                          className={`button_red_small ${isMobileOnly ? 'mobile' : ''}`}
                          style={{ width: 'unset' }}
                          onClick={() => handleBack()}
                        >
                          {t('Back')}
                        </div>
                        <div
                          className={`button_red_small ${isMobileOnly ? 'mobile' : ''
                            } ${el.id == 'height'
                              ? !dogHeight
                                ? 'disabled'
                                : ''
                              : ''
                            } ${el.id == 'gender'
                              ? !petGender
                                ? 'disabled'
                                : ''
                              : ''
                            }
                            ${el.id == 'habitat'
                              ? !catHabitat
                                ? 'disabled'
                                : ''
                              : ''
                            }`}
                          style={{ width: 'unset' }}
                          onClick={() => {
                            if (el.btnType == 'sendRequest') {
                              handleSubmit();
                              handleNext();
                            } else if (el.btnType == 'next') {
                              handleNext();
                            } else {
                              (e) => e.stopPropagation();
                            }
                          }}
                        >
                          {t('sendChange')}
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {el.type == 'text_input' && (
                  <>
                    <div className="modal_container__body__inputs">
                      <input
                        className="modal_container__body__input"
                        placeholder={t(el.placeholder)}
                        value={
                          el.id == 'name'
                            ? petName
                            : el.id == 'chipNumber'
                              ? petChip
                              : ''
                        }
                        onChange={(e) => {
                          el.id == 'name' && setPetName(e.target.value);
                          el.id == 'chipNumber' && handleChip(e);
                        }}
                      />
                      {chipError && (
                        <div className="modal_container__body__error FastShow">
                          {t('chipWrong')}
                        </div>
                      )}
                    </div>
                    <div className="modal_container__body__row-modal ">
                      <div
                        className={`button_red_small ${isMobileOnly ? 'mobile' : ''}`}
                        style={{ width: 'unset' }}
                        onClick={() => handleBack()}
                      >
                        {t('Back')}
                      </div>
                      <div
                        className={`button_red_small ${isMobileOnly ? 'mobile' : ''
                          } ${el.id == 'name' ? (petName ? '' : 'disabled') : ''
                          } ${el.id == 'chipNumber'
                            ? petChip && !chipError
                              ? ''
                              : 'disabled'
                            : ''
                          }`}
                        style={{ width: 'unset' }}
                        onClick={
                          el.btnType == 'sendRequest'
                            ? () => {
                              handleSubmit();
                              handleNext();
                            }
                            : el.btnType == 'next'
                              ? () => handleNext()
                              : (e) => e.stopPropagation()
                        }
                      >
                        {t('Next')}
                      </div>
                    </div>
                  </>
                )}

                {el.type == 'radio' && (
                  <>
                    <div className="modal_container__body__inputs">
                      <div className="modal_container__body__radio">
                        <label
                          style={{ display: 'block', marginBottom: '8px' }}
                        >
                          <input
                            type="radio"
                            value="Pure"
                            checked={petBreedType === 'Pure'}
                            onChange={(e) => {
                              handleResetBreedValues();
                              setPetBreedType(e.target.value);
                            }}
                            style={{ marginRight: '8px' }}
                          />
                          {t('pureBreed')}
                        </label>
                      </div>
                      <div className="modal_container__body__radio">
                        <label
                          style={{ display: 'block', marginBottom: '8px' }}
                        >
                          <input
                            type="radio"
                            value="Hybrid"
                            checked={petBreedType === 'Hybrid'}
                            onChange={(e) => {
                              handleResetBreedValues();
                              setPetBreedType(e.target.value);
                            }}
                            style={{ marginRight: '8px' }}
                          />
                          {t('hybridBeed')}
                        </label>
                      </div>
                      <div className="modal_container__body__radio">
                        <label
                          style={{ display: 'block', marginBottom: '8px' }}
                        >
                          <input
                            type="radio"
                            value="Unknown"
                            checked={petBreedType === 'Unknown'}
                            onChange={(e) => {
                              handleResetBreedValues();
                              setPetBreedType(e.target.value);
                            }}
                            style={{ marginRight: '8px' }}
                          />
                          {t('unknownBeed')}
                        </label>
                      </div>
                    </div>
                  </>
                )}

                {el.type == 'date' && (
                  <>
                    <div className="modal_container__body__dropdowns justify-content-center">
                      <Datepicker
                        date={petBD}
                        setDate={(newValue) => setPetBD(newValue)}
                        disabled={false}
                        type={'reset-dp-modal'}
                        minDate={maxDate}
                        maxDate={minDate}
                      />
                    </div>
                    <div className="modal_container__body__row-modal justify-content-center">
                      <div
                        className={`button_red_small ${isMobileOnly ? 'mobile' : ''}`}
                        style={{ width: 'unset' }}
                        onClick={() => handleBack()}
                      >
                        {t('Back')}
                      </div>
                      <div
                        className={`button_red_small ${isMobileOnly ? 'mobile' : ''
                          } ${petBD ? '' : 'disabled'}`}
                        style={{ width: 'unset' }}
                        onClick={
                          petBD
                            ? () => {
                              handleNext();
                            }
                            : (e) => e.stopPropagation()
                        }
                      >
                        {t('sendChange')}
                      </div>
                    </div>
                  </>
                )}

                {el.type == 'upload' && (
                  <div className="d-flex align-items-center flex-column">
                    <div
                      className="modal_container__body__uploader justify-content-center"
                      style={{ maxWidth: '540px' }}
                      onDragOver={handleDragOver}
                      onDrop={handleDrop}
                      onClick={handleButtonClick}
                    >
                      <input
                        type="file"
                        ref={fileInputRef}
                        // accept=".jpg,.png"
                        onChange={handleFileChange}
                        hidden
                        multiple
                      />
                      <img src={Upload} />
                      <span>{t('docUploadPlaceholder')}</span>
                      <div
                        className={`button_red_small ${isMobileOnly ? 'mobile' : ''
                          }`}
                        style={{
                          width: 'unset',
                          padding: isMobile ? '0 20px' : '0 39px',
                        }}
                      >
                        {t('uploadDocument')}
                      </div>
                    </div>
                    {selectedFiles.length ? (
                      <div
                        className="modal_container__body__text"
                        style={{ maxWidth: '540px', margin: '0 0 15px 0' }}
                      >
                        {t('uploadedDocuments')}
                      </div>
                    ) : null}
                    {selectedFiles.length
                      ? selectedFiles.map((file, ind) => (
                        <div
                          key={ind}
                          className="modal_container__body__attach"
                          style={{ maxWidth: '440px', margin: '0 0 20px 0' }}
                        >
                          <img
                            src={Attach}
                            className="pointer"
                            width={isMobile ? 30 : 40}
                            alt="Attach"
                          />
                          <input
                            readOnly
                            className="modal_container__body__input-file"
                            placeholder={t('addDocument')}
                            value={file.name || ''}
                          />
                          <img
                            src={Remove}
                            alt="Remove"
                            style={{
                              position: 'absolute',
                              right: '12px',
                              cursor: 'pointer',
                            }}
                            onClick={() =>
                              setSelectedFiles((prevSelectedFiles) =>
                                prevSelectedFiles.filter(
                                  (el) => el.name !== file.name
                                )
                              )
                            }
                          />
                        </div>
                      ))
                      : null}
                    <div
                      className="modal_container__body__row-modal"
                      style={{ margin: '10px 0 0 0' }}
                    >
                      <div
                        className={`button_red_small ${isMobileOnly ? 'mobile' : ''}`}
                        style={{ width: 'unset' }}
                        onClick={() => handleBack()}
                      >
                        {t('Back')}
                      </div>
                      <div
                        className={
                          selectedFiles.length
                            ? `button_red_small ${isMobileOnly ? 'mobile' : ''}`
                            : `button_red_smal_disabled ${isMobileOnly ? 'mobile' : ''
                            }`
                        }
                        onClick={
                          selectedFiles.length
                            ? () => {
                              requestChange();
                              handleNext();
                            }
                            : (e) => e.stopPropagation()
                        }
                      >
                        {t('Next')}
                      </div>
                    </div>
                  </div>
                )}
              </>
            );
          }
        })}
      {submitting && <Loading noText fast scroll={false} />}

      {selectedDataToChange &&
        activeStep == Object.values(selectedDataToChange?.steps).length &&
        dialog == 'thankYouYes' && (
          <>
            <div
              className="modal_container__body__text"
              style={{
                maxWidth: '540px',
                margin: '0 0 30px 0',
                textAlign: 'start',
              }}
            >
              <Markup content={t('thankYouYesMessage')} />
            </div>
            <div className="modal_container__body__row-modal">
              <div
                className={`button_red_small ${isMobileOnly ? 'mobile' : ''}`}
                onClick={() => {
                  handleClose();
                }}
              >
                OK
              </div>
            </div>
          </>
        )}
      {selectedDataToChange &&
        activeStep == Object.values(selectedDataToChange?.steps).length &&
        dialog == 'thankYouNo' && (
          <>
            <div
              className="modal_container__body__text"
              style={{
                maxWidth: '540px',
                margin: '0 0 30px 0',
                textAlign: 'start',
              }}
            >
              <Markup content={t('thankYouNoMessage')} />
            </div>
            <div className="modal_container__body__row-modal">
              <div
                className={`button_red_small ${isMobileOnly ? 'mobile' : ''}`}
                onClick={() => {
                  handleClose();
                }}
              >
                OK
              </div>
            </div>
          </>
        )}
    </ModalWrapper>
  );
}

export default ModalPetData;
