import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';
import flowReducer from './flowReducer';
import adminReducer from './adminReducer';

function configureStore(initialState = {}) {
  const reducer = combineReducers({
    auth: () => ({ mock: true }),
    form: persistReducer(
      {
        key: 'form', // key for localStorage key, will be: "persist:form"
        storage,
        debug: true,
      },
      rootReducer
    ),
    flow: flowReducer,
    admin: persistReducer(
      {
        key: 'admin',
        storage,
        debug: true,
      },
      adminReducer
    ),
  });

  const store = createStore(
    persistReducer(
      {
        key: 'root',
        debug: true,
        storage,
        whitelist: ['auth'],
      },
      reducer
    ),
    initialState
  );

  const persistor = persistStore(store, null, () => {});

  return { store, persistor };
}

export default configureStore;
