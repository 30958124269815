export const initData = {
  customer: {
    id: '',
    petId: '',
    firstName: '',
    lastName: '',
    address: '',
    zipCode: '',
    city: '',
    country: '',
  },
  pets: {
    id: '',
    customerId: '',
    list: [],
  },
};
