export const en = {
  myTransactions: 'My Transactions',
  customerNumber: 'Customer number',
  noTransactions: 'There are no transactions',
  insurances: 'Insurances',
  reportDamage: 'Report damage',
  tryAsClient: 'Try as a client',
  adminMode: 'Admin mode',
  personalData: 'Personal data',
  flowManagement: 'Flow Management',
  auditClaims: 'Audit Claims',
  myDamages: 'My claims',
  date_on_contract_is_to_be_reactivated:
    'Select the date on which the contract is to be reactivated',
  AdminChanges: 'Admin Changes',
  protectedInsuranceArea: 'Protected insurance area',
  loginInfo: `To view your insurance documents, please log in to our protected area. Here you will find, among other things, information on the subject of reporting a claim. After entering your e-mail address, you will receive an access link. Also you can send your Policy ID and get confirmation code on youre-mail address.`,
  yourNewEmail: 'Your new e-mail address',
  yourPassword: 'Your password',
  requestLoginLink: 'Request login link',
  pandaPetIns: 'Panda pet insurance',
  pandaInsurTech: 'Panda InsurTech GmbH',
  riskTaker: 'Risk carrier',
  riskTakerAddress: `ELEMENT Insurance AG
             <br />
             Karl-Marx-Allee 3
             <br />
             10178 Berlin
             <br />
             Germany`,
  commercial: 'Commercial Register:',
  commercialRegister: `<span>HRB 182671B</span>
             <br />
             District Court of Charlottenburg
             <br />
             VAT ID: DE309829780`,
  member: 'Members of the Board:',
  memberBoard: `<span>
               Dr. Astrid Stange (Chairwoman),
               <br />
               Dr. Michael Bongartz,
               <br />
               Philipp Hartz,
               <br />
               Dr. Tilo Finck
             </span>
             <br />
             <strong>Chairman of the Supervisory Board:</strong>
             <br />
             Ralf Wohltmann`,
  imprint: 'Imprint',
  dataProtection: 'Data protection',
  contact: 'Contact',
  faq: 'FAQ',
  flows: 'Flows',
  noFlowsCreateNew: 'No flows. Create a new one!',
  diagnosis: 'Diagnosis',
  got: 'GOT',
  edit: 'edit',
  delete: 'delete',
  weWorkingClaims: 'We are working on your claims.',
  goBackSeeHistory: `You can go back to the claims' history to see the status of your claims and fill any missing information.`,
  close: 'Close',
  hallo: 'Hello',
  fifiChipMissing: `Kelly's chip number is still missing.`,
  addDetails: 'Add details',
  pandaPetHealth: 'Panda Pet Health Insurance',
  productVariant: 'Product variant: Panda full health insurance exclusive',
  active: 'active',
  commOfIns: 'Commencement of insurance',
  renewsOn: 'Insurance will be automatically renewed on',
  insNumber: 'Insurance number',
  petHealthDesc: `Your pet health insurance protects you from them
                           Costs in case of a necessary operation or
                           Curative treatment (if agreed) due to illness
                           or accident of your animal.`,
  details: 'Details',
  monthly: 'Monthly',
  paymentInfo: 'Payment information',
  transactionHis: 'Transaction history',
  reimCost: 'Reimbursement of costs in the event of a claim',
  insDog: 'Insured dog',
  chipNumber: 'Chip number',
  downloadDoc: 'Download documents',
  downloadDoc2: 'Get documents',
  showLess: 'Show less',
  moreInfoDoc: 'More information and documents',
  faqLong: 'Frequently Asked Questions',
  selectInvoice: 'Select the document',
  name: 'Name',
  type: 'Type',
  size: 'Size',
  group: 'Group',
  item_one: 'item',
  item_other: 'items',
  groupIntoOne: 'Group into one claim',
  analyzeNow: 'Analyze now!',
  preview: 'Preview',
  back: 'back',
  Back: 'Back',
  Next: 'Next',
  infoAboutDamage: 'Information about the damage',
  date: 'Date',
  time: 'Time',
  invoiceNumber: 'Invoice number',
  describeWhatHappen: 'Please describe what happened as precisely as possible',
  specificInfo: 'Specific information on the refund request',
  pet: 'Pet',
  yourDogChip: `Your dog's microchip number`,
  whatTreatmentType: 'What type of treatment did your dog receive?',
  whatTreatmentTakePlace: 'When did the treatment take place?',
  preIdentifyDiag: 'Pre-indentifed diagnosis (select one)',
  noneAbove: 'None of the above',
  info: 'Information',
  symptoms: 'Symptoms',
  cause: 'Cause(s)',
  confirmSelection: 'Confirm selection',
  otherDiag: 'Other diagnosis (select one)',
  diag: 'Diagn',
  invoiceDate: 'Date of invoice',
  desc: 'Description',
  count: 'Count',
  unitPrice: 'Unit Price',
  total: 'Total',
  totalAmount: 'Total amount',
  totalAmount2: 'Gross',
  addGot: 'Add GOT',
  gotCheckPass: 'GOT check passed.',
  gotCheckFailed: 'GOT check failed.',
  gotSelection: 'GOT Selection',
  noGotSelection: 'No GOT selected',
  updateGot: 'Update GOT',
  updateInfo: `If you update your information, the progress will not be saved and
            you will have to upload this invoice. You can also finish this step
            and update your information later.`,
  profilePage: 'Profile page',
  updateVendorInfo: 'Update vendor information',
  updateInformation: 'Update information',
  paymentInformation: `In the event of a refund, payment should be made to the following
        account:`,
  cardNumber: 'IBAN',
  accountOwner: 'Account owner',
  questionOrSuggestion: 'Do you have a question or suggestion?',
  send: 'Send',
  customerNum: 'Customer No.',
  element: 'ELEMENT',
  title: 'Title',
  firstName: 'First name',
  lastName: 'Last name',
  address: 'Address',
  street: 'Street',
  houseNumber: 'House number',
  postcode: 'ZIP code',
  city: 'City',
  birthDate: 'Birth date',
  contactDetails: 'Contact details',
  email: 'E-mail address',
  phone: 'Phone number',
  saveChanges: 'Save changes',
  weAreAnalyzing: 'We are analyzing your information',
  from: 'From',
  to: 'To',
  answer: 'Answer',
  answersCompleted: 'All answers in this flow were completed!',
  fillMissingInfo: 'Please fill the missing information',
  taxAndName: 'Tax ID and Name',
  status: 'Status',
  moreDetails: 'more details',
  id: 'id',
  quantity: 'Quantity',
  amount: 'Amount',
  amount2: 'Net',
  netto: 'Net',
  noClaims: 'There are no claims',
  updateFlow: 'Update flow',
  createFlow: 'Create flow',
  goBack: 'Go back',
  editView: 'Edit view',
  zoom: 'Zoom',
  zoomView: 'Zoom view',
  yourInfo: 'Your information',
  myPets: 'My pets',
  yourInfoNotUpDate: 'Are your information not up to date?',
  yourVendorInfo: `Your vendor's information`,
  editVendorInfo: `Edit your vendor's information`,
  taxId: 'Tax ID',
  totalNet: 'Total net',
  tax: 'Tax',
  VatCheckPassed: 'VAT check passed.',
  VatCheckFailed: 'VAT check failed.',
  totalGross: 'Total gross',
  invoiceTotalCheckPassed: 'Invoice total check passed.',
  invoiceTotalCheckFailed: 'Invoice total check failed.',
  inProgress: 'in progress',
  fillInfo: 'fill the info',
  'complete the flow': 'complete the flow',
  Finish: 'Finish',
  lineItem_one: 'line item',
  lineItem_other: 'line items',
  about: 'About',
  updateLogo: 'Update logo',
  auditDuplicated: 'Audit Duplicated',
  auditPrevention: 'Audit Prevention',
  auditOCR: 'Audit OCR',
  auditClaimsBack: 'Back to audit page',
  policyID: 'Policy ID',
  policyNo: 'Policy No',
  petName: 'Pet name',
  veterinarian: 'Vet name',
  match: 'Match',
  totalTax: 'Tax',
  claimsPendingReview: 'Claims pending review',
  claimsReviewed: 'Claims reviewed',
  checked: 'Checked',
  identifiedSuccess: 'Identified correctly',
  identifiedWrong: 'Identified wrong',
  petTriggerTooltip:
    'Please write the word which helped you to identify the pet',
  chipTriggerTooltip:
    'Please write the word which helped you to identify the chipnummer',
  comment: 'Comment',
  isOfPending: 'claims is pending out of',
  allCompared: 'All items compared.',
  yes: 'Yes',
  no: 'No',
  rate: 'Success rate',
  empty: 'Empty',
  allDamages: 'All Damages',
  search: 'Search',
  confirm: 'Confirm',
  invoiceInfo: 'Invoice info',
  petInfo: 'Pet info',
  chipNumberTrigger: 'Chip trigger word',
  petNameTrigger: 'Pet name trigger word',
  incidentDate: 'Incident Date',
  gender: 'Gender',
  breed: 'Breed',
  firstBreed: 'First breed',
  secondBreed: 'Second breed',
  damage: 'Damage',
  damageLocation: 'Damage location',
  seeInitDoc: 'See initial document',
  seeLineItems: 'See line items',
  duplTrue: 'Duplicate true',
  prevTrue: 'Prevention true',
  duplFalse: 'Duplicate false',
  prevFalse: 'Prevention false',
  confirmed: 'Confirmed!',
  ocrLoaded: 'OCR loaded.',
  update: 'Update',
  totalInfo: 'Total info',
  itemInfo: 'Itemized info',
  preventionEmpty: 'No more items.',
  Category: 'Category',
  Trigger: 'Trigger',
  actions: 'Actions',
  Options: 'Options',
  BackToFlow: 'Back to flow',
  createDropdown: 'Create dropdown',
  editDropdown: 'Edit dropdown',
  Logout: 'Logout',
  Font: 'Font',
  Settings: 'Settings',
  PrimaryColor: 'Primary color',
  SecondaryColor: 'Secondary color',
  TreatmentType: 'Treatment type',
  TreatmentDate: 'Treatment date',
  vendorTaxId: 'Vendor Tax ID',
  vendorAddress: 'Vendor address',
  save: 'Save',
  ShowUnread: 'Show unread messages',
  areYouSure: 'Are you sure?',
  itMight:
    'It might restart the claim process and hence delay the adjudication.',
  Cancel: 'Cancel',
  editDamage: 'Edit damage',
  Invoice: 'Invoice',
  invoice: 'invoice',
  payment: 'Payment',
  vendorName: 'Vendor name',
  add: 'Add',
  question: 'Question',
  done: 'Done',
  notSure: 'Not sure',
  width: 'Width',
  recTriggers: 'Recognized triggers',
  prevNotSure: 'Prevention not sure',
  prevMixedBill: 'Prevention mixed bill',
  mixedBill: 'Mixed bill',
  English: 'English',
  German: 'German',
  diagnosisTrigger: 'Diagnosis Trigger',
  allDatesSame: 'Set all dates as first',
  claim: 'Claim',
  claimNo: 'Claim no.',
  noDiag: 'No diagnose',
  askForEdit: 'Request other changes',
  vet: 'Veterinarian',
  userMode: 'User mode',
  getConfirmCode: 'Get confirmation code',
  submitCode: 'Submit code',
  confirmCode: 'Confirmation code',
  confirmEmail: 'Confirm your new e-mail',
  submitPolicy: 'Submit policy',
  submitEmail: 'Submit your e-mail',
  whichPet: 'Which animal would you like to choose?',
  allAnimals: 'All animals',
  uploadFoto: 'Upload a picture of your your four-legged friend.',
  petProduct: "'s products",
  firstSight: 'at a glance',
  backTo: 'back to the ',
  Selection: 'selection',
  changePetData: 'Change pet data',
  What_would_you_like_to_do: 'What would you like to do?',
  Change_deductible: 'Change deductible',
  changeData: `Change data`,
  krankenExklusiv: 'Exclusive health insurance',
  insPremium: 'Insurance premium',
  paymentMethod: 'Payment method',
  changePayment: 'Change payment method',
  whatCovered: `What is covered by your <span>tariff</span>?`,
  compensations: 'Reimbursement of costs',
  annMaxOPs: 'Annual maximum OPs',
  annMaxTreat: 'Annual maximum treatments',
  waitingStart: 'Waiting period from start of insurance',
  pandaBudget: 'PANDA protection budget',
  changeContract: 'Change contract',
  whatToDo: 'What would you like to do?',
  accident: 'Accident',
  prevention: 'Prevention',
  treatment: 'Treatment',
  preventionTreatment: 'Prevention & Treatment',
  PreventionTreatment: 'Prevention & Treatment',
  typeSelect: 'Please select type of claim',
  noStatus: 'No status',
  firstTrigger: 'First trigger',
  secondTrigger: 'Second trigger',
  preventionStatus: 'Prevention status',
  noTrigger: 'No trigger',
  noCategory: 'No category',
  sendEmail: 'Send email',
  callBack: 'Request a callback',
  cdFirst: 'Change',
  cdSecond: 'data',
  sendBill: 'submit new invoice',
  resendDocs: 'Submit an additional document',
  costCalc: 'Cost estimate',
  billDir: 'Direct billing with vet/veterinary clinic',
  uploadPhoto: 'upload a picture here',
  sendUsEmail: 'Send us an e-mail to',
  replyAsap: 'We will answer immediately!',
  yourName: 'Your name',
  message: 'Message',
  arrangeCall: 'Arrange a call appointment',
  willDiscuss: 'We will call you and discuss all your questions with you!',
  sendChoose: `Please just leave us your phone <span>number</span> and choose your <span>appointment</span>.`,
  yourPhone: 'Your phone number',
  selectDate: 'Select your preferred date',
  whatChange: 'What would you like to change?',
  changeEmail: 'Change email address',
  changeBD: 'Change date of birth',
  changeElse: 'Change something else',
  enterBD: 'Enter your date of birth here:',
  enterEmail: 'Enter your new e-mail address here:',
  Rechnungen: 'Claims',
  rechnungen: 'claims',
  AdditionalProperties: 'Additional information on the claim',
  dateRangePlaceHolder: 'Start - End',
  disabled: 'Disabled',
  halfWidth: 'Half width',
  fullWidth: 'Full width',
  'Open question': 'Open question',
  'New claim': 'New Document',
  'New Document': 'New Document',
  Type: 'Type',
  'End flow': 'End flow',
  'Multiple Choice Question': 'Multiple Choice Question',
  Upload: 'Upload',
  Date: 'Date',
  'Invoice Table': 'Invoice Table',
  'Date range': 'Date range',
  'Existing claim': 'Additional questions',
  'Additional questions': 'Additional questions',
  'Default value': 'Default value',
  values: 'Values',
  Accident: 'Accident',
  Prevention: 'Prevention',
  'to be paid': 'to be paid',
  'to reject': 'to reject',
  'to review': 'to review',
  selectDefaultValue: 'Select default value',
  'Customer address': 'Customer address',
  'Customer address recipient': 'Customer address recipient',
  'Customer id': 'Customer id',
  'Customer name': 'Customer name',
  'Invoice date': 'Invoice date',
  'Invoice id': 'Invoice id',
  'Invoice total': 'Invoice total',
  'Sub total': 'Sub total',
  'Total tax': 'Total tax',
  'Vendor address': 'Vendor address',
  'Vendor address recipient': 'Vendor address recipient',
  'Vendor name': 'Vendor name',
  'Vendor tax id': 'Vendor tax id',
  yourAttachments: 'Your attachments',
  enter: 'Enter your request HERE.',
  createNewApproach: 'Create a new approach',
  sendRequest: 'Send request',
  sendChange: 'Send change request',
  otherRequest: 'Other Request',
  enterDate: 'Enter date',
  retriever: 'Nova Scotia Duck Tolling Retriever',
  thankMsg: 'We have received your request. You will receive feedback shortly.',
  thankMsgFooter: 'The PANDA team wishes you a nice day!',
  seeDetails: 'Product details',
  callbackConfirmed: 'Your request has been accepted. Wait for our call.',
  emailConfirmed: 'Your message has been sent. Please wait for our reply.',
  notInvoice: 'Not invoice',
  variants: 'Variants',
  errorMsg: 'Oops, something went wrong. Please try again.',
  Amount: 'Amount',
  next: 'next',
  loginWelcome: 'Welcome to the PANDA Customer Portal',
  register: 'Log in here with your e-mail address.',
  codeSent:
    'We have now sent you a login code to your email address. Please enter the code we sent to your email address here.',
  errorEmail: `ATTENTION!<br/>Please enter the email address you used when you purchased the insurance.<br/><br/>You can't remember your email address?`,
  errorAdminEmail: `ATTENTION!<br/>Please enter the email address you used when you purchased the insurance.<br/><br/>`,
  errorPolicyId: `ATTENTION!<br/>Please enter correct policy number.<br/><br/>`,
  support: 'Then contact our SUPPORT',
  Implementation_date: "Implementation date",
  supportContact: 'Support contact',
  toSend: 'send',
  tt_cover_70: `You have insured a tariff with 70% excess, so we can only reimburse 70% of your costs.`,
  tt_cover_80: `You have insured a tariff with 80% excess, so we can only reimburse 80% of your costs.`,
  tt_cover_100: `You have insured a tariff with 100% excess, so we can only reimburse 100% of your costs.`,
  tooltip1_OP: `Castration/sterilisation is medically necessary in the presence of gynaecological or oncological diseases (e.g. inflammation or tumorous changes in the reproductive organs or other hormone-dependent tumours).`,
  tooltip1_KR: `Castration/sterilisation is medically necessary in the presence of gynaecological or oncological diseases (e.g. inflammation or tumorous changes in the reproductive organs or other hormone-dependent tumours).\n\nReimbursement within the PANDA protection budget up to € 70 possible.`,
  tooltip2: `You have insured a tariff with {{}}% cover, so we can only reimburse {{}}% of your costs.`,
  tooltip3: `a) Preventive examinations and health checks including blood tests;\nb) Dental prophylaxis (teeth cleaning/tartar removal) Dental prophylaxis (teeth cleaning/tartar removal);\nc) Worming;\nd) Vaccinations;\ne) Flea and tick prevention;\nf) Castration or sterilisation without medical indication;\ng) Claw trimming;\nh) Preparation of health certificates and expert opinions;\ni) Manual anal bag emptying;\nj)	Animal care products purchased through dealers selected by PANDA;\nk) Treatments due to pregnancy and delivery/birth (does not apply to breeding animals);\nl) Cancer screening and/or disease check-up for animals from abroad. \nm) Physiotherapy and behavioral therapy, provided the respective healing methods are carried out by recognized/licensed physiotherapists or behavioral therapists, as well as alternative healing methods (including acupuncture, acupressure, homeopathy, laser therapy, magnetic field therapy, neural therapy, bioresonance therapy, phytotherapy, chiropractic and osteopathy), provided the treatments are carried out by the veterinarian himself. Excluded in any case are behavioral therapies for cats and all treatments by bloodletting. Compensation is limited to one of the listed benefits up to a maximum of € 70 per insurance year. The benefit will be offset against an existing maximum annual benefit. If you have not received any reimbursement for the above-mentioned measures in one year, i.e. you have not taken advantage of any measures from the PANDA protection budget, the limit of the PANDA protection budget is doubled from € 70 to € 140 in the following year. In the following year, the originally specified maximum annual limit applies again until the conditions stated in the first sentence are met again.`,
  tooltip4: 'Please note the special waiting times in the table below.',
  tooltip5: `Insurance ends on`,
  dataChangesConfirmed:
    'Your request has been sent. Please wait for our reply.',
  addDocument: 'Add another document',
  pandaLoading: 'Thank you! We are now processing this information.',
  month: 'month',
  perInsYear: 'per insurance year',
  tariffInfo: 'Information about your tariff',
  specialWaitingPreiod_3: 'Special waiting period of 3 months',
  specialWaitingPreiod_12: 'Special waiting period of 12 months',
  specialWaitingPreiod_18: 'Special waiting period of 18 months',
  notInsured: 'This is not insured, among other things',
  'Erkrankungen, die vor Versicherungsbe - ginn oder in der Wartezeit bekannt/diagnostiziert waren':
    'Illnesses that were known/diagnosed before the start of the insurance or during the waiting period',
  'Kieferanomalien (z.B. Persistierende Milchzähne)':
    'Jaw anomalies (e.g. persistent milk teeth)',
  'Leistungen aus der Trächtigkeit (z.B. Not - kaiserschnitt)':
    'Benefits from pregnancy (e.g. emergency caesarean section)',
  'Nicht medizinisch notwendige Kastration oder Sterilisation':
    'Castration or sterilisation that is not medically necessary',
  userLogin: 'User Login',
  dateRangeError: 'Please choose correct dates for date range',
  noInfo: 'No information',
  claimSuccess: 'Your claim has been registered successfully',
  'Error, reloading...': 'Error, reloading...',
  'Something going wrong...': 'Something going wrong...',
  cancelContract: 'Cancel contract',
  whichPetCancel: 'For which animal would you like to cancel the contract?',
  whicContractCancel: 'Which contract would you like to cancel?',
  selectOption: 'Please select an option:',
  selectOption2: 'Please select an option',
  'Ordinary cancelation': 'Ordinary cancelation',
  'Cancellation for other reasons': 'Cancellation for other reasons',
  Revocation: 'Revocation',
  hintModal:
    'If the contract is revoked/cancelled, you can no longer activate the contract.',
  hint: 'Note',
  ContractEnds1:
    'The contract ends in compliance with the contractually stipulated cancellation period at the end of the contract, i.e. on ',
  afterReason2: 'The contract ends on XX.XX.XXXX.',
  afterReason3: 'Please specify the circumstances:',
  selectHere: 'Select here',
  'Animal given away': 'Animal given away',
  'Animal sold': 'Animal sold',
  'Animal handed over to animal welfare':
    'Animal handed over to animal welfare',
  'Animal deceased': 'Animal deceased',
  'Policyholder deceased': 'Policyholder deceased',
  'Moving abroad': 'Moving abroad',
  Miscellaneous: 'Miscellaneous',
  areYouSureToCancel_start: 'Are you sure you want to cancel',
  areYouSureToCancel_end:
    ' contract?<br/>You can no longer reactivate it afterwards.',
  emailSent_main:
    'We have received your cancellation request. You will receive a confirmation by email shortly.',
  emailSent_revoke:
    'We have received your cancellation request. You will receive a confirmation by email shortly.',
  toStartPage: 'To start page',
  pleaseAttach:
    'Please upload a corresponding proof document (e.g. photo of the EU pet passport).',
  pleaseAttachCustomer:
    'Please upload a corresponding proof document (e.g. photo of your identity card).',
  pleaseUpload: 'Please upload a verification document here:',
  uploadDocument: 'Upload document',
  '1. Vers. Jahr': '1st ins. year:',
  'ab 2. Vers. Jahr:': 'from 2nd ins. year:',
  '1 Monat': '1 month',
  'bei Unfall:': 'In case of accident:',
  keine: 'none',
  docUploadPlaceholder: 'Place documents to upload here',
  invoiceUploadSubmitText: 'Upload invoice',
  uploadedDocuments: 'Uploaded documents',
  submitInvoice: 'Submit invoice',
  submitDocument: 'Submit document',
  whichPetContinue: 'For which animal would you like to continue?',
  myProducts: 'My products',
  myInvoices: 'My claims',
  quantity2: 'Quantity',
  pleaseCheckData:
    'Please check whether the data has been recognised correctly and completely. If not, please correct and/or complete the data. Then please confirm that the data is correct.',
  pleaseSelectClaim_G:
    'Please select the claim for which the document is to be submitted.',
  pleaseSelectClaim_CE:
    'Please select the claim for which the cost estimation is to be submitted.',
  invoiceDetails: 'Invoice details',
  attention:
    '*Attention, there are legal consequences if data is knowingly provided incorrectly.',
  correctData: 'Correct data / add new line',
  resetChanges: 'Reset changes',
  pleaseRemove: 'Please remove the items that you do NOT WANT us to refund',
  areNotRefund: 'Are there any invoice items that we should NOT refund?',
  invoicesForVerification: 'These invoice items are forwarded for verification',
  justClick: 'Please simply click in the fields to correct the data.',
  missingDiag: 'Diagnosis missing',
  chipDoesNotMatch: 'Chip number does not match',
  missingChip: 'Chip number missing',
  cantFindDiag_inv:
    'Unfortunately, we could not find a diagnosis on your invoice, but we need this for processing. Is the diagnosis included on your invoice?',
  cantFindDiag_doc:
    'Unfortunately, we could not find a diagnosis on your document, but we need this for processing. Is the diagnosis included on your document?',
  cantFindChip_inv:
    'Unfortunately, we could not find a chip number on your invoice, but we need it to process your invoice. Is the chip number on your invoice?',
  cantFindChip_doc:
    'Unfortunately, we could not find a chip number on your document, but we need it to process your document. Is the chip number on your document?',
  chipDoesNotMatch1_inv:
    "Unfortunately, the chip number on the invoice does not match the chip number on your pet's contract.",
  chipDoesNotMatch1_doc:
    "Unfortunately, the chip number on the document does not match the chip number on your pet's contract.",
  chipDoesNotMatch2_inv:
    'Is the chip number stated on the invoice the correct chip number for your pet?',
  chipDoesNotMatch2_doc:
    'Is the chip number stated on the document the correct chip number for your pet?',
  hasDuplicate1_inv:
    'This invoice has already been submitted and is being processed under service case number ',
  hasDuplicate1_doc:
    'This document has already been submitted and is being processed under service case number ',
  hasDuplicate2: 'Has data – such as diagnosis or chip number – been added?',
  sureReuploadClaim_inv: 'Are you sure you still want to resubmit the invoice?',
  sureReuploadClaim_doc:
    'Are you sure you still want to resubmit the document?',
  thankModal_inv:
    'Thank you very much. If you have any questions about the processing status of an invoice that has already been submitted, you can view this under “My claims“.',
  thankModal_doc:
    'Thank you very much. If you have any questions about the processing status of a document that has already been submitted, you can view this under “My claims“.',
  customerPortal: 'Customer portal',
  thankForUpload_G_inv:
    'Thank you for uploading your invoice! We will forward it for processing.',
  thankForUpload_G_doc:
    'Thank you for uploading your document! We will forward it for processing.',
  thankForUpload_G_few_inv:
    'Thank you for uploading your invoices! We will forward it for processing.',
  thankForUpload_G_few_doc:
    'Thank you for uploading your documents! We will forward it for processing.',
  thankForUpload_CE:
    'Thank you for uploading your cost estimation We will forward it for processing.!',
  thankForUpload_CE_few:
    'Thank you for uploading your cost estimations! We will forward it for processing.',
  thankForUpload_LC:
    'Thank you for uploading your document! We will forward it for processing.',
  thankForUpload_LC_few:
    'Thank you for uploading your documents! We will forward it for processing.',
  nowProcessed_G:
    'This is now being processed by us and we will get back to you shortly.',
  nowProcessed_GV: 'This is now being processed by us.',
  nowProcessed_EC: 'We will forward it for processing.',
  nowProcessed_LC:
    'These are now being processed by us and we will get back to you shortly.',
  nowProcessed_CE:
    'This is now being processed by us and we will get back to you shortly.',
  loaderText: 'Please wait a moment',
  loaderSubText: 'Your documents are being uploaded.',
  loaderSubText_CE: 'Your cost estimation are being uploaded.',
  loaderSubText2:
    'We are processing your document, this can take a few moments...',
  seeAdvice: 'See advice',
  adjudication: 'Adjudication recommendations',
  CostEstimation: 'Cost estimation',
  costEstimation: 'cost estimation',
  reportLiab: 'Report liability claim',
  doesBelongExistingClaim1: 'Does the ',
  doesBelongExistingClaim1_CE: 'Does the ',
  doesBelongExistingClaim2: ' belong to an existing claim?',
  confirmChangeData_1: 'Thank you very much,',
  confirmChangeData_2:
    'We have received your change request for implementation. This is now being processed by us. You will shortly receive a confirmation to the email address stored in your contract.',
  confirmChangeData_3: 'The PANDA team wishes you a great day!',
  'Welches Dokument möchtest Du nachreichen?':
    'Which document do you want to submit?',
  'Your benefit cases': 'Your benefit cases',
  deleteLine: 'Delete line',
  addLine: 'Add line',
  paymentTile: 'Desired account for transfer',
  paymentDescription: 'Which account should we transfer the payment to?',
  Payment: 'Payment',
  chipWrong: 'Incorrect chip number. It must contain 15 digits.',
  emailWrong: 'Incorrect email.',
  confirmEmailWrong:
    'Please double-check your email and confirmation email. They do not match.',
  Document: 'Document',
  document: 'document',
  claimType: 'Claim type',
  'Cost estimation_': 'Cost estimation',
  'Liability claim_': 'Liability claim',
  'General to Vets_': 'Direct billing with vet/veterinary clinic',
  General_: 'Invoice',
  docPreview: 'Document preview',
  'Existing Claim_': 'Existing Claim',
  duplicateTo: 'Duplicate to: ',
  thankYouPageIngo:
    'It may turn out during the invoice verification that we cannot pay all the amounts invoiced by the vet in full because they are not covered or not covered in full under your contract (e.g. excess or annual maximum benefit). Once the invoice has been checked, you will receive a statement from us showing the amounts charged directly. If we were unable to pay the invoice in full, we will inform you of the amount to be reimbursed by you to the vet.',
  achtung: 'Please note!',
  achtungChip_CE:
    '*Attention, your cost estimation must include a chip number!',
  achtungChip_GtV:
    'Please make sure that the invoice is fully legible and not covered (e.g. receipt, finger, shadow, etc.)',
  achtungChip: '*Attention, your invoice must include a chip number!',
  LoginAsUser: 'Login as user',
  Submit: 'Submit',
  'Logout as user': 'Logout as user',
  createTicket: 'Create ticket',
  ticket: 'Ticket',
  bug: 'Bug',
  error: 'Error',
  enhancements: 'Enhancements',
  petPage: 'Pet`s page',
  page: 'Page',
  ticketConfirmed: 'Ticket created',
  noteScreenshot:
    'Please note that the screesnhot of the page would be taken automatically.',
  Unbekannt: 'Unknown',
  needChip:
    'We need a chip number to check your claim. Please have the vet complete the chip number and then upload the document again.',
  needChipYes:
    "Please request a change to your pet's chip number in the customer portal.",
  needChipNo_inv:
    "Please contact your veterinary practice and ask them to issue a corrected invoice with your pet's correct chip number. Please then resubmit the corrected invoice.",
  needChipNo_doc:
    "Please contact your veterinary practice and ask them to issue a corrected document with your pet's correct chip number. Please then resubmit the corrected document.",
  needDiag_inv:
    'We need a diagnosis to check your claim. Please have the diagnosis completed by your vet and then upload the invoice again.',
  needDiag_doc:
    'We need a diagnosis to check your claim. Please have the diagnosis completed by your vet and then upload the document again.',
  needDiag_EC:
    'You have selected Submit diagnosis and confirmed in the next step that there is no diagnosis on the document. Please have the vet complete the diagnosis and then upload the document again.',
  noClaimsToAdd: "You don't have any claims to add to yet.",
  claimChecks: 'Claim checks',
  wasChipOnInvoice: 'Was chipnmuber on invoice:',
  wasDiagOnInvoice: 'Was diagnosis on invoice:',
  isItPolicyDR: 'Is it policy date range:',
  isItDupl: 'Is it a duplicate:',
  containPrevent: 'Does it contain prevention:',
  somethingWentWrong: 'Something went wrong! Please try again later.',
  errorReloading: 'Error, reloading...',
  successfullyUpdated: 'Successfully updated!',
  maxRequstLimitReached:
    "Your last claim didn't completed yet, please try again later.",
  loadingFlowText: 'Please wait a moment...',
  sendChangeRequest: 'Save changes',
  submissionDate: 'Submission date',
  ContractInformation: 'Contract information',
  tariff: 'Tariff',
  submittedDocs: 'Documents submitted',
  submittedOn: 'submitted on ',
  coverage: 'Reimbursement of costs',
  detailedView: 'Detailed view',
  claimLC: 'Liability insurance claim',
  addDescr: 'Add description',
  datePlaceholder: 'DD.MM.YYYY',
  "YY":"YY",
  "DD":"DD",
  "MM":"MM",
  customerChanged: '*Customer changes made',
  policyReactivate: 'Reactivate policy',
  invoiceLink: 'Invoice link',
  yourDocuments: 'Your documents',
  Male: 'Male',
  Female: 'Female',
  diverse: 'Diverse',
  year: 'year',
  halfYear: 'half-year',
  quarter: 'quarter',
  readClaim: 'Read claim',
  analyzeClaim: 'Analyze claim',
  nothingToChange: "Sorry, you can't change your pet's details yourself.",
  no_waiting_time: 'no waiting time',
  unlim: 'unlimited',
  day_14: '14 days',
  day_5: '5 days',
  month_1: '1 month',
  'Onkologische Krankheiten (bösartige Tumore)':
    'Oncological diseases (malignant tumours)',
  'Onkologische Krankheiten (gutartige Tumore)':
    'Oncological diseases (benign tumours)',
  'Onkologische Krankheiten (gutartige und bösartige Tumore)':
    'Oncological diseases (benign and malignant tumours)',
  'Erkrankungen, die vor Versicherungsbeginn oder in der Wartezeit bekannt/diagnostiziert waren':
    'Illnesses that were known/diagnosed before the start of the insurance or during the waiting period',
  Kreuzbandrisse: 'Cruciate ligament ruptures',
  Pankreatitis: 'Pancreatitis',
  Niereninsuffizienz: 'Renal insufficiency',
  'Nickhautdrüsenvorfall/Cherry Eye': 'Nictitating gland prolapse/cherry eye',
  'Epiliepsie/Krampfanfälle': 'Epilepsy/seizures',
  'Hüftgelenksdysplasie (HD)': 'Hip joint dysplasia (HD)',
  'Ellenbogendysplasie (ED)': 'Elbow dysplasia (ED)',
  'Osteochondrosis dissecans (OCD)': 'Osteochondrosis dissecans (OCD)',
  'Frakturierter Processus Coronoideus (FPC)':
    'Fractured coronoid process (FPC)',
  'Isolierter Processus Anconaeus (IPA)': 'Isolated processus anconaeus (IPA)',
  'Radius Curvus (Short-Ulna-Syndrom)': 'Radius curvus (short ulna syndrome)',
  Herzerkrankungen: 'Heart disease',
  Allergien: 'Allergies',
  'Erworbene Herzerkrankungen und Allergien':
    'Acquired heart disease and allergies',
  'Schilddrüsenerkrankungen bei Hunden': 'Thyroid diseases in dogs',
  'Goldakupunktur bzw. Prothesen/Implantate':
    'Gold acupuncture or prostheses/implants',
  Kryptorchismus: 'Cryptorchidism',
  Entropium: 'Entropion',
  Ektropium: 'Ectropion',
  Prothesen: 'Prostheses',
  'Cherry-Eye': 'Cherry-Eye',
  'Cherry-Eye/Nickhautdrüsenvorfall': 'Cherry eye/nictitating gland prolapse',
  Nabelbruch: 'Umbilical hernia',
  Kreuzbandriss: 'Cruciate ligament rupture',
  Arthrosen: 'Arthroses',
  'Brachyzephales Syndrom': 'Brachycephalic syndrome',
  Patellaluxation: 'Patellar luxation',
  Dackellähmung: 'Dachshund paralysis',
  Epilepsie: 'Epilepsy',
  'Polyzistische Nierenerkrankung (PKD)': 'Polycystic kidney disease (PKD)',
  'Spinale Muskelatrophie (SMA)': 'Spinal muscular atrophy (SMA)',
  'Mucopolysaccharidose (MPS VI)': 'Mucopolysaccharidosis (MPS VI)',
  Anaplasmose: 'Anaplasmosis',
  'Wobbler-Syndrom': 'Wobbler syndrome',
  'Niereninsuffizienz/Nierensteine': 'Renal insufficiency/kidney stones',
  'FORL/CORL': 'FORL/CORL',
  'Canine/Feline Vector Borne Diseases (CVBD/FVBD)':
    'Canine/Feline Vector Borne Diseases (CVBD/FVBD)',
  Bandscheibenvorfall: 'Slipped disc',
  Bandscheibenvorfälle: 'Herniated discs',
  'keine besondere Wartezeit': 'No special waiting time',
  '70€ je Vers. Jahr': '70€ per insurance year',
  'OP Basic': 'OP Basic',
  'OP Comfort': 'OP Comfort',
  'OP Full': 'OP Full',
  'Health Basic': 'Health Basic',
  'Health Comfort': 'Health Comfort',
  'Health Full': 'Health Full',
  readClaimFail_start: 'Invoice could not be read please send it to ',
  readClaimFail_end: ' and refresh the page.',
  'sofort bei Unfällen': '(unlimited in case of accidents)',
  paymentPage: 'Payment page',
  exportExcel: 'Export EXCEL',
  processXML: 'Process XML',
  createXML: 'Create XML file',
  customer: 'Customer',
  linkToInsly: 'Link to Insly',
  noPoliciesFound: 'No policies found',
  numberOfClaims: 'Number of claims',
  processPayment: 'Process payment',
  createdXMLok: 'XML file created',
  'bei Unfällen unbegrenzt': '(unlimited in case of accidents)',
  '(bei Unfällen 15.000€)': '(15.000€ in case of accidents)',
  '(bei Unfällen 2.000€)': '(2.000€ in case of accidents)',
  '(bei Unfällen 1.000€)': '(1.000€ in case of accidents)',
  unsupportedFileExtension: ' is not a supported file extension.',
  unsupportedFileSize: ' file size is too big',
  Refunds: 'Refunds',
  subInvTotal: 'Total amount / Invoice amount',
  subInvTotalMob: 'Total amount /\nInvoice amount',
  Payments: 'Payments',
  savePaymentMethod: 'Save payment method',
  paymentMethodSuccessfullyUpdated: 'Payment method successfully updated',
  open: 'open',
  'in progress': 'in progress',
  'closed - payment released': 'closed - payment released',
  'closed - rejection': 'closed - rejection',
  AdminChangesHeader: 'Hello, what change would you like to make?',
  'closed - no customer respond': 'closed - no customer respond',
  'closed - payment issued': 'closed - payment issued',
  reopened: 'reopened',
  'Recovery created': 'Recovery created',
  'Recovery settled': 'Recovery settled',
  canceled: 'canceled',
  attentionNoChip: 'Ooops! Chip number missing',
  chipShould15: 'The chip number must contain 15 digits',
  pleaseEnterChip: 'Please enter your chip number.',
  noteReporOnlytWithChip:
    'Please note that you can only report a claim once you have entered the chip number of your four-legged friend.',
  requestSent: 'Request has been sent',
  enterChipBefore:
    "Before you can report a claim, you must provide your pet's chip number.",
  chipHere: 'Enter the chip number here',
  Kundenservice: 'Customer portal',
  Leistungsfallabteilung: 'Claims department',
  pleaseFillInputs: 'Please fill in all mandatory fields.',
  hasOverdue: 'Has overdue',
  event: 'Event',
  iban: 'IBAN',
  female: 'Female',
  male: 'Male',
  petNameChange: 'Pet name',
  petBreedChange: 'Pet breed',
  petBDChange: 'Pet birthday',
  petChipChange: 'Pet chipnumber',
  petGenderChange: 'Pet gender',
  dogHeightChange: 'Dog height',
  catHabitatChange: 'Cat habitat',
  whichPetDataToChange: 'Which animal data would you like to change?',
  pleaseSelectHere: 'Please select here',
  pleaseEnterPetName: 'Please enter the name of your pet here.',
  pleaseSelectPetType: 'Which animal is it?',
  pleaseSelectBreedType: 'Please select breed type of your pet here.',
  pleaseSelectPetGender: 'Please select gender of your pet here.',
  pleaseEnterPetChip: 'Please enter chipnumber of your pet here.',
  pleaseEnterPetBD: 'Please enter birthday of your pet here.',
  pleaseSelectDogHeight: 'Please select height of your dog here.',
  pleaseSelectCatHabitat: 'Please select habitat of your cat here.',
  Indoor: 'Indoor',
  Outdoor: 'Outgoing',
  changeBreedMessage:
    'Would you like to finalise the process now? Please note that a change of breed may affect the price of your insurance.',
  changeBDMessage:
    'Would you like to finalise the process now? Please note that a change of date of birth may affect the price of your insurance.',
  changeHeightMessage:
    'Would you like to finalise the process now? Please note that a change in size may affect the price of your insurance.',
  changeHabitatMessage:
    'Would you like to finalise the process now? Please note that a change in your place of residence may affect the price of your insurance.',
  thankYouYesMessage: `Thank you very much,<br/><br/>We have received your change request for implementation. This is now being processed by us. You will shortly receive a confirmation to the e-mail address stored in your contract.<br/><br/>We wish you and your four-legged friend all the best!`,
  thankYouNoMessage: `We can see that you do not wish to make the change. The contract will continue as before.<br/><br/>If you have any questions, please contact our customer service team at any time!`,
  'Pet name': 'Pet name',
  'Pet breed': 'Pet breed',
  'Pet birthday': 'Pet birthday',
  'Pet chipnumber': 'Pet chipnumber',
  "The contract is terminated as of the following date": "The contract is terminated as of the following date",
  'Pet gender': 'Pet gender',
  'Dog height': 'Dog height',
  'Cat habitat': 'Cat habitat',
  pureBreed: 'Pure breed',
  hybridBeed: 'Mixed breed',
  unknownBeed: 'Unknown breed',
  dog: 'Dog',
  cat: 'Cat',
  attach: 'Add document',
  whatToChange: 'What would you like to do?',
  changeDeductible: 'Change deductible',
  changeTariff: 'Request a tariff change',
  change_tariff: 'Change tariff',
  otherEnquiry: 'Other enquiry',
  changeDeductibleTo: 'Change deductible to',
  yourNewPremiumIs: 'Your new premium is',
  whenShouldChange: 'When should the change take effect?',
  changeEffectiveFrom: 'Your change is active from',
  pleaseConfirmRequest: 'Please confirm your change request',
  thankYouMessage_changeDeductible: `Thank you,</br></br>we will forward your change request.</br>We will contact you when the change has been implemented.</br></br>The PANDA team wishes you a have a nice day!`,
  thankYouMessage_otherEnquiry: `Thank you,</br></br>we have received your enquiry.</br>We will get back to you as soon as possible. </br></br>The PANDA team wishes you a have a nice day!`,
  placeholder_1: 'Enter your enquiry HERE.',
  pleaseAnswerTruthfully: 'Please answer the following questions truthfully',
  option_noClaims:
    'No benefit (damage) has been paid so far and the animal is healthy',
  option_noClaimsSixMonths:
    'You have already received veterinary treatment (other than prevention), this was more than 6 months ago and the animal is now healthy',
  option_wasClaimed:
    'A service other than prevention has already been provided, which was not more than 6 months ago or the animal is not healthy',
  pleaseSelectTariff: 'Please select a tariff',
  confirmPet: 'Confirm Pet',
  changeBirthOrAdress: 'Customer birthday and email adress change',
  reactivateContract: 'Reactivate contract',
  changeAdminContract: 'Contract Change',
  CancellationOrRevocation: 'Contract termination',
  'The new premium amounts to': 'The new premium amounts to',
  dataChangedSuccess: 'Well done! Data record successfully changed!',
  'Datenänderung': 'Change data',
  'Bestätigen': 'Confirm',
  'Bitte Rasse eintippen': 'Please select breed',
  'Please make a selection for reimbursement in your new tariff': 'Please make a selection for reimbursement in your new tariff',
  'kündigen': 'your',
  'widerrufen': 'your',
  badCoverage: 'No such coverage for this tariff',
  'When should the change be active?': 'When should the change be active?',
  'Change birthday/email address': 'Change birthday/email address',
  'Contract change': 'Contract change',
  'Cancellation/withdrawal': 'Cancellation/withdrawal',
  'Reactivate contract': 'Reactivate contract',
  'Change animal data': 'Change animal data',
  'Invalid MTA date. Must be a future date.': 'Invalid MTA date. Must be a future date.',
  'Policy not found!': 'Policy not found!',
  "Can't change to the same tariff/coverage.": "Can't change to the same tariff/coverage",
  'You have a pending operation on this policy!': 'You have a pending operation on this policy!',
  'Subscription is canceled.': 'Subscription is canceled.',
};

export const de = {
  'Subscription is canceled.': 'Das Abonnement wurde gekündigt.',
  'You have a pending operation on this policy!': 'Sie haben einen schwebenden Vorgang für diese Police!',
  "Can't change to the same tariff/coverage.": "Kann nicht in denselben Tarif/Deckung wechseln.",
  'Policy not found!': 'Richtlinie nicht gefunden!',
  'Invalid MTA date. Must be a future date.': 'Ungültiges MTA-Datum. Muss ein zukünftiges Datum sein.',
  'Change animal data': 'Tierdaten ändern',
  'Reactivate contract': 'Vertrag reaktivieren',
  'Cancellation/withdrawal': 'Kündigung/Rücktritt',
  'Contract change': 'Vertragsänderung',
  'Change birthday/email address': 'Geburtstag/E-Mail-Adresse ändern',
  'When should the change be active?': 'Ab wann soll die Änderung aktiv sein?',
  badCoverage: 'Keine derartige Deckung für diesen Tarif',
  'widerrufen': 'widerrufen',
  'kündigen': 'kündigen',
  'Please make a selection for reimbursement in your new tariff': 'Bitte treffen Sie eine Auswahl zur Kostenerstattung in Ihrem neuen Tarif',
  'Bitte Rasse eintippen': 'Bitte Rasse eintippen',
  'Bestätigen': 'Bestätigen',
  'Datenänderung': 'Datenänderung',
  dataChangedSuccess: 'Gut gemacht! Datensatz erfolgreich geändert!',
  pleaseSelectTariff: 'Bitte wählen Sie einen Tarif',
  option_noClaims:
    'Bisher keine Leistung (Schaden) erfolgt und das Tier  ist gesund',
  option_noClaimsSixMonths:
    'Bereits eine tierärztliche Behandlung (außer Prävention) erfolgt, diese liegt länger als 6 Monate zurück und das Tier ist jetzt gesund',
  option_wasClaimed:
    'Bereits eine Leistung außer Prävention erfolgt, welche nicht länger als 6 Monate zurückliegt bzw. das Tier ist nicht gesund',
  pleaseAnswerTruthfully:
    'Bitte beantworten Sie folgende Fragen wahrheitsgemäß',
  placeholder_1: 'Geben Sie HIER Ihre Anfrage ein.',
  thankYouMessage_changeDeductible: `Vielen Dank,</br></br>wir leiten Ihren Änderungswunsch weiter.</br>Wir melden uns bei Ihnen, wenn die Änderung durchgeführt wurde.</br></br>Das PANDA Team wünscht einen schönen Tag!`,
  thankYouMessage_otherEnquiry: `Vielen Dank,</br></br>Ihre Anfrage ist bei uns eingegangen.</br>Wir melden uns so rasch als möglich bei Ihnen zurück.</br></br>Das PANDA Team wünscht einen schönen Tag!`,
  pleaseConfirmRequest: 'Bitte bestätigen Sie Ihren Änderungswunsch',
  changeEffectiveFrom: 'Ihre Änderung ist aktiv ab',
  whenShouldChange: 'Ab wann soll die Änderung aktiv sein?',
  yourNewPremiumIs: 'Ihre neue Prämie beträgt',
  changeDeductibleTo: 'Selbstbehalt ändern auf',
  otherEnquiry: 'Andere Anfrage',
  changeTariff: 'Tarifwechsel anfordern',
  'The new premium amounts to': 'Die neue Prämie beträgt',
  change_tariff: 'Tarifwechsel',
  changeDeductible: 'Selbstbehalt ändern',
  whatToChange: 'Was möchten Sie tun?',
  dog: 'Hund',
  cat: 'Katze',
  pureBreed: 'Reinrassig',
  hybridBeed: 'Mischling',
  unknownBeed: 'Unbekannt',
  'Cat habitat': 'Wohnort meiner Katze',
  'Dog height': 'Größe meines Hundes',
  'Pet gender': 'Geschlecht meines Tieres',
  'Pet chipnumber': 'Chipnummer meines Tieres',
  'Pet birthday': 'Geburtsdatum meines Tieres',
  'Pet breed': 'Rasse meines Tieres',
  'Pet name': 'Name meines Tieres',
  reactivateContract: 'Vertrag reaktivieren',
  thankYouNoMessage: `Wir sehen, dass Sie die Änderung nicht durchführen möchten. Der Vertrag wird wie bisher weitergeführt.<br/><br/>Bei Rückfragen wenden Sie sich gerne jederzeit an unseren Kundenservice!`,
  thankYouYesMessage: `Vielen Dank,<br/><br/>wir haben Ihren Änderungswunsch zur Durchführung erhalten. Diese ist nun bei uns in Bearbeitung. Sie erhalten in Kürze eine Bestätigung an die in Ihrem Vertrag hinterlegte Emailadresse.<br/><br/>Wir wünschen Ihnen und Ihrem Vierbeiner alles Gute!`,
  changeBreedMessage:
    'Möchten Sie den Vorgang nun abschließen? Wir weisen Sie darauf hin, dass eine Änderung des Rasse den Preis Ihrer Versicherung beeinflussen kann.',
  changeBDMessage:
    'Möchten Sie den Vorgang nun abschließen? Wir weisen Sie darauf hin, dass eine Änderung des Geburtsdatums den Preis Ihrer Versicherung beeinflussen kann.',
  changeHabitatMessage:
    'Möchten Sie den Vorgang nun abschließen? Wir weisen Sie darauf hin, dass eine Änderung des Wohnortes den Preis Ihrer Versicherung beeinflussen kann.',
  changeHeightMessage:
    'Möchten Sie den Vorgang nun abschließen? Wir weisen Sie darauf hin, dass eine Änderung der Größe den Preis Ihrer Versicherung beeinflussen kann.',
  Outdoor: 'Freigänger',
  Indoor: 'Hauskatze',
  pleaseSelectCatHabitat: 'Bitte geben Sie hier den Wohnort Ihrer Katze an.',
  pleaseSelectDogHeight: 'Bitte geben Sie hier die Größe Ihres Tieres an.',
  pleaseEnterPetBD: 'Bitte geben Sie hier den Geburtstag Ihres Tieres ein.',
  pleaseEnterPetChip:
    'Bitte geben Sie hier die korrekte Chipnummer Ihres Tieres ein.',
  pleaseSelectPetGender: 'Bitte geben Sie hier das Geschlecht Ihres Tieres an.',
  pleaseSelectBreedType: 'Bitte wählen Sie die Rasse Ihres Tieres aus',
  pleaseSelectPetType: 'Um welches Tier handelt es sich?',
  pleaseEnterPetName:
    'Bitte geben Sie hier den gewünschten Namen Ihres Tieres ein.',
  pleaseSelectHere: 'Bitte hier auswählen',
  whichPetDataToChange: 'Welche Tierdaten möchten Sie ändern?',
  catHabitatChange: 'Wohnort meiner Katze',
  dogHeightChange: 'Größe des Hundes',
  petGenderChange: 'Geschlecht des Tieres',
  petChipChange: 'Chipnummer des Tieres',
  petBDChange: 'Geburtstag des Tieres',
  petBreedChange: 'Rasse des Tieres',
  petNameChange: 'Name des Tieres',
  male: 'Männlich',
  female: 'Weiblich',
  iban: 'IBAN',
  Change_deductible: 'Selbstbehalt ändern',
  event: 'Veranstaltung',
  hasOverdue: 'Ist überfällig',
  pleaseFillInputs: 'Bitte fülle alle Pflichtfelder aus.',
  Kundenservice: 'Kundenservice',
  chipHere: 'Gib hier die Chipnummer ein',
  Leistungsfallabteilung: 'Leistungsfallabteilung',
  enterChipBefore:
    'Bevor Du einen Leistungsfall melden kannst, musst Du die Chipnummer Deines Tieres bekanntgeben.',
  requestSent: 'Anfrage wurde gesendet',
  noteReporOnlytWithChip:
    'Bitte beachte, dass Du erst einen Leistungsfall melden kannst, wenn Du die Chipnummer Deines Vierbeiners angegeben hast.',
  pleaseEnterChip: 'Bitte trage Deine Chipnummer nach.',
  chipShould15: 'Die Chipnummer muss 15 Stellen enthalten',
  attentionNoChip: 'Achtung! Chipnummer fehlt',
  open: 'offen',
  'in progress': 'in Bearbeitung',
  'closed - payment released': 'Zahlung veranlasst',
  'closed - rejection': 'geschlossen',
  'closed - no customer respond': 'keine Kundenrückmeldung',
  'closed - payment issued': 'Zahlung freigegeben',
  reopened: 'wiedereröffnet',
  'Recovery created': 'Regress',
  'Recovery settled': 'Regress',
  canceled: 'storniert',
  paymentMethodSuccessfullyUpdated: 'Zahlungsmethode erfolgreich aktualisiert',
  savePaymentMethod: 'Zahlungsmethode speichern',
  Payments: 'Zahlungen',
  subInvTotal: 'Gesamtsumme / Rechnungsbetrag',
  subInvTotalMob: 'Gesamtsumme /\nRechnungsbetrag',
  Refunds: 'Erstattungen',
  unsupportedFileSize: ' Dateigröße ist zu groß',
  unsupportedFileExtension: ' ist keine unterstützte Dateierweiterung.',
  '(bei Unfällen 1.000€)': '(bei Unfällen 1.000€)',
  '(bei Unfällen 2.000€)': '(bei Unfällen 2.000€)',
  '(bei Unfällen 15.000€)': '(bei Unfällen 15.000€)',
  'bei Unfällen unbegrenzt': '(bei Unfällen unbegrenzt)',
  createdXMLok: 'XML-Datei erstellt',
  processPayment: 'Zahlung verarbeiten',
  numberOfClaims: 'Anzahl der Ansprüche',
  noPoliciesFound: 'Es wurde keine Politik gefunden',
  linkToInsly: 'Link zu Insly',
  customer: 'Kunde',
  createXML: 'XML-Datei erstellen',
  confirmPet: 'Tier bestätigen',
  date_on_contract_is_to_be_reactivated:
    'Wähle das Datum an dem der Vertrag reaktiviert werden soll',
  processXML: 'XML verarbeiten',
  exportExcel: 'EXCEL exportieren',
  paymentPage: 'Zahlungsseite',
  'sofort bei Unfällen': '(sofort bei Unfällen)',
  readClaimFail_start:
    'Die Rechnung konnte nicht gelesen werden, bitte senden Sie sie an ',
  readClaimFail_end: ' und aktualisieren Sie die Seite.',
  'OP Basic': 'OP-Schutz Basis',
  'OP Comfort': 'OP-Schutz Komfort',
  'OP Full': 'OP-Schutz Exklusiv',
  'Health Basic': 'Krankenvollschutz Basis',
  'Health Comfort': 'Krankenvollschutz Komfort',
  'Health Full': 'Krankenvollschutz Exklusiv',
  '70€ je Vers. Jahr': '70€ je Vers. Jahr',
  'keine besondere Wartezeit': 'Keine besondere Wartezeit',
  Bandscheibenvorfall: 'Bandscheibenvorfall',
  Bandscheibenvorfälle: 'Bandscheibenvorfälle',
  'Epiliepsie/Krampfanfälle': 'Epiliepsie/Krampfanfälle',
  'Nickhautdrüsenvorfall/Cherry Eye': 'Nickhautdrüsenvorfall/Cherry Eye',
  Niereninsuffizienz: 'Niereninsuffizienz',
  Pankreatitis: 'Pankreatitis',
  'termination claim customer':
    'Kündigung im Leistungsfall Versicherungsnehmer',
  Kreuzbandrisse: 'Kreuzbandrisse',
  'Ordinairy termination insurer': 'Ordentliche Kündigung Versicherer',
  'Termination claim insurer': 'Kündigung im Leistungsfall Versicherer',
  'Withdrawal insurer': 'Rücktritt vom Vertrag Versicherer',
  'Onkologische Krankheiten (bösartige Tumore)':
    'Onkologische Krankheiten (bösartige Tumore)',
  'Onkologische Krankheiten (gutartige Tumore)':
    'Onkologische Krankheiten (gutartige Tumore)',
  'Onkologische Krankheiten (gutartige und bösartige Tumore)':
    'Onkologische Krankheiten (gutartige und bösartige Tumore)',
  'Erkrankungen, die vor Versicherungsbeginn oder in der Wartezeit bekannt/diagnostiziert waren':
    'Erkrankungen, die vor Versicherungsbeginn oder in der Wartezeit bekannt/diagnostiziert waren',
  'Hüftgelenksdysplasie (HD)': 'Hüftgelenksdysplasie (HD)',
  'Ellenbogendysplasie (ED)': 'Ellenbogendysplasie (ED)',
  'Osteochondrosis dissecans (OCD)': 'Osteochondrosis dissecans (OCD)',
  'Frakturierter Processus Coronoideus (FPC)':
    'Frakturierter Processus Coronoideus (FPC)',
  'Isolierter Processus Anconaeus (IPA)':
    'Isolierter Processus Anconaeus (IPA)',
  'Radius Curvus (Short-Ulna-Syndrom)': 'Radius Curvus (Short-Ulna-Syndrom)',
  Herzerkrankungen: 'Herzerkrankungen',
  'Erworbene Herzerkrankungen und Allergien':
    'Erworbene Herzerkrankungen und Allergien',
  Allergien: 'Allergien',
  'Schilddrüsenerkrankungen bei Hunden': 'Schilddrüsenerkrankungen bei Hunden',
  'Goldakupunktur bzw. Prothesen/Implantate':
    'Goldakupunktur bzw. Prothesen/Implantate',
  Kryptorchismus: 'Kryptorchismus',
  Entropium: 'Entropium',
  Ektropium: 'Ektropium',
  'Cherry-Eye/Nickhautdrüsenvorfall': 'Cherry-Eye/Nickhautdrüsenvorfall',
  Prothesen: 'Prothesen',
  'Cherry-Eye': 'Cherry-Eye',
  Nabelbruch: 'Nabelbruch',
  Arthrosen: 'Arthrosen',
  Kreuzbandriss: 'Kreuzbandriss',
  'Brachyzephales Syndrom': 'Brachyzephales Syndrom',
  Patellaluxation: 'Patellaluxation',
  Dackellähmung: 'Dackellähmung',
  Epilepsie: 'Epilepsie',
  'Polyzistische Nierenerkrankung (PKD)':
    'Polyzistische Nierenerkrankung (PKD)',
  'Spinale Muskelatrophie (SMA)': 'Spinale Muskelatrophie (SMA)',
  'Mucopolysaccharidose (MPS VI)': 'Mucopolysaccharidose (MPS VI)',
  Anaplasmose: 'Anaplasmose',
  'Wobbler-Syndrom': 'Wobbler-Syndrom',
  'Niereninsuffizienz/Nierensteine': 'Niereninsuffizienz/Nierensteine',
  'FORL/CORL': 'FORL/CORL',
  'Canine/Feline Vector Borne Diseases (CVBD/FVBD)':
    'Canine/Feline Vector Borne Diseases (CVBD/FVBD)',
  month_1: '1 Monat',
  day_5: '5 Tage',
  day_14: '14 Tage',
  unlim: 'unbegrenzt',
  no_waiting_time: 'keine Wartezeit',
  nothingToChange:
    'Leider können Sie die Angaben zu Ihrem Haustier nicht selbst ändern.',
  analyzeClaim: 'Forderung analysieren',
  readClaim: 'Anspruch lesen',
  quarter: 'Vierteljahr',
  halfYear: 'Halbjahr',
  year: 'Jahr',
  Male: 'Herr',
  Female: 'Frau',
  diverse: 'Divers',
  yourDocuments: 'Deine Dokumente',
  invoiceLink: 'Link zur Rechnung',
  policyReactivate: 'Politik reaktivieren',
  customerChanged: '*Durchgeführte Kundenänderungen',
  datePlaceholder: 'TT.MM.JJJJ',
  addDescr: 'Beschreibung hinzufügen',
  claimLC: 'Anspruch auf Haftpflichtversicherung',
  detailedView: 'Detailansicht',
  coverage: 'Kostenersatz',
  submittedOn: 'eingereicht am ',
  submittedDocs: 'Eingereichte Dokumente',
  tariff: 'Tarif',
  ContractInformation: 'Informationen zum Vertrag',
  Unknown: 'Unbekannt',
  submissionDate: 'Datum der Einreichung',
  sendChangeRequest: 'Änderungen speichern',
  loadingFlowText: 'Bitte warten Sie einen kurzen Moment...',
  maxRequstLimitReached:
    "Votre dernière demande n'a pas encore été traitée, veuillez réessayer plus tard.",
  successfullyUpdated: 'Erfolgreich aktualisiert!',
  errorReloading: 'Fehler, neu laden...',
  somethingWentWrong:
    'Etwas ist schief gelaufen! Bitte versuchen Sie es später noch einmal.',
  containPrevent: 'Enthält es Prävention:',
  isItDupl: 'Ist es ein Duplikat:',
  isItPolicyDR: 'Ist es der Datumsbereich der Police:',
  wasDiagOnInvoice: 'Wurde die Diagnose im Leistungsfall gestellt:',
  wasChipOnInvoice: 'War Chipnmuber auf dem Leistungsfall:',
  claimChecks: 'Leistungskontrolle',
  noClaimsToAdd: 'Sie haben bis jetzt noch keinen Leistungsfall hinzugefügt.',
  needChip:
    'Wir benötigen zur Prüfung Deines Leistungsfalls eine Chipnummer. Bitte lass die Chipnummer vom Tierarzt ergänzen und lade das Dokument danach erneut hoch.',
  needChipYes:
    'Bitte beantrage eine Änderung der Chipnummer Deines Tieres im Kundenportal.',
  needChipNo_inv:
    'Bitte kontaktiere Deine Tierarztpraxis und bitte um Ausstellung einer korrigierten Rechnung mit der richtigen Chipnummer Deines Tieres. Bitte reiche die korrigierte Rechnung dann erneut ein.',
  needChipNo_doc:
    'Bitte kontaktiere Deine Tierarztpraxis und bitte um Ausstellung einer korrigierten Dokument mit der richtigen Chipnummer Deines Tieres. Bitte reiche die korrigierte Dokument dann erneut ein.',
  needDiag_inv:
    'Wir benötigen zur Prüfung Deines Leistungsfalls eine Diagnose. Bitte lass die Diagnose vom Tierarzt ergänzen und lade das Rechnung danach erneut hoch.',
  needDiag_doc:
    'Wir benötigen zur Prüfung Deines Leistungsfalls eine Diagnose. Bitte lass die Diagnose vom Tierarzt ergänzen und lade das Dokument danach erneut hoch.',
  needDiag_EC:
    'Du hast Diagnose nachreichen ausgewählt und im nächsten Schritt bestätigt, dass auf dem Dokument keine Diagnose vorhanden ist. Bitte lass die Diagnose vom Tierarzt ergänzen und lade das Dokument danach erneut hoch.',
  Unbekannt: 'Unbekannt',
  'Logout as user': 'Als Benutzer abmelden',
  noteScreenshot:
    'Bitte beachten Sie, dass der Screenshot der Seite automatisch erstellt wird.',
  ticketConfirmed: 'Ticket erstellt',
  page: 'Seite',
  petPage: 'Seite für Haustiere',
  enhancements: 'Erweiterungen',
  error: 'Fehler',
  bug: 'Bug',
  ticket: 'Ticket',
  createTicket: 'Ticket erstellen',
  Submit: 'Einreichen',
  LoginAsUser: 'Melden Sie sich als Benutzer an',
  achtungChip: '*Achtung, Deine Rechnung muss eine Chipnummer beinhalten!',
  achtungChip_GtV:
    'Bitte achte darauf, dass die Rechnung vollständig lesbar ist und nicht verdeckt wird (z.B. Kassabon, Finger, Schatten etc.)',
  achtungChip_CE:
    '*Achtung, Dein Kostenvoranschlag muss eine Chipnummer beinhalten!',
  achtung: 'Achtung!',
  thankYouPageIngo:
    'Möglicherweise stellt sich bei der Rechnungsprüfung heraus, dass wir nicht alle vom Tierarzt verrechneten Beträge in voller Höhe bezahlen können, weil sie in Deinem Vertrag nicht oder nicht in voller Höhe gedeckt sind (z.B. Selbstbeteiligung oder Jahreshöchstleistung). Nach erfolgter Rechnungsprüfung erhältst Du von uns eine Aufstellung über die direktverrechneten Beträge. Falls wir die Rechnung nicht in voller Höhe bezahlen konnten, teilen wir Dir den von Dir an den Tierarzt zu erstattenden Betrag mit.',
  duplicateTo: 'Duplizieren nach: ',
  docPreview: 'Dokumentvorschau',
  'Existing Claim_': 'Bestehende Leistungsfall',
  General_: 'Rechnung',
  'General to Vets_': 'Direktverrechnung mit Tierarzt/Tierklinik',
  'Liability claim_': 'Haftpflichtschaden',
  'Cost estimation_': 'Kostenvoranschlag',
  claimType: 'Art der Leistungsfalles',
  document: 'Dokument',
  Document: 'Dokument',
  chipWrong: 'Falsche Chipnummer. Sie muss 15 Ziffern enthalten.',
  emailWrong: 'Falsche E-Mail.',
  confirmEmailWrong:
    'Bitte überprüfen Sie Ihre E-Mail und Ihre Bestätigungs-E-Mail. Sie stimmen nicht überein.',
  addLine: 'Zeile hinzufügen',
  deleteLine: 'Zeile löschen',
  Payment: 'Zahlung',
  paymentDescription: 'An welches Konto sollen wir die Zahlung überweisen?',
  paymentTile: 'Gewünschtes Konto für Überweisung',
  'Your benefit cases': 'Deine Leistungsfälle',
  'Welches Dokument möchtest Du nachreichen?':
    'Welches Dokument möchtest Du nachreichen?',
  confirmChangeData_3: 'Das PANDA Team wünscht einen schönen Tag!',
  confirmChangeData_2:
    'wir haben Ihren Änderungswunsch zur Durchführung erhalten. Diese ist nun bei uns in Bearbeitung. Sie erhalten in Kürze eine Bestätigung an die in Ihrem Vertrag hinterlegte Emailadresse',
  confirmChangeData_1: 'Vielen Dank,',
  doesBelongExistingClaim1: 'Gehört die ',
  doesBelongExistingClaim1_CE: 'Gehört der ',
  doesBelongExistingClaim2: ' zu einem bereits bestehenden Leistungsfall?',
  reportLiab: 'Haftpflichtschaden melden',
  costEstimation: 'Kostenvoranschlag',
  CostEstimation: 'Kostenvoranschlag',
  adjudication: 'Empfehlungen zur Adjudikation',
  seeAdvice: 'Siehe Ratschläge',
  loaderSubText2:
    'Wir verarbeiten gerade Ihr Dokument, dies kann einige Momente in Anspruch nehmen…',
  loaderSubText_CE: 'Dein Kostenvoranschlag wird gerade hochgeladen.',
  loaderSubText: 'Deine Dokumente werden gerade hochgeladen.',
  loaderText: 'Bitte warte einen kurzen Moment',
  nowProcessed_CE:
    'Dieser ist nun bei uns in Bearbeitung und wir melden uns in Kürze bei Dir.',
  nowProcessed_LC:
    'Diese sind nun bei uns in Bearbeitung und wir melden uns in Kürze bei Dir.',
  nowProcessed_EC: 'Wir leiten diese zur Bearbeitung weiter.',
  nowProcessed_GV: 'Diese ist nun bei uns in Bearbeitung.',
  nowProcessed_G:
    'Diese ist nun bei uns in Bearbeitung und wir melden uns in Kürze bei Dir.',
  thankForUpload_LC:
    'Danke für das Hochladen Deines Dokuments! Wir leiten dieses zur Bearbeitung weiter.',
  thankForUpload_LC_few:
    'Danke für das Hochladen Deiner Dokumente! Wir leiten diese zur Bearbeitung weiter.',
  thankForUpload_CE:
    'Danke für das Hochladen Deines Kostenvoranschlags! Wir leiten diesen zur Bearbeitung weiter.',
  thankForUpload_CE_few:
    'Danke für das Hochladen Deiner Kostenvoranschläge! Wir leiten diese zur Bearbeitung weiter.',
  thankForUpload_G_inv:
    'Danke für das Hochladen Deiner Rechnung! Wir leiten diese zur Bearbeitung weiter.',
  thankForUpload_G_few_inv:
    'Danke für das Hochladen Deiner Rechnungen! Wir leiten diese zur Bearbeitung weiter.',
  thankForUpload_G_doc:
    'Danke für das Hochladen Deines Dokuments! Wir leiten dieses zur Bearbeitung weiter.',
  thankForUpload_G_few_doc:
    'Danke für das Hochladen Deiner Dokumente! Wir leiten diese zur Bearbeitung weiter.',
  customerPortal: 'Kundenportal',
  thankModal_inv:
    'Vielen Dank. Wenn Du Fragen zum Bearbeitungsstand einer bereits eingereichten Rechnung hast, kannst Du diesen unter “Meine Leistungsfälle” einsehen.',
  thankModal_doc:
    'Vielen Dank. Wenn Du Fragen zum Bearbeitungsstand einer bereits eingereichten Dokument hast, kannst Du diesen unter “Meine Leistungsfälle” einsehen.',
  sureReuploadClaim_inv:
    'Bist Du sicher, dass Du die Rechnung trotzdem erneut einreichen möchtest?',
  "Implementation_date": "Durchführungsdatum",
  "The contract is terminated as of the following date": "Der Vertrag wird zum folgenden Datum gekündigt",
  sureReuploadClaim_doc:
    'Bist Du sicher, dass Du die Dokument trotzdem erneut einreichen möchtest?',
  hasDuplicate1_inv:
    'Diese Rechnung wurde bereits eingereicht und wird unter der Leistungsfallnummer ',
  hasDuplicate1_doc:
    'Diese Dokument wurde bereits eingereicht und wird unter der Leistungsfallnummer ',
  hasDuplicate2:
    ' bearbeitet. Wurden Daten – wie z.B. Diagnose oder Chipnummer ergänzt?',
  chipDoesNotMatch1_inv:
    'Die auf der Rechnung angegebene Chipnummer stimmt leider nicht mit der im Vertrag Deines Tieres hinterlegten Chipnummer überein.',
  chipDoesNotMatch1_doc:
    'Die auf der Dokument angegebene Chipnummer stimmt leider nicht mit der im Vertrag Deines Tieres hinterlegten Chipnummer überein.',
  chipDoesNotMatch2_inv:
    'Ist die auf der Rechnung angeführte Chipnummer die korrekte Chipnummer Deines Tieres?',
  chipDoesNotMatch2_doc:
    'Ist die auf der Dokument angeführte Chipnummer die korrekte Chipnummer Deines Tieres?',
  cantFindChip_inv:
    'Wir konnten leider auf Deiner Rechnung keine Chipnummer finden, diese benötigen wir aber zur Bearbeitung. Ist die Chipnummer auf Deiner Rechnung enthalten?',
  cantFindChip_doc:
    'Wir konnten leider auf Deinem Dokument keine Chipnummer finden, diese benötigen wir aber zur Bearbeitung. Ist die Chipnummer auf Deinem Dokument enthalten?',
  cantFindDiag_inv:
    'Wir konnten leider auf Deiner Rechnung keine Diagnose finden, diese benötigen wir aber zur Bearbeitung. Ist die Diagnose auf Deiner Rechnung enthalten?',
  cantFindDiag_doc:
    'Wir konnten leider auf Deinem Dokument keine Diagnose finden, diese benötigen wir aber zur Bearbeitung. Ist die Diagnose auf Deinem Dokument enthalten?',
  chipDoesNotMatch: 'Chipnummer stimmt nicht überein',
  missingDiag: 'Diagnose fehlt',
  missingChip: 'Chipnummer fehlt',
  justClick: 'Bitte klicke einfach in die Felder, um Daten zu korrigieren.',
  invoicesForVerification:
    'Diese Rechnungsposten werden zur Prüfung weitergeleitet',
  areNotRefund: 'Gibt es Rechnungsposten, die wir NICHT erstatten sollen?',
  pleaseRemove: 'Bitte klicke die Posten an, die wir NICHT ERSTATTEN sollen',
  resetChanges: 'Änderungen zurücksetzen',
  correctData: 'Daten korrigieren / neue Zeile hinzufügen',
  attention:
    '*Achtung, es hat rechtliche Folgen, wenn Daten wissentlich falsch angegeben werden.',
  invoiceDetails: 'Rechnungsdetails',
  pleaseSelectClaim_G:
    'Bitte wähle den Leistungsfall aus, zu dem Du das Dokument nachreichen möchtest.',
  pleaseSelectClaim_CE:
    'Bitte wähle den Leistungsfall aus, zu dem Du die Folgerechnung nachreichen möchtest.',
  pleaseCheckData:
    'Bitte überprüfe, ob die Daten richtig und vollständig erkannt wurden. Wenn nein, dann bitte korrigiere und/oder ergänze die Daten. Danach bestätige bitte die Korrektheit der Daten.',
  quantity2: 'Anzahl',
  myInvoices: 'Meine Leistungsfälle',
  myProducts: 'Meine Produkte',
  whichPetContinue: 'Für welches Tier möchtest Du fortfahren?',
  submitDocument: 'Dokument einreichen',
  submitInvoice: 'Rechnung einreichen',
  uploadedDocuments: 'Hochgeladene Dokumente',
  invoiceUploadSubmitText: 'Rechnung hochladen',
  docUploadPlaceholder: 'Dokumente zum Hochladen HIER ablegen',
  keine: 'keine',
  'bei Unfall:': 'bei Unfall:',
  '1 Monat': '1 Monat',
  'ab 2. Vers. Jahr:': 'ab 2. Vers. Jahr:',
  '1. Vers. Jahr': '1. Vers. Jahr',
  uploadDocument: 'Dokument hochladen',
  pleaseUpload: 'Bitte lade hier ein Nachweisdokument hoch:',
  pleaseAttach:
    'Bitte laden Sie ein entsprechendes Nachweisdokument hoch (z.B. Foto des EU-Heimtierausweises).',
  pleaseAttachCustomer:
    'Bitte laden Sie ein entsprechendes Nachweisdokument hoch (z.B. Foto Ihres Personalausweises).',
  toStartPage: 'Zur Startseite',
  emailSent_main:
    'Wir haben Deinen Kündigungswunsch erhalten. Du erhältst in Kürze eine Bestätigung per E-Mail.',
  emailSent_revoke:
    'Wir haben Ihren Widerrufswunsch erhalten. Du erhältst in Kürze eine Bestätigung per E-Mail.',
  areYouSureToCancel_end:
    ' möchtest?<br/>Du kannst ihn danach nicht mehr reaktivieren.',
  areYouSureToCancel_start: 'Bist Du sicher, dass Du Deinen Vertrag',
  Miscellaneous: 'Sonstiges',
  'Moving abroad': 'Umzug ins Ausland',
  'Policyholder deceased': 'Versicherungsnehmer verstorben',
  AdminChangesHeader: 'Hallo, welche Änderung möchtest Du vornehmen?',
  'Animal deceased': 'Tier verstorben',
  'Animal handed over to animal welfare': 'Tier dem Tierschutz übergeben',
  'Animal sold': 'Tier verkauft',
  'Animal given away': 'Tier verschenkt',
  selectHere: 'Hier auswählen',
  afterReason3: 'Bitte gib die näheren Umstände an:',
  afterReason2: 'Der Vertrag endet am XX.XX.XXXX.',
  ContractEnds1:
    'Der Vertrag endet unter Einhaltung der vertraglich geregelten Kündigungsfrist zum Vertragsende, dem ',
  hint: 'Hinweis',
  hintModal:
    'Wenn der Vertrag widerrufen/gekündigt wird, kannst Du den Vertrag nicht mehr aktivieren.',
  Revocation: 'Widerruf',
  'Cancellation for other reasons': 'Kündigung aus sonstigen Gründen',
  'Ordinary cancelation': 'Ordentliche Kündigung',
  selectOption: 'Bitte wähle eine Option:',
  selectOption2: 'Bitte wähle eine Option',
  whicContractCancel: 'Welchen Vertrag möchtest Du kündigen?',
  whichPetCancel: 'Für welches Tier möchtest Du den Vertrag kündigen?',
  cancelContract: 'Vertrag kündigen',
  'Something going wrong...': 'Irgendetwas läuft schief...',
  'Error, reloading...': 'Fehler, neu laden...',
  claimSuccess: 'Ihr Leistungsfall wurde erfolgreich registriert.',
  noInfo: 'Keine Informationen',
  userLogin: 'Benutzer-Login',
  dateRangeError:
    'Bitte wählen Sie die korrekten Daten für den Datumsbereich aus',
  'Nicht medizinisch notwendige Kastration oder Sterilisation':
    'Nicht medizinisch notwendige Kastration oder Sterilisation',
  'Leistungen aus der Trächtigkeit (z.B. Not - kaiserschnitt)':
    'Leistungen aus der Trächtigkeit (z.B. Not - kaiserschnitt)',
  'Kieferanomalien (z.B. Persistierende Milchzähne)':
    'Kieferanomalien (z.B. Persistierende Milchzähne)',
  'Erkrankungen, die vor Versicherungsbe - ginn oder in der Wartezeit bekannt/diagnostiziert waren':
    'Erkrankungen, die vor Versicherungsbe - ginn oder in der Wartezeit bekannt/diagnostiziert waren',
  notInsured: 'Das ist unter anderem nicht versichert',
  specialWaitingPreiod_3: 'Besondere Wartezeit von 3 Monaten',
  specialWaitingPreiod_12: 'Besondere Wartezeit von 12 Monaten',
  specialWaitingPreiod_18: 'Besondere Wartezeit von 18 Monaten',
  tariffInfo: 'Infos zu Deinem Tarif',
  perInsYear: 'je Versicherungsjahr',
  month: 'Monat',
  pandaLoading:
    'Wir danken Ihnen! Wir sind gerade dabei, diese Informationen zu bearbeiten.',
  addDocument: 'Weiteres Dokument hinzufügen',
  dataChangesConfirmed:
    'Ihre Anfrage wurde abgeschickt. Bitte warten Sie auf unsere Antwort.',
  tooltip5: `Versicherung endet am`,
  tooltip4: 'Bitte beachte die Besonderen Wartezeiten unten in der Tabelle.',
  tooltip3: `a) Vorsorgeuntersuchungen bzw. Gesundheitschecks einschließlich Blutuntersuchungen;\nb) Zahnprophylaxe (Zahnreinigung/Zahnsteinentfernung);\nc) Wurmkuren;\nd)	Schutzimpfungen;\ne) Floh- und Zeckenvorsorge;\nf) Kastration oder Sterilisation ohne medizinische Indikation;\ng) Kürzen der Krallen;\nh) Erstellung von Gesundheitszeugnissen und Gutachten;\ni) Manuelle Analbeutelentleerung;\nj)	Tierpflegeprodukte, die über von PANDA ausgewählte Händler gekauft werden;\nk)	Behandlungen aufgrund von Trächtigkeit und Entbindung/Geburt (gilt nicht für Zuchttiere);\nl)	Krebsvorsorge und/oder Krankheits-Checkup bei Tieren aus dem Ausland.\nm) Physiotherapie und Verhaltenstherapie, sofern die jeweiligen Heilmethoden durch aner-kannte/lizensierte Physio- bzw. Verhaltenstherapeuten ausgeführt werden sowie alternative Heilmethoden (inkl. Akupunktur, Akupressur, Homöopathie, Lasertherapie, Magnetfeldtherapie, Neuraltherapie, Bioresonanztherapie, Phytotherapie, Chiropraxis und Osteopathie), sofern die Behandlungen vom Tierarzt selbst durchgeführt werden. Ausgeschlossen sind in jedem Fall Verhaltenstherapien für Katzen und alle Behandlungen durch Aderlass. 

  Die Entschädigung ist auf eine der aufgeführten Leistungen bis max. 70 € je Versicherungsjahr begrenzt. Die Leistung wird auf eine bestehende Jahreshöchstleistung angerechnet. 
  
  Wenn Sie in einem Jahr keine Erstattung für o.g. Maßnahmen erhalten haben, d.h. keine Maßnahme aus dem PANDA-Schutzbudget in Anspruch genommen haben, verdoppelt sich die Begrenzung des PANDA-Schutzbudgets im Folgejahr von 70 € auf 140 €. Im darauffolgenden Jahr gilt wieder die ursprünglich angegebene Jahreshöchstgrenze, bis die im ersten Satz genannten Voraussetzungen wieder erfüllt sind. 
  `,
  tooltip2: `Du hast einen Tarif mit {{}}% Deckungssumme versichert, daher können wir nur {{}}% deiner Kosten ersetzen.`,
  tooltip1_KR: `Medizinisch notwendig ist eine Kastration/Sterilisation bei Vor - liegen gynäkologischer oder on - kologischer Erkrankungen (z. B. Entzündungen oder tumoröse Veränderungen der Geschlechts - organe oder hormonabhängiger sonstiger Tumore).\n\nErstattung im Rahmen des PANDA Schutzbudgets bis zu 70 € möglich.`,
  tooltip1_OP: `Medizinisch notwendig ist eine Kastration/Sterilisation bei Vor - liegen gynäkologischer oder on - kologischer Erkrankungen (z. B. Entzündungen oder tumoröse Veränderungen der Geschlechts - organe oder hormonabhängiger sonstiger Tumore).`,
  tt_cover_70: `Du hast einen Tarif mit 70% Deckungssumme versichert, daher können wir nur 70% deiner Kosten ersetzen.`,
  tt_cover_80: `Du hast einen Tarif mit 80% Deckungssumme versichert, daher können wir nur 80% deiner Kosten ersetzen.`,
  tt_cover_100: `Du hast einen Tarif mit 100% Deckungssumme versichert, daher können wir nur 100% deiner Kosten ersetzen.`,
  toSend: 'senden',
  supportContact: 'Support Kontakt',
  support: 'Dann kontaktiere HIER unseren SUPPORT',
  errorEmail: `ACHTUNG!<br/>Bitte gib hier die E-Mail-Adresse ein, die Du bei Abschluss der Versicherung angegeben hast.<br/><br/>Du kannst Dich nicht an Deine E-Mail Adresse erinnern?`,
  errorAdminEmail: `ACHTUNG!<br/>Bitte gib hier die E-Mail-Adresse ein, die Du bei Abschluss der Versicherung angegeben hast.<br/><br/>`,
  errorPolicyId: `ACHTUNG!<br/>Bitte geben Sie die richtige Policennummer ein.<br/><br/>`,
  codeSent:
    'Wir haben Dir nun an Deine E-Mail Adresse einen Login Code gesendet. Bitte gib hier den Code ein, den wir an Deine E-Mail Adresse gesendet haben.',
  register: 'Melde Dich hier mit Deiner E-Mail-Adresse an.',
  loginWelcome: 'Herzlich willkommen im PANDA Kundenportal',
  next: 'weiter',
  Amount: 'Betrag',
  empty: 'Leer',
  English: 'Englisch',
  German: 'Deutsch',
  errorMsg:
    'Ups, da ist etwas schief gelaufen. Bitte versuchen Sie es später noch einmal.',
  emailConfirmed:
    'Ihre Nachricht wurde gesendet. Bitte warten Sie auf unsere Antwort.',
  callbackConfirmed:
    'Ihre Anfrage wurde angenommen. Warten Sie auf unseren Anruf.',
  seeDetails: 'Produktdetails',
  thankMsg:
    'wir haben Deinen Anfrage erhalten.  Du wirst in Kürze eine Rückmeldung erhalten. ',
  thankMsgFooter: 'Das PANDA Team wünscht einen schönen Tag!',
  yourAttachments: 'Ihre Anhänge',
  'Vendor tax id': 'Umsatzsteuer id',
  'Vendor name': 'Name des Anbieters',
  'Vendor address recipient': 'Adresse des Lieferanten Empfänger',
  'Vendor address': 'Adresse des Anbieters',
  'Total tax': 'Steuer insgesamt',
  'Sub total': 'Zwischensumme',
  'Invoice total': 'Rechnungsbetrag',
  'Invoice id': 'Rechnung id',
  'Invoice date': 'Datum der Rechnung',
  'Customer name': 'Name des Kunden',
  'Customer id': 'Kunde id',
  'Customer address recipient': 'Kundenadresse Empfänger',
  'Customer address': 'Adresse des Kunden',
  selectDefaultValue: 'Standardwert auswählen',
  'to review': 'zur Überprüfung',
  'to reject': 'abzulehnen',
  'to be paid': 'zu entrichten',
  Prevention: 'Prävention',
  Accident: 'Unfall',
  values: 'Werte',
  'Default value': 'Standardwert',
  'Existing claim': 'Weitere Fragen',
  'Additional questions': 'Weitere Fragen',
  'Date range': 'Datumsbereich',
  'Invoice Table': 'Tabelle der Rechnungen',
  Date: 'Datum',
  Upload: 'Hochladen',
  'Multiple Choice Question': 'Multiple Choice Frage',
  'End flow': 'Fluss beenden',
  Type: 'Typ',
  'New claim': 'Neues Dokument',
  'New Document': 'Neues Dokument',
  'Open question': 'Offene Frage',
  fullWidth: 'Volle Breite',
  halfWidth: 'Halbe Breite',
  disabled: 'Behinderte',
  enterEmail: 'Geben Sie hier Ihre neue E-Mail-Adresse ein:',
  enterBD: 'Geben Sie hier Ihr Geburtsdatum ein:',
  changeElse: 'Etwas anderes ändern',
  "YY":"JJ",
  "DD":"TT",
  "MM":"MM",
  changeBD: 'Geburtsdatum ändern',
  changeEmail: 'E-Mail Adresse ändern',
  whatChange: 'Was möchtest Du ändern?',
  selectDate: 'Deinen Wunschtermin auswählen',
  yourPhone: 'Deine Telefonnummer',
  sendChoose: `Bitte hinterlasse uns einfach Deine <span>Telefonnummer</span> und wähle Deinen <span>Wunschtermin</span> aus.`,
  willDiscuss:
    'Wir rufen Dich an und besprechen gerne mit Dir alle Deine Fragen!',
  arrangeCall: 'Anruftermin vereinbaren',
  message: 'Nachricht',
  yourName: 'Dein Name',
  replyAsap: 'Wir antworten umgehend!',
  sendUsEmail: 'Sende uns eine E-Mail an',
  uploadPhoto: 'lade hier ein Bild hoch',
  billDir: 'Direktverrechnung mit Tierarzt/Tierklinik',
  costCalc: 'Kostenvoranschlag',
  resendDocs: 'Dokument nachreichen',
  sendBill: 'neue Rechnung einreichen',
  claim: 'Leistungsfall',
  claimNo: 'Leistungsfall-Nr.',
  cdSecond: 'ändern',
  cdFirst: 'Daten',
  callBack: 'Rückruf anfordern',
  sendEmail: 'E-Mail senden',
  variants: 'Varianten',
  notInvoice: 'Keine Rechnung',
  noCategory: 'Keine Kategorie',
  noTrigger: 'Kein Auslöser',
  preventionStatus: 'Status der Prävention',
  secondTrigger: 'Zweiter Auslöser',
  firstTrigger: 'Erster Auslöser',
  noStatus: 'Kein Status',
  typeSelect: 'Bitte wählen Sie die Art der Leistungsfall',
  prevention: 'Prävention',
  accident: 'Unfall',
  treatment: 'Behandlung',
  preventionTreatment: 'Prävention & Behandlung',
  PreventionTreatment: 'Prävention & Behandlung',
  submitEmail: 'E-Mail abschicken',
  submitPolicy: 'ID-Politik einreichen',
  confirmEmail: 'Bestätigen Sie Ihre neue E-Mail',
  confirmCode: 'Bestätigungscode',
  submitCode: 'Code einreichen',
  getConfirmCode: 'Bestätigungscode erhalten',
  whatToDo: 'Was möchtest Du tun?',
  changeContract: 'Vertrag ändern',
  pandaBudget: 'PANDA Schutzbudget',
  waitingStart: 'Wartezeit ab Versicherungsbeginn',
  annMaxTreat: 'Jahreshöchstleistung Behandlungen',
  annMaxOPs: 'Jahreshöchstleistung OPs',
  compensations: 'Kostenersatz',
  whatCovered: `Was ist in Deinem <span>Tarif</span> gedeckt?`,
  changePayment: 'Zahlungsweise ändern',
  paymentMethod: 'Zahlungsmethode',
  insPremium: 'Versicherungsprämie',
  krankenExklusiv: 'Krankenschutz Exklusiv',
  changeData: `Datenänderungswunsch`,
  changePetData: 'Daten des Tieres ändern',
  Selection: 'Auswahl',
  backTo: 'zurück zur ',
  firstSight: 'auf einen Blick',
  petProduct: 's Produkte',
  uploadFoto: 'Lade hier ein Bild Deines Vierbeiners hoch.',
  allAnimals: 'Alle Tiere',
  whichPet: 'Welches Tier möchtest Du auswählen?',
  userMode: 'Benutzermodus',
  vet: 'Tierarzt',
  noDiag: 'Keine Diagnose',
  add: 'Hinzufügen',
  allDatesSame: 'Alle Daten als erste einstellen',
  askForEdit: 'Andere Änderungen beantragen',
  diagnosisTrigger: 'Diagnose Auslöser',
  mixedBill: 'Gemischte Rechnung',
  prevTrue: 'Prävention wahr',
  prevFalse: 'Prävention falsch',
  prevMixedBill: 'Prävention gemischte Rechnung',
  prevNotSure: 'Prävention nicht sicher',
  recTriggers: 'Erkannte Auslöser',
  width: 'Breite',
  notSure: 'Nicht sicher',
  done: 'Erledigt',
  question: 'Frage',
  vendorName: 'Name des Anbieters',
  payment: 'Zahlung',
  Invoice: 'Rechnung',
  invoice: 'Rechnung',
  editDamage: 'Leistungsfall bearbeiten',
  Cancel: 'Abbrechen',
  itMight:
    'Dies könnte das Antragsverfahren wieder in Gang setzen und somit die Entscheidung verzögern.',
  areYouSure: 'Sind Sie sicher?',
  ShowUnread: 'zeige ungelesene Nachrichten an',
  save: 'Speichern Sie',
  vendorAddress: 'Adresse des Anbieters',
  vendorTaxId: 'Steuer-ID des Verkäufers',
  TreatmentDate: 'Behandlungsdatum',
  TreatmentType: 'Art der Behandlung',
  SecondaryColor: 'Sekundäre Farbe',
  PrimaryColor: 'Primäre Farbe',
  Settings: 'Einstellungen',
  Font: 'Schriftart',
  Logout: 'Abmeldung',
  createDropdown: 'Dropdown erstellen',
  editDropdown: 'Dropdown bearbeiten',
  BackToFlow: 'Zurück zum Fluss',
  Options: 'Optionen',
  actions: 'Aktionen',
  Trigger: 'Auslöser',
  Category: 'Kategorie',
  preventionEmpty: 'Keine weiteren Gegenstände.',
  itemInfo: 'Aufgeschlüsselte Informationen',
  totalInfo: 'Gesamt info',
  update: 'Aktualisierung',
  ocrLoaded: 'OCR geladen.',
  confirmed: 'Bestätigt!',
  duplTrue: 'Duplizieren wahr',
  duplFalse: 'Duplizieren falsch',
  seeLineItems: 'Siehe Einzelposten',
  seeInitDoc: 'Siehe Ausgangsdokument',
  damageLocation: 'Ort der Beschädigung',
  damage: 'Leistungsfall',
  breed: 'Rasse',
  firstBreed: 'Erste Rasse',
  secondBreed: 'Zweite Rasse',
  gender: 'Anrede',
  incidentDate: 'Datum des Vorfalls',
  AdminChanges: 'Admin Änderungen',
  petNameTrigger: 'Kosename Auslösewort',
  chipNumberTrigger: 'Chip-Triggerwort',
  petInfo: 'Haustier-Infos',
  invoiceInfo: 'Informationen auf der Rechnung',
  confirm: 'Bestätigen Sie',
  search: 'Suche',
  allDamages: 'Alle Schäden',
  auditClaims: 'Audit-Leistungsfälle',
  auditDuplicated: 'Audit Dupliziert',
  auditPrevention: 'Audit Prävention',
  auditOCR: 'Audit OCR',
  rate: 'Erfolgsquote',
  auditClaimsBack: 'Zurück zur Audit-Seite',
  yes: 'Ja',
  no: 'Nein',
  allCompared: 'Alle Artikel im Vergleich.',
  isOfPending: 'Leistungsfälle anhängig ist aus',
  comment: 'Kommentar',
  petTriggerTooltip:
    'Bitte schreiben Sie das Wort, das Ihnen geholfen hat, das Haustier zu identifizieren',
  chipTriggerTooltip:
    'Bitte schreiben Sie das Wort, das Ihnen geholfen hat, die Chipnummer zu identifizieren',
  identifiedWrong: 'Falsch identifiziert',
  identifiedSuccess: 'Korrekt identifiziert',
  checked: 'Geprüft',
  claimsReviewed: 'Überprüfte Leistungsfälle',
  claimsPendingReview: 'Zu prüfende Leistungsfälle',
  totalTax: 'Steuer',
  match: 'Passen',
  veterinarian: 'Name des Tierarztes',
  petName: 'Name des Haustiers',
  policyID: 'ID-Politik',
  policyNo: 'Vertragsnummer',
  updateLogo: 'Logo aktualisieren',
  about: 'Um',
  Finish: 'Beenden',
  lineItem_one: 'Einzelposten',
  lineItem_other: 'Einzelposten',
  myTransactions: 'Meine Transaktionen',
  'complete the flow': 'runden den Ablauf ab',
  fillInfo: 'Füllen Sie die Informationen aus',
  inProgress: 'im Gange',
  invoiceTotalCheckPassed: 'Rechnungssummenprüfung bestanden.',
  invoiceTotalCheckFailed: 'Rechnungssummenprüfung fehlgeschlagen.',
  totalNet: 'Total netto',
  totalGross: 'Gesamtbrutto',
  VatCheckPassed: 'Umsatzsteuerprüfung bestanden.',
  VatCheckFailed: 'Mehrwertsteuerprüfung fehlgeschlagen.',
  tax: 'Steuer',
  taxId: 'Steuer ID',
  yourVendorInfo: 'Die Informationen Ihres Anbieters',
  editVendorInfo: 'Bearbeiten Sie die Informationen Ihres Anbieters',
  yourInfoNotUpDate: 'Ihre Daten sind nicht aktuell?',
  customerNumber: 'Kundennummer',
  myPets: 'Meine Haustiere',
  yourInfo: 'Ihre Informationen',
  zoomView: 'Zoom-Ansicht',
  zoom: 'Zoomen',
  goBack: 'Geh zurück',
  editView: 'Ansicht bearbeiten',
  updateFlow: 'Fluss aktualisieren',
  status: 'Status',
  noClaims: 'Keine Leistungsfälle vorhanden',
  quantity: 'Quantität',
  amount: 'Rechnungsbetrag',
  amount2: 'Netz',
  netto: 'Netto',
  id: 'id',
  moreDetails: 'mehr Details',
  taxAndName: 'Steuernummer und Name',
  answersCompleted: 'Alle Antworten in diesem Flow wurden abgeschlossen!',
  fillMissingInfo: 'Alle Antworten in diesem Flow wurden abgeschlossen!',
  from: 'Aus',
  to: 'Zu',
  answer: 'Antworten',
  customerNum: 'Kunden-Nr.',
  email: 'E-Mail Addresse',
  weAreAnalyzing: 'Wir analysieren Ihre Informationen',
  saveChanges: 'Änderungen speichern',
  phone: 'Telefonnummer',
  element: 'ELEMENT',
  address: 'Anschrift',
  street: 'Straße',
  houseNumber: 'Hausnummer',
  contactDetails: 'Kontaktdaten',
  city: 'Stadt',
  birthDate: 'Geburtsdatum',
  postcode: 'PLZ',
  noTransactions: 'Es gibt keine Transaktionen',
  insurances: 'Versicherungen',
  reportDamage: 'Rechnung einreichen',
  tryAsClient: 'Versuchen Sie es als Kunde',
  adminMode: 'Admin-Modus',
  personalData: 'Persönliche Daten',
  flowManagement: 'Flowmanagement',
  myDamages: 'Meine Leistungsfälle',
  protectedInsuranceArea: 'Geschützter Versicherungsbereich',
  loginInfo: `Für die Einsicht Ihrer Versicherungsunterlagen melden Sie
                      sich bitte in unserem geschützten Bereich an. Hier finden
                      Sie unter anderem Informationen zum Thema Schadenmeldung.
                      Nach Angabe Ihrer E-Mail-Adresse erhalten Sie einen
                      Zugangslink. Sie können auch Ihre Policen-ID senden und 
                      einen Bestätigungscode an Ihre E-Mail-Adresse senden.`,
  yourNewEmail: 'Ihre neue E-Mail Adresse',
  yourPassword: 'Ihr Passwort',
  requestLoginLink: 'Login Link anfordern',
  pandaPetIns: 'Panda Tierversicherungen',
  pandaInsurTech: 'Panda InsurTech GmbH',
  riskTaker: 'Risikoträger',
  riskTakerAddress: `ELEMENT Insurance AG
            <br />
            Karl-Marx-Allee 3
             <br />
            10178 Berlin
             <br />
             Deutschland`,
  commercial: 'Handelsregister (Commercial Register):',
  commercialRegister: `<span>HRB&nbsp;182671B</span>
            <br />
            Amtsgericht Charlottenburg
            <br />
            USt-IdNr: DE309829780`,
  member: 'Mitglieder des Vorstandes:',
  memberBoard: `<span>
              Dr. Astrid Stange (Vorsitzende/Chairwoman),
              <br />
              Dr. Michael Bongartz,
              <br />
              Philipp Hartz,
              <br />
              Dr. Tilo Finck
            </span>
            <br />
            <strong>
            Vorsitzender des Aufsichtsrates:
            </strong>
            <br />
            Ralf Wohltmann`,
  imprint: 'Impressum',
  dataProtection: 'Datenschutz',
  contact: 'Kontakt',
  faq: 'FAQ',
  createFlow: 'Fluss erzeugen',
  flows: 'Fließt',
  noFlowsCreateNew: 'Keine Strömungen. Erstellen Sie eine neue!',
  diagnosis: 'Diagnose',
  got: 'GOT',
  edit: 'bearbeiten',
  delete: 'löschen',
  weWorkingClaims: 'Wir arbeiten an Ihrem Leistungsfall.',
  goBackSeeHistory: `Sie können zum Verlauf der Leistungsfälle zurückkehren, um den Status Ihrer Leistungsfälle anzuzeigen Leistungsfälle und füllen Sie alle fehlenden Informationen aus.`,
  close: 'Schließen',
  hallo: 'Hallo',
  fifiChipMissing: 'Die Chipnummer von Kelly fehlt noch.',
  addDetails: 'Details ergänzen',
  pandaPetHealth: 'Panda Tierkrankenversicherung',
  productVariant: 'Produktvariante: Panda Vollkrankenschutz Exklusiv',
  active: 'aktiv',
  commOfIns: 'Versicherungsbeginn',
  renewsOn: 'Die Versicherung wird automatisch verlängert am',
  insNumber: 'Versicherungsnummer',
  petHealthDesc: `Ihre Tierkrankenversicherung schützt Sie vor den
                          Kosten im Falle einer notwendigen Operation oder
                          Heilbehandlung (sofern vereinbart) wegen Krankheit
                          oder Unfall Ihres Tieres.`,
  details: 'Einzelheiten',
  monthly: 'monatlich',
  paymentInfo: 'Zahlungsinformation',
  transactionHis: 'Transaktionsverlauf',
  reimCost: 'Kostenerstattung im Leistungsfall',
  insDog: 'Versicherter Hund',
  chipNumber: 'Chipnummer',
  downloadDoc: 'Dokumente herunterladen',
  downloadDoc2: 'Dokumente erhalten',
  showLess: 'Weniger anzeigen',
  moreInfoDoc: 'Weitere Informationen & Dokumente',
  faqLong: 'Häufige gestellte Fragen',
  selectInvoice: 'Wählen Sie das Dokument',
  name: 'Name',
  type: 'Typ',
  size: 'Größe',
  group: 'Gruppe',
  item_one: 'Artikel',
  item_other: 'Artikel',
  groupIntoOne: 'In einem Anspruch gruppieren',
  analyzeNow: 'Jetzt analysieren!',
  preview: 'Vorschau',
  back: 'zurück',
  Back: 'Zurück',
  Next: 'Weiter',
  infoAboutDamage: 'Angaben zum schadenfall',
  date: 'Datum',
  time: 'Uhrzeit',
  invoiceNumber: 'Rechnungsnummer',
  describeWhatHappen:
    'Bitte beschreiben Sie so genau wie möglich, was passiert ist',
  specificInfo: '1. Spezifische Angaben zum Erstattungsantrag',
  pet: 'Haustier',
  yourDogChip: 'Die Mikrochipnummer Ihres Hundes',
  whatTreatmentType: 'Welche Art von Behandlung hat Ihr Hund erhalten?',
  whatTreatmentTakePlace: 'Wann fand die Behandlung statt?',
  preIdentifyDiag: 'Voridentifizierte Diagnose (eine auswählen)',
  noneAbove: 'Nichts des oben Genannten',
  info: 'Information',
  symptoms: 'Symptome',
  cause: 'Ursache(n)',
  confirmSelection: 'Auswahl bestätigen',
  otherDiag: 'Andere Diagnose (eine auswählen)',
  diag: 'Diagnose',
  invoiceDate: 'Rechnungsdatum',
  desc: 'Beschreibung',
  count: 'Zählen',
  unitPrice: 'Einzelpreis',
  total: 'Gesamt',
  totalAmount: 'Gesamtbetrag',
  totalAmount2: 'Brutto',
  addGot: 'GOT hinzufügen',
  gotCheckPass: 'GOT-Check bestanden',
  gotCheckFailed: 'GOT-Prüfung fehlgeschlagen.',
  gotSelection: 'GOT-Auswahl',
  noGotSelection: 'Kein GOT ausgewählt',
  updateGot: 'GOT aktualisieren',
  updateInfo: `Wenn Sie Ihre Informationen aktualisieren, wird der Fortschritt nicht gespeichert und
             Sie müssen diese Rechnung hochladen. Sie können diesen Schritt auch beenden
             und aktualisieren Sie Ihre Informationen später.`,
  profilePage: 'Profilseite',
  updateVendorInfo: 'Lieferanteninformationen aktualisieren',
  updateInformation: 'Informationen aktualisieren',
  paymentInformation: `Im Falle einer Rückerstattung sollte die Zahlung wie folgt erfolgen
         Konto:`,
  cardNumber: 'IBAN',
  accountOwner: 'Kontoinhaber',
  questionOrSuggestion: 'Sie haben eine Frage oder Anregung?',
  send: 'Abschicken',
  title: 'Titel',
  firstName: 'Vorname',
  lastName: 'Nachname',
  attach: 'Dokument hinzufügen',
  rechnungen: 'Leistungsfälle',
  Rechnungen: 'Leistungsfälle',
  AdditionalProperties: 'Zusatzinformationen zum Leistungsfall',
  dateRangePlaceHolder: 'Anfang - Ende',
  enter: 'Gebe HIER Deine Anfrage ein.',
  createNewApproach: 'Neuen Anahng erstellen',
  sendRequest: 'Anfrage senden',
  sendChange: 'Änderungswunsch senden',
  otherRequest: 'Andere Anfrage',
  enterDate: 'Datum eingeben',
  retriever: 'Nova Scotia Duck Tolling Retriever',
  changeBirthOrAdress: 'Kundengeburtstag/E-Mail-Adresse',
  changeAdminContract: 'Änderung am Vertrag',
  CancellationOrRevocation: 'Kündigung/Widerruf',
  What_would_you_like_to_do: 'Was möchtest Du tun?',
};
