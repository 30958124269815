import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { t } from 'i18next';
import React from 'react';

export default function AreYouSure({ open, handleClose, handleYes }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ minWidth: '400px' }} id="alert-dialog-title">
        {t('areYouSure')}
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClose}>{t('no')}</Button>
        <button className="ButtonReverse Enabled" onClick={handleYes}>
          {t('yes')}
        </button>
      </DialogActions>
    </Dialog>
  );
}
