import { useEffect, useRef } from 'react';

const useStickyHeader = () => {
  const headerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        headerRef.current.classList.add('sticky-header');
      } else {
        headerRef.current.classList.remove('sticky-header');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return headerRef;
};

export default useStickyHeader;
