const checkDetailsInit = {
  total: {
    values: {
      SubTotal: 0,
      TotalTax: 0,
      InvoiceTotal: 0,
    },
    data: false,
    test: {
      total: false,
      vat: false,
    },
  },
};

const CLAIM_PROPS = {
  PET: 'Pet',
  DATE: 'Date',
  GOT_NUMBER: 'GOT Nummer',
  GOT: 'GOT',
  CONTENT: 'content',
  UNIT_PRICE: 'UnitPrice',
  AMOUNT: 'Amount',
  TAX: 'Tax',
  TAX_RATE: 'TaxRate',
  SUB_TOTAL: 'SubTotal',
  TOTAL_TAX: 'TotalTax',
  INVOICE_TOTAL: 'InvoiceTotal',
  TOTAL: 'total',
  VAT: 'vat',
  QUANTITY: 'Quantity',
  DESCRIPTION: 'Description',
  CURRENCY: 'Currency',
};

const FUSE_INIT_DATA = {
  keys: ['Bezeichnung'],
  includeScore: true,
  threshold: 0.4,
};

const USER_MODE = {
  ADMIN: 'admin',
  CLIENT: 'client',
};

const LANGUAGES = {
  DE: 'de',
  EN: 'en',
};

const CURRENCY = {
  EUR: '€',
  USD: '$',
};

const LANGUAGE_NAMES = {
  [LANGUAGES.DE]: 'German',
  [LANGUAGES.EN]: 'English',
};

const DEFAULT_VALUES = [
  {
    name: 'Customer address',
    value: 'CustomerAddress',
  },
  {
    name: 'Customer address recipient',
    value: 'CustomerAddressRecipient',
  },
  {
    name: 'Customer id',
    value: 'CustomerId',
  },
  {
    name: 'Customer name',
    value: 'CustomerName',
  },
  {
    name: 'Invoice date',
    value: 'InvoiceDate',
  },
  {
    name: 'Invoice id',
    value: 'InvoiceId',
  },
  {
    name: 'Invoice total',
    value: 'InvoiceTotal',
  },
  {
    name: 'Sub total',
    value: 'SubTotal',
  },
  {
    name: 'Total tax',
    value: 'TotalTax',
  },
  {
    name: 'Vendor address',
    value: 'VendorAddress',
  },
  {
    name: 'Vendor address recipient',
    value: 'VendorAddressRecipient',
  },
  {
    name: 'Vendor name',
    value: 'VendorName',
  },
  {
    name: 'Vendor tax id',
    value: 'VendorTaxId',
  },
  {
    name: 'Diagnosis',
    value: 'Diagnosis',
  },
];

const initialFormsOCR = {
  invoiceNumber: '',
  invoiceDate: '',
  petName: '',
  chipNumber: '',
  veterinarian: '',
  diagnosis: '',
  taxId: '',
  petNameTrigger: '',
  chipNumberTrigger: '',
  comment: '',
  diagnosisTrigger: '',
  totalNet: '',
  totalTax: '',
  totalGross: '',
};

const HEADER_LINKS = [
  {
    title: 'myProducts',
    href: '/',
    mode: [USER_MODE.CLIENT],
  },
  {
    title: 'personalData',
    href: '/account',
    mode: [USER_MODE.CLIENT],
  },
  {
    title: 'AdminChanges',
    href: '/admin',
    admin: true,
    mode: [USER_MODE.ADMIN],
  },
  {
    title: 'flowManagement',
    href: '/management',
    admin: true,
    mode: [USER_MODE.ADMIN],
  },
  // {
  //   title: "readClaim",
  //   href: "/read-claim",
  //   admin: true,
  //   mode: [USER_MODE.ADMIN],
  // },
  {
    title: 'paymentPage',
    href: '/payment-page',
    admin: true,
    mode: [USER_MODE.ADMIN],
  },
  {
    title: 'myInvoices',
    href: '/claims/',
    mode: [
      USER_MODE.CLIENT,
      // USER_MODE.ADMIN
    ],
  },
];

const COVERAGES = {
  0.7: ['70%', 'tt_cover_70'],
  0.8: ['80%', 'tt_cover_80'],
  1: ['100%', 'tt_cover_100'],
};

export {
  checkDetailsInit,
  FUSE_INIT_DATA,
  CLAIM_PROPS,
  USER_MODE,
  LANGUAGES,
  DEFAULT_VALUES,
  LANGUAGE_NAMES,
  initialFormsOCR,
  HEADER_LINKS,
  CURRENCY,
  COVERAGES,
};
