import React, { useEffect, useState } from 'react';
import Loading from '../../../components/common/Loading';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getToken } from '../../../config/token';
import { Request } from '../../../config/requests';
import TextField from '@mui/material/TextField';
import { showToast } from '../../../config/helpers';

function AuditDuplicated() {
  const { t } = useTranslation();
  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isStatisticLoading, setIsStatisticLoading] = useState(false);
  const [duplicates, setDuplicates] = useState({ claims: [] });
  const [comment, setComment] = useState('');
  const [duplicatesStatistics, setDuplicatesStatistics] = useState([]);

  const buttons = [
    { text: t('yes'), onClick: () => buttonHandler(true) },
    { text: t('no'), onClick: () => buttonHandler(false) },
    { text: t('update'), onClick: () => buttonHandler('updated') },
  ];

  function getDuplicates() {
    setIsLoading(true);
    Request({
      method: 'get',
      route: 'api/claims/duplicates',
      token: getToken(),
    })
      .then((res) => {
        return res.json();
      })
      .then(async (json) => {
        setDuplicates(json.data || null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function getStatistics() {
    setIsStatisticLoading(true);
    Request({
      method: 'get',
      route: 'api/claims/duplicates/statistics',
      token: getToken(),
    })
      .then((res) => {
        return res.json();
      })
      .then(async (json) => {
        setDuplicatesStatistics([json.data.checked, json.data.left]);
      })
      .finally(() => {
        setIsStatisticLoading(false);
      });
  }

  function buttonHandler(val) {
    Request({
      method: 'post',
      route: 'api/claims/duplicate_status',
      token: getToken(),
      values: {
        id: duplicates.id,
        is_similar: val == 'updated' ? true : val,
        comment: val == 'updated' ? 'updated' : comment,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then(() => {
        showToast(val ? t('duplTrue') : t('duplFalse'), {
          position: 'top-center',
        });
        getDuplicates();
        getStatistics();
        setComment('');
      });
  }

  const Button = ({ text, onClick }) => (
    <div
      className="ButtonReverse Enabled"
      style={{ width: '50%' }}
      onClick={onClick}
    >
      {text}
    </div>
  );

  useEffect(() => {
    getDuplicates();
    getStatistics();
    console.log('calling Duplicates...');
  }, []);

  return (
    <div className="FMContainer">
      <div className="AuditContent">
        <div className="DuplicateWrap">
          <div className="HeaderContainerDuplicates">
            <div className="HeaderDuplicates">{t('auditDuplicated')}</div>
            {!isStatisticLoading && (
              <div className="CountContainer">
                {duplicatesStatistics[1]} {t('isOfPending')}{' '}
                {duplicatesStatistics[0] + duplicatesStatistics[1]}
              </div>
            )}
            <div
              className="ButtonReverse Enabled"
              onClick={() => history('/audit')}
            >
              {t('auditClaimsBack')}
            </div>
          </div>
          {isLoading ? (
            <Loading noText fast />
          ) : (
            <div className="DuplicateDocWrap">
              {duplicates === null ? (
                <h2 className="DuplicateFinish">{t('allCompared')}</h2>
              ) : (
                <>
                  {duplicates?.claims.map((el, index) => (
                    <div className="PdfDoc" key={index}>
                      <img
                        src={`data:image/jpeg;base64, ${el.content}`}
                        height="100%"
                        width="100%"
                      />
                    </div>
                  ))}
                  <div className="NavContainer">
                    <div>
                      <TextField
                        multiline
                        label={`${t('comment')}*`}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        sx={{ width: '100%' }}
                        maxRows={4}
                      />
                    </div>
                    {buttons.map((button, index) => (
                      <Button
                        key={index}
                        text={button.text}
                        onClick={button.onClick}
                      />
                    ))}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AuditDuplicated;
