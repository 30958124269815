import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import PandaHeader from '../../../assets/modal/panda-header-logo.svg';
import PandaFooter from '../../../assets/modal/panda-footer-logo.svg';
import Close from '../../../assets/modal/close-x-white.svg';
import { useTranslation } from 'react-i18next';
import { Email_birthChange } from '../components/Email_birthChange';
import ChangeContract from '../components/ChangeContract';
import AdminPetDataChange from '../components/AdminPetDataChange';
import ReactivateContract from '../components/ReactivateContract';
import CancellationOrRevocation from '../components/CancellationOrRevocation';
import { CircularProgress } from '@mui/material';
import { Markup } from 'interweave';
import ThanksNotice from '../components/ThanksNotice';

const MainModal = ({
  selectedChange,
  selectedChangeTitle,
  isModalOpen,
  setSelectedChange,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDone, setIsDone] = React.useState(false)
  function handleClose() {
    setIsOpen(false);
    setSelectedChange(null);
    setIsDone(false)
  }
  const selectFlow = {};
  const findComponent = () => {
    if (isDone) {
      return <ThanksNotice handleClose={handleClose}></ThanksNotice>
    } else {


      switch (selectedChange) {
        case 'changeBirthOrAdress':
          return (
            <Email_birthChange
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              handleClose={handleClose}
              setIsDone={setIsDone}
            ></Email_birthChange>
          );
        case 'reactivateContract':
          return (
            <ReactivateContract
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              handleClose={handleClose}
              setIsDone={setIsDone}

            ></ReactivateContract>
          );
        case 'changePetData':
          return (
            <AdminPetDataChange
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              setIsDone={setIsDone}
              handleClose={handleClose}
            ></AdminPetDataChange>
          );
        case 'changeAdminContract':
          return (
            <ChangeContract
              isLoading={isLoading}
              setIsDone={setIsDone}
              setIsLoading={setIsLoading}
              handleClose={handleClose}
            ></ChangeContract>
          );
        case 'CancellationOrRevocation':
          return (
            <CancellationOrRevocation
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              setIsDone={setIsDone}
              handleClose={handleClose}
            ></CancellationOrRevocation>
          );

        default:
          return <div></div>;
      }
    }
  };
  return (
    <Modal
      open={isModalOpen}
      onClose={handleClose}
      className="custom_blured_background"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modal_container">
        <div className="modal_container__main">
          <div className="modal_container__header gap-4">
            <img
              className="modal_container__header__close"
              src={Close}
              onClick={handleClose}
            />
            <img
              className="modal_container__header__logo"
              style={{ margin: '10px 0 0 0' }}
              src={PandaHeader}
            />

            <div className="text-centered">
              <h3>{t(selectedChangeTitle)}</h3>
            </div>
          </div>
          <div className="modal_container__body">
            {findComponent()}
            {isLoading && (
              <div className="loading_container">
                <CircularProgress color="error" />
              </div>
            )}
          </div>

          <img className="modal_container__footer" src={PandaFooter} />
        </div>
      </div>
    </Modal>
  );
};
export default MainModal;
