import { Request } from '../config/requests';
import { getToken } from '../config/token';

const token = getToken();

export async function recordImage(files) {
  const fd = new FormData();
  files.forEach((element) => {
    fd.append('files', element);
  });

  try {
    return await Request({
      method: 'post',
      token: token,
      route: `api/claims/upload_files`,
      formData: fd,
    });
  } catch (err) {
    console.error(err);
  }
}
