import React, { useState } from 'react';
import Header from '../../components/common/Header';
import Loading from '../../components/common/Loading';
import Footer from '../../components/common/Footer';
import { useTranslation } from 'react-i18next';
import FlowManagement from '../flowManagement/FlowManagement';
import useLocalStorage from 'use-local-storage';
import { isMobile } from 'react-device-detect';
import PawUI from '../../components/ui/PawUI';
import MainModal from './modals/MainModal';

function AdminChanges() {
  const { t } = useTranslation();
  const [flows, setFlows] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setOpenModal] = useState(false);
  const [selectedChange, setSelectedChange] = useState(null);
  const [selectedChangeTitle, setSelectedChangeTitle] = useState(null);
  const Btns = [
    {
      title: 'changeBirthOrAdress',
      headTitle: 'Change birthday/email address',
      color: '#FF8665',
    },
    {
      title: 'changeAdminContract',
      headTitle: 'Contract change',
      color: '#F89E9E',
    },
    {
      title: 'CancellationOrRevocation',
      headTitle: 'Cancellation/withdrawal',
      color: '#EAC3BA',
    },
    {
      title: 'changePetData',
      headTitle: 'Change animal data',
      color: '#F8D89E',
    },
    {
      title: 'reactivateContract',
      headTitle: 'Reactivate contract',
      color: '#D8A75E',
    },
  ];
  const openModal = (title, headTitle) => {
    setSelectedChange(title);
    setSelectedChangeTitle(headTitle);
    setOpenModal(true);
  };
  const handleClose = () => {
    setSelectedChange(null);
    setOpenModal(false);
  };

  return (
    <div className="main-container">
      <Header />
      <div className="page-content">
        <div className="claims">
          {isMobile ? (
            <>
              <PawUI
                top="1vh"
                right="6vw"
                rotation="right"
                width="25vw"
                zIndex="0"
              />
              <PawUI
                top="53vh"
                left="-20vw"
                rotation="right"
                width="60vw"
                zIndex="0"
              />
              <PawUI
                bottom="-23vh"
                right="-27vw"
                rotation="left"
                width="70vw"
                zIndex="0"
              />
            </>
          ) : (
            <>
              <PawUI
                top="20vh"
                right="1vw"
                rotation="right"
                width="350px"
                zIndex="0"
              />
              <PawUI
                bottom="-10vh"
                left="5vw"
                rotation="left"
                width="150px"
                zIndex="0"
              />
              <PawUI
                top="10vh"
                left="20vw"
                rotation="left"
                width="250px"
                zIndex="0"
              />
            </>
          )}
        </div>
        {isLoading && <Loading noText fast />}

        {!isLoading && (
          <div className="admin__changes__main">
            <div className="m-auto my-3 d-flex justify-content-center dark_red_title z-9">
              {t('AdminChangesHeader')}
            </div>
            <div className="btns__container">
              {Btns.map((el, key) => {
                return (
                  <button
                    key={key}
                    style={{ background: el.color }}
                    onClick={() => openModal(el.title, el.headTitle)}
                    className="change__btn"
                  >
                    {t(el.title)}
                  </button>
                );
              })}
            </div>
            <MainModal
              selectedChange={selectedChange}
              selectedChangeTitle={selectedChangeTitle}
              isModalOpen={isModalOpen}
              setIsOpen={setOpenModal}
              setSelectedChange={setSelectedChange}
            />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default AdminChanges;
