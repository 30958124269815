import { styled, Menu } from '@mui/material';

export const StyledMenu = styled(Menu)(() => ({
  '& .MuiPopover-paper': {
    background: 'none',
    boxShadow: 'none',
  },
  '& .MuiMenu-list': {
    background: 'none',
    color: 'black',
    borderRadius: '10px',
    padding: '0',
  },
  '& .MuiMenuItem-root': {
    height: '35px',
    background: 'var(--b-color-main-1)',
    color: 'var(--b-color-text-primary-2)',
    margin: '8px',
    borderRadius: '14px',
    fontFamily: 'var(--b-font-family-primary-1)',
    fontSize: 'var(--b-font-size-m)',
    boxShadow: '4px 8px 5px -3px rgba(0,0,0,0.2)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .MuiMenuItem-root:hover': {
    color: '#EB5955',
    background: 'var(--b-color-text-primary-2)',
  },
}));

export const StyledContactMenu = styled(Menu)(() => ({
  '& .MuiPopover-paper': {
    background: 'none',
    boxShadow: 'none',
  },
  '& .MuiMenu-list': {
    background: 'none',
    color: 'black',
    borderRadius: '10px',
    padding: '0',
  },
  '& .MuiMenuItem-root': {
    position: 'relative',
    height: '35px',
    width: '175px',
    color: 'var(--b-color-text-primary-2)',
    background: 'var(--b-color-main-1)',
    margin: '8px 0px 8px 25px',
    padding: '0 0 0 25px',
    borderRadius: '0 14px 14px 0',
    fontFamily: 'var(--b-font-family-primary-1)',
    fontSize: 'var(--b-font-size-m)',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
  '& .MuiListItemIcon-root': {
    width: '40px',
    height: '40px',
    position: 'absolute',
    left: '-25px',
    borderRadius: '50%',
    boxShadow: '4px 0px 4px rgba(0, 0, 0, 0.25)',
  },
}));
