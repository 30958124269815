import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../../config/token';

export default function RequireAuth({ children }) {
  const { token } = getToken() ? JSON.parse(getToken()) : {};
  const router = useNavigate();

  useEffect(() => {
    if (!token) router('/');
  }, []);

  return token ? children : router('/');
}
