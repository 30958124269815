import React from 'react';
import { Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CatDog from '../../assets/Insurances/cat_dog.svg';
import { isMobile } from 'react-device-detect';

export default function RenderPets({ userPets }) {
  const { t } = useTranslation();
  const history = useNavigate();

  const rows = [];
  const numPets = userPets.length;

  for (let i = 0; i < numPets; i += 3) {
    const rowPets = userPets.slice(i, i + 3);
    const row = (
      <div key={`row-${i}`} className="pet_container__row">
        {rowPets.map((pet) => (
          <Tooltip title={t('seeDetails')} key={pet?.policy.id}>
            <div
              className="pet_container__item"
              onClick={() => history(`/pet/${pet?.policy.id}`)}
            >
              <div
                className={
                  rowPets.length < 3
                    ? 'pet_container__wrapper'
                    : 'pet_container__wrapper small'
                }
              >
                <div>
                  {pet?.image ? (
                    <img
                      src={`data:image/jpeg;base64, ${pet?.image}`}
                      alt="CatDog"
                      height="100%"
                    />
                  ) : (
                    <img src={CatDog} alt="CatDog" width="95%" height="95%" />
                  )}
                </div>
              </div>
              <div
                className="pet_container__name"
              // style={{ maxWidth: isMobile ? '130px' : '192px' }}
              >
                {pet?.attributes.nameOfPet}
                {userPets.filter(
                  (el) => el.attributes.nameOfPet === pet.attributes.nameOfPet
                ).length > 1 && <div>{pet?.policy.policyNo}</div>}
              </div>
            </div>
          </Tooltip>
        ))}
        {rowPets.length < 3 &&
          Array(3 - rowPets.length)
            .fill()
            .map((_, index) => (
              <div
                key={`empty-${i}-${index}`}
                className="pet_container__item empty"
              />
            ))}
      </div>
    );

    rows.push(row);
  }

  return rows;
}
