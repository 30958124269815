import { useNavigate, useParams } from 'react-router-dom';
import { Request } from '../../../config/requests';
import { getToken } from '../../../config/token';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const FlowStepsConfirm = ({
  steps,
  disabled,
  message,
  language,
  translations,
}) => {
  const { flow_id } = useParams();
  const history = useNavigate();
  const { t } = useTranslation();
  const flowSteps = useSelector((s) => s.flow.flowSteps);

  const createPayload = (subSteps = null) => {
    let flows = subSteps || steps;
    flows = flows.map((i, index) => {
      const fs = flowSteps[index];
      if (fs) {
        i.group = fs.group;
      }
      return i;
    });
    let values = {
      data: [
        {
          content: [],
          autofill: flows,
          translations: { initial_language: language, translations },
        },
      ],
    };

    // 1 FLOW
    flows.forEach((step, idx) => {
      values.data[0].content.push({});
      // 1 STEP

      // MCQ
      if (step.code === 'mcq') {
        step.content.forEach((mcq) => {
          values.data[0].content[idx]['type'] = step.code;

          if (!values.data[0].content[idx].content) {
            values.data[0].content[idx]['content'] = [];
          }

          values.data[0].content[idx]['content'].push({
            choice: mcq.choice,
            title: step.title,
            description: step.description,
            ...createPayload(mcq.content),
          });
        });
      }
      // OTHERS
      else if (
        step.value ||
        step.title ||
        step.selected ||
        step.code === 'invoice_table'
      ) {
        values.data[0].content[idx]['type'] = step.code;
        values.data[0].content[idx]['content'] = {
          value: step.value,
          title: step.title || null,
          description: step.description || null,
        };

        if (step.code === 'dropdown') {
          values.data[0].content[idx].content.selected = step.selected;
        }

        if (step.code === 'date_range') {
          values.data[0].content[idx]['content']['value_2'] = step.value_2;
        }
      } else if (step.code === 'trigger') {
        values.data[0].content[idx]['type'] = step.code;
        values.data[0].content[idx]['content'] = {
          values: step.values,
          dropdown: { id: step.dropdownId },
        };
      }
      // GOT or DIAGNOSIS
      else {
        if (Array.isArray(step))
          step.forEach((sub_step) => {
            values.data[0].content[idx][sub_step.code] = {
              type: sub_step.code,
              values: sub_step.value,
            };
          });
      }
    });

    return values;
  };

  const createFlow = () => {
    const values = createPayload();

    Request({ method: 'post', route: 'api/flows', values, token: getToken() })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.status) history('/management');
      });
  };

  const updateFlow = () => {
    const values = createPayload();

    Request({
      method: 'put',
      route: `api/flows/${flow_id}`,
      values,
      token: getToken(),
    })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        if (json.status) history('/management');
      });
  };

  const handleClick = () => {
    if (flow_id) {
      updateFlow();
    } else {
      createFlow();
    }
  };

  return (
    <div>
      <span
        data-tooltip-id="my-tooltip"
        data-tooltip-content={message ? message : ''}
        data-tooltip-place="top"
      >
        <button
          className={
            disabled
              ? 'ButtonReverse FullWidth'
              : 'ButtonReverse FullWidth Enabled'
          }
          onClick={handleClick}
          disabled={disabled}
        >
          {flow_id ? t('updateFlow') : t('createFlow')}
        </button>
      </span>
      <Tooltip id="my-tooltip" />
    </div>
  );
};

export default FlowStepsConfirm;
