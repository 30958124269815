import { useCallback, useState } from 'react';
import { Request } from '../config/requests';
import { getToken } from '../config/token';

export default function useDropdownById() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const refetch = useCallback(async (id) => {
    try {
      setIsLoading(true);
      const res = await Request({
        method: 'get',
        token: getToken(),
        route: `api/dropdowns/${id}`,
      });
      const data = await res.json();
      setData(data);
      return data;
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return [refetch, data, isLoading];
}
