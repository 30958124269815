import { Box, Skeleton } from '@mui/material';
import { isMobile, isMobileOnly } from 'react-device-detect';
import React from 'react';
import { USER_MODE } from '../../../constants/ApplicationConstants';

export default function InvoiceTableFilterSkeleton({ mode }) {
  return (
    <>
      {isMobile ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {mode !== USER_MODE.ADMIN && (
            <>
              <Skeleton
                variant="rounded"
                width={isMobileOnly ? '96%' : '50%'}
                height={30}
                style={{ margin: '1vh 0 0 0' }}
              />
              <Skeleton
                variant="rounded"
                width={isMobileOnly ? '96%' : '50%'}
                height={30}
                style={{ margin: '1vh 0 0 0' }}
              />
            </>
          )}
          <Skeleton
            variant="rounded"
            width={isMobileOnly ? '96%' : '80%'}
            height={isMobileOnly ? 300 : 450}
            style={{ margin: '4vh 0 0 0' }}
          />
        </div>
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            {mode !== USER_MODE.ADMIN && (
              <>
                <Skeleton
                  variant="rounded"
                  width={100}
                  height={40}
                  style={{ margin: '2vh 0 0 1vw' }}
                />
                <Skeleton
                  variant="rounded"
                  width={100}
                  height={40}
                  style={{ margin: '2vh 0 0 1vw' }}
                />
              </>
            )}
          </Box>
          <Skeleton
            variant="rounded"
            width={isMobile ? '80%' : '100%'}
            height={isMobile ? 100 : 150}
            style={{ margin: '4vh 0 0 0' }}
          />
        </>
      )}
    </>
  );
}
