import React, { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import RunningDots from '../../assets/loader/Dots.gif';
import { useSelector } from 'react-redux';

function Loading({
  noText,
  fast,
  text,
  subText,
  height = null,
  width = null,
  margin = null,
  size = 40,
  color = 'inherit',
  scroll = true,
}) {
  const { t } = useTranslation();
  const flowType = useSelector((s) => s.flow.flowType);

  useEffect(() => {
    if (scroll) window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div
      className="SlowShow LoadingContainer"
      style={{
        height: height || 'unset',
        width: width || 'unset',
        margin: margin || 'unset'
      }}
    >
      {fast ? (
        <CircularProgress style={{ color: color }} size={size} />
      ) : (
        <>
          <div className="loader-container">
            <div className="loader-back">
              <div className="loader-text">
                {text || t('loaderText')}
                <img className="RunningDots" src={RunningDots} />
              </div>
              <div className="loader-subtext">
                {subText ||
                  (flowType === 'Cost estimation'
                    ? t('loaderSubText_CE')
                    : t('loaderSubText'))}
              </div>
            </div>
          </div>
        </>
      )}
      {!noText && <div>{t('weAreAnalyzing')}...</div>}
    </div>
  );
}

export default Loading;
