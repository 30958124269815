import { showToast } from '../../config/helpers';
import { Request } from '../../config/requests';

export async function requestPrice(policyId, setPriceList, pendingPrice, t) {
  pendingPrice(true);

  return Request({
    method: 'get',
    route: `api/v2/all_coverage_info?policyId=${policyId}`,
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(`Request failed with status ${res.status}`);
      }
      return res.json();
    })
    .then((json) => {
      setPriceList(json.data);
    })
    .catch((err) => {
      console.error(err);
      showToast(t('somethingWentWrong'), { error: true });
    })
    .finally(() => {
      pendingPrice(false);
    });
}

export async function sendUserRequest(
  message,
  selectedFiles,
  selectedPetInfo,
  t
) {
  const fd = new FormData();
  selectedFiles.forEach((i) => fd.append('files', i));
  fd.append('reportType', 'Change contract request');
  fd.append('customerEmail', selectedPetInfo?.quote?.customer?.email);
  fd.append('policyNo', selectedPetInfo?.policy?.policyNo);
  fd.append('policyId', selectedPetInfo?.quote?.policy?.id);
  fd.append('name', selectedPetInfo?.data?.nameOfPet);
  fd.append(
    'tariff',
    selectedPetInfo?.products?.petInsurance?.data?.selectedPackage
  );
  fd.append('message', message);

  return Request({
    method: 'post',
    route: 'api/users/change_data_request',
    formData: fd,
  })
    .then((res) => {
      if (!res.ok) throw new Error(`Request failed with status ${res.status}`);
    })
    .catch(() => {
      showToast(t('somethingWentWrong'), { error: true });
    });
}

export async function submitUpdates(policyID, tariff, coverage, mta_date, t) {
  console.log('submitUpdates > policyID', policyID);
  console.log('submitUpdates > tariff', tariff);
  console.log('submitUpdates > coverage', coverage);
  console.log('submitUpdates > mta_date', mta_date);

  return Request({
    method: 'post',
    route: `api/policy/change_contract`,
    values: {
      policyId: policyID,
      newTariff: tariff,
      newCoverage: coverage,
      mtaDate: mta_date,
    },
  })
    .then((res) => {
      if (!res.ok) {
        console.error('Response not OK!');
        throw new Error(`Request failed with status ${res.status}`);
      }
      return res.json();
    })
    .then((json) => {
      console.log('submitUpdates > json', json);
    })
    .catch((error) => {
      console.error(error);
      showToast(t('somethingWentWrong'), { error: true });
    });
}
