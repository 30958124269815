import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { clearArrows, updateSameTitle } from '../../../config/actions';
import { useTranslation } from 'react-i18next';
import FlowDefaultValueModal from './FlowDefaultValueModal';
import { DEFAULT_VALUES } from '../../../constants/ApplicationConstants';
import { getUploads } from './flow-helper';
import { getStepTitles } from '../../../config/helpers';

const FlowStepsItem = ({
  idItem,
  step,
  steps,
  setSteps,
  isClient,
  onDeleteMCQOption,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const dropdowns = useSelector((s) => s.flow.dropdowns);

  const [value, setValue] = useState(
    step.value
      ? step.value
      : step.code === 'got' || step.code === 'diagnosis'
        ? []
        : ''
  );
  const [valueTwo, setValueTwo] = useState('');
  const [title, setTitle] = useState(
    step.code ? step.title : step.code === 'mcq' ? step.content[0]?.title : ''
  );
  const [description, setDescription] = useState(
    step.code
      ? step.description
      : step.code === 'mcq'
        ? step.content[0]?.description
        : ''
  );
  const [isOnCreate, setIsOnCreate] = useState(false);
  const [question, setQuestion] = useState('');
  const [currentStep, setCurrentStep] = useState(null);
  const [openDefaultValue, setOpenDefaultValue] = useState(false);
  const flowSteps = useSelector((s) => s.flow.flowSteps);

  const handleSelect = (e) => {
    setValue(e.target.value);
    step.value = e.target.value;
    setSteps([...steps]);
  };

  const handleDropdown = (e) => {
    step.selected = e.target.value;
    setSteps([...steps]);
  };

  const handleDefaultSelect = (step) => {
    setCurrentStep(step);
    setOpenDefaultValue(true);
  };

  const stepTitles = useMemo(() => {
    return getStepTitles(flowSteps).filter((i) => i);
  }, [flowSteps]);

  const handleTitle = (e) => {
    const value = e.target.value;
    dispatch(updateSameTitle(stepTitles.includes(value.trim())));
    setTitle(value);
    step.title = value;
    setSteps([...steps]);
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
    step.description = e.target.value;
    setSteps([...steps]);
  };

  const handleQuestion = (e) => {
    setQuestion(e.target.value);
    setSteps([...steps]);
  };

  const handleInvoiceTable = (e) => {
    step.uploadId = e.target.value;
    setSteps([...steps]);
  };

  const handleOcr = (e) => {
    step.ocr = e.target.checked;
    setSteps([...steps]);
  };

  const handleDropdownValues = (e) => {
    step.values = e.target.value;
    setSteps([...steps]);
  };

  const handleDropdownId = (e) => {
    step.dropdownId = e.target.value;
    setSteps([...steps]);
  };

  const handleDate = (newValue, isSecond = false) => {
    if (isSecond) {
      setValueTwo(newValue);
      step.value_2 = newValue.format('DD-MM-YYYY');
    } else {
      setValue(newValue);
      step.value = newValue.format('DD-MM-YYYY');
    }
  };

  const onAddQuestion = () => setIsOnCreate(!isOnCreate);

  const onSelectValue = (item, upload) => {
    step.defaultValue = item.value;
    step.uploadId = upload.uniqueId;
    step.uploadColor = upload.color;
    setCurrentStep(null);
    setOpenDefaultValue(false);
    setSteps([...steps]);
  };

  const getDefaultValue = (value) => {
    const v = DEFAULT_VALUES.find((i) => i.value === value);
    return v || {};
  };

  const addQuestion = () => {
    if (
      !step.options.find((option) => option.name === question) &&
      question !== ''
    )
      step.options.push({ name: question });
    setQuestion('');
    setSteps([...steps]);

    setIsOnCreate(!isOnCreate);
  };

  const onDeleteStep = () => {
    let index = steps.indexOf(step);

    if (step.code === 'existing_claim' || step.code === 'new_claim') {
      setSteps([]);
      return;
    }

    if (index > -1) {
      steps.splice(index, 1);
      setSteps([...steps]);
    } else {
      let index = steps[1].indexOf(step);
      steps[1].splice(index, 1);
      if (steps[1].length === 0) {
        steps.splice(1, 1);
      }
      setSteps([...steps]);
    }

    dispatch(clearArrows());
    setSteps([...steps]);
  };

  const currentUploads = useMemo(() => {
    const ulds = getUploads(step, flowSteps);
    return ulds.filter((i) => i.code === 'upload' && i.ocr);
  }, [flowSteps, step]);

  const currentOptions = useMemo(() => {
    const dropdown = dropdowns.find((i) => i.id === step.dropdownId);
    return dropdown?.options || [];
  }, [step.dropdownId]);

  const defaultValueComponent = (step) => (
    <>
      <Button variant="outlined" onClick={() => handleDefaultSelect(step)}>
        {t('Default value')}
      </Button>

      {step.defaultValue && (
        <Box
          sx={{
            display: 'flex',
            marginTop: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <span
            className="upload-color"
            style={{ backgroundColor: step.uploadColor }}
          ></span>
          <span>{getDefaultValue(step.defaultValue).name}</span>
        </Box>
      )}
    </>
  );

  return (
    <div
      className="FlowStepsItem"
      style={{
        marginBottom:
          step.code === 'new_claim' || step.code === 'existing_claim'
            ? '0'
            : '20px',
      }}
    >
      {step.code !== 'diagnosis' && step.code !== 'got' && (
        <div className="FSIHeader">
          <div className="FSITitle">
            {step.text.includes('#')
              ? `${t(step.text.split('#')[0].trim())} #${step.text.split('#')[1]}`
              : t(step.text)}
          </div>

          {step.code !== 'trigger' && step.code !== 'type' && (
            <CloseOutlinedIcon
              onClick={onDeleteStep}
              className="FSIHeader__close"
            />
          )}
        </div>
      )}

      {step.withText && (
        <div className="FSIHeader">
          <div
            className="FSITitle"
            style={{
              margin: 0,
              padding: '10px 20px',
              background: 'var(--background_2)',
            }}
          >
            {t(step.text)}
          </div>
          <CloseOutlinedIcon
            onClick={onDeleteStep}
            className="FSIHeader__close"
          />
        </div>
      )}

      {step.withDescription && (
        <div className="FSIGeneral">
          <div className="BasicInput">
            <FormControl
              sx={{
                width: step.inputWidth,
                padding: '0 !important',
                height: '56px !important',
              }}
            >
              <InputLabel
                id="demo-multiple-name-label-title"
                sx={{ background: 'var(--b-color-text-primary-2)' }}
              >
                {t('title')}
              </InputLabel>

              <Input
                id="demo-multiple-name-title"
                className={!title ? 'BasicInput__error' : ''}
                type="text"
                onChange={(e) => handleTitle(e)}
                value={title}
                sx={{
                  margin: '0 !important',
                  height: '100% !important',
                  width: '100%',
                  border: 'thin solid var(--b-color-bg-additional-2)',
                  fontSize: '12px',
                  borderRadius: '5px',
                  backgroundColor: 'var(--b-color-text-primary-2)',
                }}
              />
            </FormControl>
          </div>

          <div className="BasicInput" style={{ marginTop: '15px' }}>
            <FormControl
              sx={{
                width: step.inputWidth,
                padding: '0 !important',
                height: '56px !important',
              }}
            >
              <InputLabel
                id="demo-multiple-name-label-description"
                sx={{ background: 'var(--b-color-text-primary-2)' }}
              >
                {t('desc')}
              </InputLabel>

              <Input
                id="demo-multiple-name-description"
                type="text"
                onChange={(e) => handleDescription(e)}
                value={description}
                sx={{
                  margin: '0 !important',
                  height: '100% !important',
                  width: '100%',
                  border: 'thin solid var(--b-color-bg-additional-2)',
                  fontSize: '12px',
                  borderRadius: '5px',
                  backgroundColor: 'var(--b-color-text-primary-2)',
                }}
              />
            </FormControl>
          </div>

          {step.code !== 'date_range' &&
            step.code !== 'mcq' &&
            step.code !== 'payment' &&
            step.isOcr && (
              <FormControl
                sx={{
                  width: step.inputWidth,
                  margin:
                    step.code === 'got' ||
                    step.code === 'diagnosis' ||
                    step.code === 'status' ||
                    step.code === 'type'
                      ? '10px auto 0'
                      : '10px 0 0',
                }}
              >
                {defaultValueComponent(step)}
              </FormControl>
            )}
        </div>
      )}

      {step.code === 'invoice_table' && step.isOcr && (
        <div className="FSIGeneral">
          <div>
            <Box
              sx={{
                marginLeft: '10px',
                marginRight: '10px',
                marginTop: '0',
                display: 'grid',
                gap: '10px',
              }}
            >
              <FormControl
                sx={{
                  width: step.inputWidth,
                  margin: '10px 0 0',
                }}
              >
                <InputLabel
                  id="demo-multiple-name-label"
                  sx={{ background: 'var(--b-color-text-primary-2)' }}
                >
                  Select upload
                </InputLabel>

                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  value={step.uploadId}
                  onChange={(e) => handleInvoiceTable(e)}
                  input={
                    <OutlinedInput
                      label="Name"
                      className={value.length ? '' : 'BasicInput__error'}
                    />
                  }
                >
                  {currentUploads.map((item, idx) => (
                    <MenuItem key={`item-${idx}`} value={item.uniqueId}>
                      {item.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </div>
        </div>
      )}

      {step.withTitle && (
        <div className="FSIGeneral">
          <div className="BasicInput">
            <FormControl
              sx={{
                width: step.inputWidth,
                padding: '0 !important',
                height: '56px !important',
              }}
            >
              <InputLabel
                id="demo-multiple-name-label-title"
                sx={{ background: 'var(--b-color-text-primary-2)' }}
              >
                {t('title')}
              </InputLabel>

              <Input
                id="demo-multiple-name-title"
                className={!title ? 'BasicInput__error' : ''}
                type="text"
                onChange={(e) => handleTitle(e)}
                value={title}
                sx={{
                  margin: '0 !important',
                  height: '100% !important',
                  width: '100%',
                  border: 'thin solid var(--b-color-bg-additional-2)',
                  fontSize: '12px',
                  borderRadius: '5px',
                  backgroundColor: 'var(--b-color-text-primary-2)',
                }}
              />
            </FormControl>
          </div>
        </div>
      )}

      {step.code === 'mcq' && (
        <div className="BasicInput MCQHolder">
          {isClient && (
            <Input
              className={value === '' ? 'BasicInput__error' : ''}
              type="text"
              onChange={(e) => handleSelect(e)}
              sx={{
                width: step.inputWidth,
              }}
            />
          )}

          <div className="MCQ">
            {step.options.map((option, idx) => (
              <div key={`mcq-${idx}`} className="MCQItem">
                <span className="MCQItem__text">{option.name}</span>

                <CloseOutlinedIcon
                  onClick={() => onDeleteMCQOption(idx, idItem)}
                  className="MCQItem__close"
                  sx={{ width: '20px', height: '20px' }}
                />
              </div>
            ))}

            {isOnCreate ? (
              <div className="MCQCreate">
                <Input
                  type="text"
                  onChange={(e) => handleQuestion(e)}
                  sx={{
                    width: '70% !important',
                    border: 'thin solid var(--b-color-bg-additional-2)',
                    fontSize: '12px',
                    borderRadius: '5px',
                    backgroundColor: 'var(--b-color-text-primary-2)',
                  }}
                />

                <button
                  className="ButtonReverse Enabled MCQBtn"
                  onClick={addQuestion}
                >
                  <CheckIcon />
                </button>
              </div>
            ) : (
              <button
                className="ButtonReverse Enabled MCQBtn"
                onClick={onAddQuestion}
              >
                <AddIcon />
              </button>
            )}
          </div>
        </div>
      )}

      {step.code === 'oq' && isClient && (
        <>
          <Input
            className={value === '' ? 'BasicInput__error' : ''}
            type="text"
            onChange={(e) => handleSelect(e)}
            sx={{
              width: step.inputWidth,
              border: 'thin solid var(--b-color-bg-additional-2)',
              fontSize: '12px',
              borderRadius: '5px',
              backgroundColor: 'var(--b-color-text-primary-2)',
            }}
          />
        </>
      )}

      {step.code === 'date' && isClient && (
        <>
          <DatePicker
            label={t('date')}
            value={value}
            onChange={(newValue) => {
              handleDate(newValue);
            }}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </>
      )}

      {step.code === 'date_range' && isClient && (
        <div className="DateRange">
          <DatePicker
            label={step.label}
            value={value}
            onChange={(newValue) => {
              handleDate(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} sx={{ width: step.inputWidth }} />
            )}
          />

          <DatePicker
            label={step.label_2}
            value={valueTwo}
            onChange={(newValue) => {
              handleDate(newValue, true);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                sx={{ width: step.inputWidth }}
              />
            )}
          />
        </div>
      )}

      {step.code === 'upload' && (
        <div className="Upload-content">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              justifyContent: 'space-between',
              marginRight: '15px',
            }}
          >
            <FormControlLabel
              control={<Switch color="primary" />}
              label="OCR"
              labelPlacement="start"
              checked={step.ocr}
              onChange={(e) => handleOcr(e)}
            />
            <span
              className="upload-color"
              style={{ backgroundColor: step.color }}
            ></span>
          </Box>

          <Box
            sx={{
              marginLeft: '10px',
              marginRight: '10px',
              marginTop: '15px',
              display: 'grid',
              gap: '10px',
            }}
          >
            <TextField
              onChange={(e) => handleTitle(e)}
              id="title"
              value={title}
              label={t('title')}
            />

            <TextField
              id="comment"
              onChange={(e) => handleDescription(e)}
              value={description}
              label={t('desc')}
            />
          </Box>
        </div>
      )}

      {step.code === 'dropdown' && (
        <div className="Upload-content">
          <Box
            sx={{
              marginLeft: '10px',
              marginRight: '10px',
              marginTop: '15px',
              display: 'grid',
              gap: '10px',
            }}
          >
            <FormControl
              sx={{
                // width: step.inputWidth,
                margin: '10px 10px 0',
              }}
            >
              <InputLabel
                id="demo-multiple-name-label"
                sx={{ background: 'var(--b-color-text-primary-2)' }}
              >
                {step.label}
              </InputLabel>

              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={step.selected}
                onChange={(e) => handleDropdown(e)}
                input={
                  <OutlinedInput
                    label={t('name')}
                    className={value.length ? '' : 'BasicInput__error'}
                  />
                }
              >
                {dropdowns.map((item, idx) => (
                  <MenuItem key={`item-${idx}`} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
      )}

      {step.code === 'trigger' && (
        <div className="Upload-content">
          <Box
            sx={{
              marginLeft: '10px',
              marginRight: '10px',
              marginTop: '15px',
              display: 'grid',
              gap: '10px',
            }}
          >
            <FormControl
              sx={{
                // width: step.inputWidth,
                margin: '10px 10px 0',
              }}
            >
              <InputLabel
                id="demo-multiple-name-label"
                sx={{ background: 'var(--b-color-text-primary-2)' }}
              >
                Dropdown
              </InputLabel>

              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={step.dropdownId}
                onChange={(e) => handleDropdownId(e)}
                input={
                  <OutlinedInput
                    label={t('name')}
                    className={value.length ? '' : 'BasicInput__error'}
                  />
                }
              >
                {dropdowns.map((item, idx) => (
                  <MenuItem key={`item-${idx}`} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              sx={{
                // width: step.inputWidth,
                margin: '10px 10px 0',
              }}
            >
              <InputLabel
                id="demo-multiple-name-label"
                sx={{ background: 'var(--b-color-text-primary-2)' }}
              >
                {t('values')}
              </InputLabel>

              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={step.values || []}
                onChange={(e) => handleDropdownValues(e)}
                input={
                  <OutlinedInput
                    label={t('name')}
                    className={value.length ? '' : 'BasicInput__error'}
                  />
                }
                multiple
              >
                {currentOptions.map((item, idx) => (
                  <MenuItem key={`option-${idx}`} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
      )}

      {step.code !== 'mcq' &&
        step.code !== 'oq' &&
        step.code !== 'new_claim' &&
        step.code !== 'existing_claim' &&
        step.code !== 'date' &&
        step.code !== 'upload' &&
        step.code !== 'date_range' &&
        step.code !== 'dropdown' &&
        step.code !== 'invoice_table' &&
        step.code !== 'trigger' &&
        step.code !== 'payment' && (
          <>
            <FormControl
              sx={{
                width: step.inputWidth,
                margin:
                  step.code === 'got' ||
                  step.code === 'diagnosis' ||
                  step.code === 'status' ||
                  step.code === 'type'
                    ? '10px auto 0'
                    : '10px 0 0',
              }}
            >
              <InputLabel
                id="demo-multiple-name-label"
                sx={{ background: 'var(--b-color-text-primary-2)' }}
              >
                {t(step.label)}
              </InputLabel>

              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={value}
                onChange={(e) => handleSelect(e)}
                input={
                  <OutlinedInput
                    label={t('name')}
                    className={value.length ? '' : 'BasicInput__error'}
                  />
                }
                multiple={step.code === 'got' || step.code === 'diagnosis'}
              >
                {step.options.map((item, idx) => (
                  <MenuItem
                    key={`item-${idx}`}
                    value={item.name ? item.name : item.Bezeichnung}
                  >
                    {item.name ? t(item.name) : t(item.Bezeichnung)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {step.code === 'diagnosis' && step.isOcr && (
              <FormControl
                sx={{
                  width: step.inputWidth,
                  margin:
                    step.code === 'got' ||
                    step.code === 'diagnosis' ||
                    step.code === 'status' ||
                    step.code === 'type'
                      ? '10px auto 0'
                      : '10px 0 0',
                }}
              >
                {defaultValueComponent(step)}
              </FormControl>
            )}
          </>
        )}

      <FlowDefaultValueModal
        handleClose={setOpenDefaultValue}
        open={openDefaultValue}
        step={currentStep}
        onSelectValue={onSelectValue}
      />
    </div>
  );
};

export default FlowStepsItem;
