import { useCallback, useEffect, useState } from 'react';

export function useTableScroll() {
  const [rectHeader, setRectHeader] = useState(null);
  const [rectBody, setRectBody] = useState(null);
  const [rectBtn, setRectBtn] = useState(null);
  const [listOnfItems, setListOfItems] = useState(null);
  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  const [triggered, setTriggered] = useState(0);

  const recalculate = () => [setTriggered((prev) => prev + 1)];

  const syncScroll = () => {
    if (rectHeader && rectBody && !rectBtn) {
      rectHeader.scrollLeft = rectBody.scrollLeft;
    }
    if (rectHeader && rectBody && rectBtn) {
      rectHeader.scrollLeft = rectBody.scrollLeft;
      rectBtn.scrollLeft = rectBody.scrollLeft;
    }
  };

  const scrollToBottom = () => {
    if (!rectBody) return;
    setTimeout(() => {
      const scrollHeight = rectBody.scrollHeight;
      rectBody.scrollTo({
        top: scrollHeight - 50,
        behavior: 'smooth',
      });
    }, 500);
  };

  const ref1 = useCallback((node) => {
    if (node !== null) {
      setRectHeader(node);
    }
  }, []);

  const ref2 = useCallback((node) => {
    if (node !== null) {
      setRectBody(node);
    }
  }, []);

  const ref3 = useCallback((node) => {
    if (node !== null) {
      setRectBtn(node);
    }
  }, []);

  const ref4 = useCallback((node) => {
    if (node !== null) {
      setListOfItems(node);
    }
  }, []);

  useEffect(() => {
    const bodyTableContainer = rectBody;
    if (!bodyTableContainer) return;

    const scrollbarWidth =
      bodyTableContainer.offsetWidth - bodyTableContainer.clientWidth;
    setScrollbarWidth(scrollbarWidth);
  }, [rectBody, listOnfItems?.children.length, triggered]);

  useEffect(() => {
    const headerTable = rectHeader;
    const btnTable = rectBtn;

    if (!headerTable) return;
    headerTable.style.width = `calc(100% - ${scrollbarWidth}px)`;
    if (rectBtn) {
      btnTable.style.width = `calc(100% - ${scrollbarWidth}px)`;
    }
  }, [scrollbarWidth, rectHeader, rectBtn]);

  return {
    headerRef: ref1,
    bodyRef: ref2,
    btnRef: ref3,
    syncScroll,
    listOfItemsRef: ref4,
    recalculate: recalculate,
    scrollToBottom: scrollToBottom,
  };
}
