import { USER_MODE } from '../constants/ApplicationConstants';
import Logo from '../assets/logos/logo_header.png';

const lang = localStorage.getItem('langauge');

const defaultState = {
  token: null,
  data: null,
  mode: USER_MODE.CLIENT,
  logo: Logo,
  user: null,
  language: lang || 'de',
};

export default function (state = defaultState, action = {}) {
  switch (action.type) {
    case 'UPDATE_TOKEN':
      return {
        ...state,
        token: action.token,
      };
    case 'UPDATE_DATA':
      return {
        ...state,
        data: action.data,
      };
    case 'UPDATE_USER':
      return {
        ...state,
        user: action.data,
      };
    case 'UPDATE_MODE':
      return { ...state, mode: action.data };
    case 'UPDATE_LANGUAGE':
      localStorage.setItem('language', action.data);
      return { ...state, language: action.data };
    case 'UPDATE_LOGO':
      return { ...state, logo: `data:image/png;base64, ${action.data.logo}` };
    case 'AUTHENTICATION_EXPIRED':
      return defaultState;
    case 'LOGOUT':
      return defaultState;
    default:
      return state;
  }
}
