import React, { useEffect, useState } from 'react';
import { Request } from '../../config/requests';
import Loading from '../../components/common/Loading';
import { getToken } from '../../config/token';
import FlowManagementTable from './components/FlowManagementTable';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { renameDropdownIdtoId } from '../../config/helpers';

function FlowManagement() {
  const [flows, setFlows] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(null);
  const { t } = useTranslation();

  const history = useNavigate();

  const goToCreate = () => {
    history('/management/create');
  };

  const getFlows = () => {
    setIsLoading(true);

    Request({ method: 'get', route: 'api/flows', token: getToken() })
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        setFlows(renameDropdownIdtoId(json.data));
        console.log(
          'renameDropdownIdtoId(json.data)',
          renameDropdownIdtoId(json.data)
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getFlows();
  }, [currentIndex]);

  return (
    <>
      {isLoading && <Loading noText fast />}

      {!isLoading && flows && (
        <>
          <div className="w-100 my-3 d-flex justify-content-between dark_red_title">
            {t('flows')}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '10px',
              }}
            >
              <button
                className="button_red"
                style={{ margin: '0' }}
                onClick={() => history('/dropdown')}
              >
                {t('Dropdowns')}
              </button>
              <button
                className="button_red"
                style={{ margin: '0' }}
                onClick={goToCreate}
              >
                {t('createFlow')}
              </button>
            </Box>
          </div>

          <FlowManagementTable
            tableData={flows}
            setCurrentIndex={setCurrentIndex}
          />
        </>
      )}
    </>
  );
}

export default FlowManagement;
