import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  setFlowLoading,
  setTranslations,
  updateFlowCompletion,
  updateFlowCurrentStep,
  updateFlowQuestions,
} from '../config/actions';
import { showToast } from '../config/helpers';
import i18n from '../lang/i18n';
import { Request } from '../config/requests';

function useCompleteFlow() {
  const flowQuestions = useSelector((s) => s.flow.flowQuestions);
  const flowCompletion = useSelector((s) => s.flow.flowCompletion);
  const flowType = useSelector((s) => s.flow.flowType);
  const existingTypeDocument = useSelector((s) => s.flow.existingTypeDocument);
  const missedChip = useSelector((s) => s.flow.missedChipNumber);
  const missedDiag = useSelector((s) => s.flow.missedDiagnose);
  const tableDetails = useSelector((s) => s.flow.tableDetails);

  const history = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const compleFlow = async (subClaimID = null) => {
    try {
      const currentLanguage = i18n.language;
      dispatch(setFlowLoading(true));
      if (flowCompletion) {
        const flow_data = flowQuestions.flat().map((i) => ({
          name: i.content.title,
          value: i.content.answer,
          selected: i.content.selected,
        }));
        const res = await Request({
          method: 'post',
          route: `api/claims/update-claim?policy_id=${id}&lang=${currentLanguage}`,
          values: {
            id: searchParams.get('claimId'),
            status: localStorage.getItem('flowStatus'),
            flow_data,
            existing_claim: true,
            approve: true,
            type: flowType,
            missed_chipnumber: missedChip,
            missed_diagnose: missedDiag,
          },
        });
        if (!res.ok) {
          throw new Error();
        }
        showToast(t('claimSuccess'));
        dispatch(setFlowLoading(false));
        dispatch(updateFlowCompletion(false));
        dispatch(updateFlowCurrentStep(0));
        history('/flow-completed');
      } else {
        const manual = flowQuestions.flat().map((i) => ({
          name: i.content.title,
          value: i.content.answer,
          selected: i.content.selected,
        }));
        const res = await Request({
          method: 'post',
          route: `api/claims/update-claim?policy_id=${id}&lang=${currentLanguage}`,
          values: {
            id: searchParams.get('claimId'),
            approve: true,
            manual,
            type: flowType,
            doc_type: existingTypeDocument,
            missed_chipnumber: missedChip,
            missed_diagnose: missedDiag,
            tableDetails: tableDetails,
            subsequentClaimID: subClaimID,
          },
        });
        if (!res.ok) {
          throw new Error();
        }
        const data = await res.json();
        dispatch(setFlowLoading(false));
        if (data.flow && data.flow.data) {
          const content = data?.flow?.data.content.map((i, ind) => {
            const currData = data?.flow?.data.autofill[ind];
            i.group = currData.group;
            if (i.type === 'upload') {
              i.content.ocr = currData.ocr;
              i.content.uniqueId = currData.uniqueId;
            }
            if (i.type === 'dropdown') {
              i.content.selected = currData.selected;
            }
            i.content.isOcr = currData.isOcr;
            i.content.uploadId = currData.uploadId;
            i.content.defaultValue = currData.defaultValue;
            i.uniqueId = currData.uniqueId;
            i.content.groupTitle = currData.groupTitle;
            i.content.width = currData.width;
            return i;
          });
          const status = content[content.length - 1].content.value;
          localStorage.setItem('flowStatus', status);
          const d =
            content.filter(
              (i) =>
                ![
                  'new_claim',
                  'type',
                  'status',
                  'existing_claim',
                  'trigger',
                ].includes(i.type)
            ) || [];
          const groups = d.reduce((acc, i) => {
            return (acc = acc.includes(i.group) ? acc : [...acc, i.group]);
          }, []);
          const values = groups
            .map((i) => {
              return d.filter(
                (j) =>
                  j.group === i &&
                  j.type !== 'existing_claim' &&
                  j.type !== 'trigger'
              );
            })
            .filter((arr) => arr.length > 0);
          dispatch(updateFlowQuestions(values));
          //dispatch below for updating translations when additional flow runs
          dispatch(setTranslations(data.flow.data?.translations));
          dispatch(updateFlowCurrentStep(0));
          dispatch(updateFlowCompletion(true));
        } else {
          dispatch(setFlowLoading(false));
          showToast(t('claimSuccess'));
          dispatch(updateFlowCurrentStep(0));
          history('/flow-completed');
        }
      }
    } catch (err) {
      console.error(err);
      showToast(t('somethingWentWrong'), { error: true });
      history('/');
    } finally {
      dispatch(setFlowLoading(false));
    }
  };

  return {
    compleFlow,
  };
}

export default useCompleteFlow;
