import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { enUS } from 'date-fns/locale';

const Datepicker = ({
  date,
  setDate,
  disabled,
  label,
  type,
  disabledPast,
  disabledFuture,
  placeholder,
  minDate,
  maxDate,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={enUS}>
      <DatePicker
        label={label}
        className={label ? '' : type ? type : 'reset-dp'}
        value={date}
        onChange={setDate}
        format="dd-MM-yyyy"
        views={['year', 'month', 'day']}
        disabled={disabled}
        disablePast={disabledPast}
        disableFuture={disabledFuture}
        placeholder={placeholder}
        minDate={minDate}
        maxDate={maxDate}
      />
    </LocalizationProvider>
  );
};

export default Datepicker;
