import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/common/Footer';
import Header from '../components/common/Header';
import { getToken, setToken } from '../config/token';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateIsAdminViewMode, updateMode } from '../config/actions';
import { USER_MODE } from '../constants/ApplicationConstants';
import { Markup } from 'interweave';
import PandaLogoMain from '../assets/UserInfo/panda-logo-main.svg';
import Dog from '../assets/login/dog.svg';
import Cat from '../assets/login/cat.svg';
import ModalSupport from './claims/modals/ModalSupport';
import { isMobile } from 'react-device-detect';
import Loading from '../components/common/Loading';
import { projectSettings } from '../config/setting';
import { useProjectTheme } from '../hooks/useProjectTheme';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useUserInfo } from '../hooks/useGetUserInfo';

export default function Login() {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const router = useNavigate();
  const dispatch = useDispatch();
  const [adminMode, setAdminMode] = useState(false);
  const [username, setUsername] = useState('');
  const [policyEmail, setPolicyEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [veificationCode, setVeificationCode] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidAdminEmail, setIsValidAdminEmail] = useState(false);
  const [problemEmail, setProblemEmail] = useState(false);
  const [openModalSupport, setOpenModalSupport] = useState(false);
  const [codeLoading, setCodeLoading] = useState(false);
  const { token } = getToken() ? JSON.parse(getToken()) : {};
  const { refetch } = useUserInfo();

  const theme = useProjectTheme();
  const areLoginBgImagesVisible =
    theme === projectSettings.assetsPath && projectSettings.loginBgImages;

  const sendAdminEmail = async () => {
    setCodeLoading(true);
    return await fetch('/api/users/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: username }),
    })
      .then((response) => {
        if (response.status === 200) {
          setProblemEmail(false);
          setEmailSent(true);
        } else {
          setProblemEmail(true);
        }
        setCodeLoading(false);
        return response;
      })
      .then((response) => response.json())
      .then((res) => setVeificationCode(res.message));
  };

  const sendUserEmail = async () => {
    setCodeLoading(true);
    fetch('/api/users/email_login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: policyEmail }),
    })
      .then((response) => {
        if (response.status === 200) {
          setProblemEmail(false);
          setEmailSent(true);
        } else {
          setProblemEmail(true);
        }
        setCodeLoading(false);
        return response;
      })
      .then((response) => response.json())
      .then((res) => setVeificationCode(res.message));
  };

  const codeConfirmAdmin = async () => {
    setCodeLoading(true);
    fetch('/api/users/admin_verification', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code: veificationCode,
        email: username,
      }),
    })
      .then((response) => {
        if (!response.ok)
          throw new Error(`Request failed with status ${response.status}`);
        return response.json();
      })
      .then((data) => {
        setToken(JSON.stringify({ token: data.token }));
        return data;
      })
      .then((data) => {
        refetch(data.token);
        return data;
      })
      .then((data) => {
        if (data.is_admin) {
          dispatch(updateMode(USER_MODE.ADMIN));
          dispatch(updateIsAdminViewMode(true));
          router('/management');
        } else {
          setProblemEmail(true);
        }
        router(0);
      })
      .catch(() => {
        router('/');
      })
      .finally(() => {
        setCodeLoading(false);
      });
  };

  const codeConfirmUser = async () => {
    setCodeLoading(true);
    fetch('/api/users/user_verification', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code: veificationCode,
        email: policyEmail,
      }),
    })
      .then((response) => {
        if (!response.ok)
          throw new Error(`Request failed with status ${response.status}`);
        return response.json();
      })
      .then((data) => {
        setToken(JSON.stringify({ token: data.token }));
        return data;
      })
      .then((data) => {
        refetch(data.token);
      })
      .then(() => {
        dispatch(updateMode(USER_MODE.CLIENT));
        dispatch(updateIsAdminViewMode(false));
        router('/');
        router(0);
      })
      .catch(() => {
        router('/');
      })
      .finally(() => {
        setCodeLoading(false);
      });
  };

  const handleAdminEmail = (e) => {
    const inputValue = e.target.value.trim();
    setUsername(inputValue);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(inputValue);
    setIsValidAdminEmail(isValid);
  };

  const handleUserEmail = (e) => {
    const inputValue = e.target.value.trim();
    setPolicyEmail(inputValue);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(inputValue);
    setIsValidEmail(isValid);
  };

  const handleCode = (inputValue) => {
    setVeificationCode(inputValue);
  };

  const matchIsNumeric = (text) => {
    const isNumber = typeof text === 'number';
    const isString = typeof text === 'string';
    return (isNumber || (isString && text !== '')) && !isNaN(Number(text));
  };

  useEffect(() => {
    if (token) {
      router('/');
    }
  }, [token]);

  return (
    <div className="main-container">
      <Header />
      <div className="login__container">
        <div className="login__header">
          <img
            src={PandaLogoMain}
            className={
              currentLanguage === 'de'
                ? 'main-logo-login de'
                : 'main-logo-login en'
            }
          />
          <span className="headline1_red_title">{t('loginWelcome')}</span>
        </div>
        <div className="login__main_back">
          {areLoginBgImagesVisible && (
            <img src={Dog} className="login__main_back__dog" />
          )}
          {areLoginBgImagesVisible && (
            <img src={Cat} className="login__main_back__cat" />
          )}
          {adminMode && !emailSent && (
            <>
              <div className="login__body">
                <div className="login__body__title">
                  <span className="hl2_white_big text-centered">
                    {t('register')}
                  </span>
                </div>
                <form
                  className="login__body__form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (isValidAdminEmail) {
                      sendAdminEmail();
                    }
                  }}
                >
                  <input
                    name="email"
                    type="email"
                    value={username}
                    onChange={handleAdminEmail}
                    data-cy="login"
                    required
                    autoFocus
                    placeholder={'E-Mail'}
                    className="login__body__input text-centered"
                    style={{ margin: '1vh' }}
                  />
                  {codeLoading ? (
                    <div className="mt-3">
                      <Loading noText fast />
                    </div>
                  ) : (
                    <button
                      type="submit"
                      disabled={!isValidAdminEmail}
                      className={`login__body__button${isMobile ? '_mobile' : ''} button_red_common`}
                      style={{
                        minWidth: '170px',
                        height: '63px',
                      }}
                    >
                      {t('toSend')}
                    </button>
                  )}
                </form>
              </div>
            </>
          )}
          {!adminMode && !emailSent && (
            <div className="login__body">
              <div className="login__body__title">
                <span className="hl2_white_big text-centered">
                  {t('register')}
                </span>
              </div>
              <form
                className="login__body__form"
                onSubmit={(e) => {
                  e.preventDefault();
                  if (isValidEmail) sendUserEmail();
                }}
              >
                <input
                  className="login__body__input text-centered"
                  name="policyEmail"
                  data-cy="policyEmail"
                  type="text"
                  required
                  autoFocus
                  value={policyEmail}
                  onChange={handleUserEmail}
                  placeholder={'E-Mail'}
                />
                {problemEmail && (
                  <div className="login__body__alert">
                    <Markup
                      content={t('errorEmail')}
                      className="white_slim_rooney small text-centered"
                    />
                    <span
                      className="white_slim_rooney small text-centered"
                      style={{
                        cursor: 'pointer',
                        textDecorationLine: 'underline',
                      }}
                      onClick={() => setOpenModalSupport(true)}
                    >
                      {t('support')}
                    </span>
                  </div>
                )}
                {codeLoading ? (
                  <div className="mt-3">
                    <Loading noText fast />
                  </div>
                ) : (
                  <button
                    type="submit"
                    disabled={!isValidEmail}
                    className={`login__body__button${isMobile ? '_mobile' : ''} button_red_common`}
                    style={{
                      minWidth: '170px',
                      height: '63px',
                    }}
                  >
                    {t('Next')}
                  </button>
                )}
              </form>
            </div>
          )}
          {emailSent && (
            <div className="login__body">
              <div className="login__body__title">
                <span className="hl2_white_big text-centered">
                  {t('Login Code')}
                </span>
              </div>
              <div className="login__body__description">
                <span className="white_slim_rooney text-centered">
                  {t('codeSent')}
                </span>
              </div>
              <form
                className="login__body__form"
                onSubmit={(e) => {
                  e.preventDefault();
                  if (veificationCode.length === 4) {
                    if (adminMode) {
                      codeConfirmAdmin();
                    } else {
                      codeConfirmUser();
                    }
                  }
                }}
              >
                <MuiOtpInput
                  length={4}
                  validateChar={matchIsNumeric}
                  className="login-otp"
                  autoFocus
                  value={veificationCode}
                  onChange={handleCode}
                />
                {codeLoading ? (
                  <div className="mt-3">
                    <Loading noText fast />
                  </div>
                ) : (
                  <button
                    type="submit"
                    disabled={veificationCode.length !== 4}
                    className={`login__body__button${isMobile ? '_mobile' : ''} button_red_common`}
                    style={{
                      minWidth: '170px',
                      height: '63px',
                    }}
                  >
                    {t('Login')}
                  </button>
                )}
              </form>
            </div>
          )}
        </div>
      </div>
      <Footer mode={adminMode} modeHandler={() => setAdminMode(!adminMode)} />
      {openModalSupport && (
        <ModalSupport
          openModalSupport={openModalSupport}
          setOpenModalSupport={setOpenModalSupport}
        />
      )}
    </div>
  );
}
