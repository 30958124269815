import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import {
  Box,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useDropdownById from '../../hooks/useDropdownById';
import useFlowById from '../../hooks/useFlowById';
import {
  LANGUAGES,
  LANGUAGE_NAMES,
} from '../../constants/ApplicationConstants';
import { getLangIcon, showToast } from '../../config/helpers';
import { Request } from '../../config/requests';
import { getToken } from '../../config/token';

const headers = Object.entries(LANGUAGES).map(([, val]) => ({
  title: LANGUAGE_NAMES[val],
  icon: getLangIcon(val),
  key: val,
}));
const initTranslation = {};
Object.values(LANGUAGES).forEach((i) => {
  initTranslation[i] = {};
});

export default function FlowTranslations() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [translations, setTranslations] = useState(initTranslation);
  const [initialLanguage, setInitialLanguage] = useState('');
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [getFlow] = useFlowById();
  const [getDropdown] = useDropdownById();
  const flow_id = searchParams.get('flow_id');
  const dropdown_id = searchParams.get('dropdown_id');
  const history = useNavigate();

  const getTranslations = useCallback(async () => {
    if (flow_id) {
      await getFlow(flow_id).then((res) => {
        const data = res?.data?.translations;
        setTranslations({ ...initTranslation, ...data?.translations });
        setInitialLanguage(data.initial_language);
        setData(res.data);
      });
    } else if (dropdown_id) {
      await getDropdown(dropdown_id).then((res) => {
        console.log(res);
        const data = res.data?.translations;
        setTranslations({ ...initTranslation, ...data?.translations });
        setInitialLanguage(data.initial_language);
        setData(res.data);
      });
    }
  }, [dropdown_id, flow_id, getDropdown, getFlow]);

  const isTranslationsExist = useMemo(
    () =>
      Object.entries(translations).some(([, val]) => Object.keys(val).length),
    [translations]
  );
  const translastionKeys = useMemo(
    () => Object.keys(translations[initialLanguage] || {}),
    [translations, initialLanguage]
  );

  const inputHandler = useCallback(
    ({ target }, lang, key) => {
      setTranslations({
        ...translations,
        [lang]: { ...translations[lang], [key]: target.value },
      });
    },
    [translations]
  );

  useEffect(() => {
    getTranslations();
  }, []);

  const onUpdate = useCallback(async () => {
    if (isLoading) return;
    setIsLoading(true);
    const values = {
      data: [
        {
          ...data,
          translations: { initial_language: initialLanguage, translations },
        },
      ],
    };
    try {
      if (dropdown_id) {
        await Request({
          method: 'patch',
          token: getToken(),
          route: `api/dropdowns/${dropdown_id}`,
          values: {
            ...data,
            translations: {
              initial_language: initialLanguage,
              translations,
            },
          },
        });
      } else {
        await Request({
          method: 'put',
          route: `api/flows/${flow_id}`,
          values,
          token: getToken(),
        });
      }
      showToast('Successfully updated');
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, data, initialLanguage, translations, dropdown_id, flow_id]);

  return (
    <div className="main-container">
      <Header />
      <div className="page-content">
        <div className="claims">
          <Box
            sx={{
              maxWidth: '200px',
              marginBottom: '1rem',
            }}
          >
            <button
              onClick={() => history(-1)}
              className="button_red"
              style={{ margin: '0' }}
            >
              {t('back')}
            </button>
          </Box>
          <div className="col-12 mx-auto">
            <TableContainer className="FMTable">
              {/* TABLE */}
              <Table
                className="SlowShow"
                sx={{ minWidth: 400, border: 'none' }}
                stickyHeader
              >
                {/* TABLE HEAD */}
                <TableHead>
                  <TableRow>
                    {headers.map((header, index) => (
                      <TableCell
                        key={`header-${index}`}
                        align={index > 0 ? 'inherit' : 'left'}
                        className="HeaderTableReverse"
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            gap: '1rem',
                            marginLeft: '1rem',
                            alignItems: 'center',
                          }}
                        >
                          <img src={header.icon} alt="" width={30} />
                          <Box sx={{ marginTop: '4px' }}>{t(header.title)}</Box>
                        </Box>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                {/* TABLE BODY */}
                {isTranslationsExist && (
                  <TableBody>
                    {translastionKeys.map((key, index) => (
                      <TableRow
                        key={`row-${index}`}
                        className="RestyledTableRow"
                      >
                        {headers.map((i) => (
                          <TableCell key={i.key}>
                            <Input
                              className="translation-input"
                              value={translations[i.key][key]}
                              name={`${i.key}-${key}`}
                              onInput={(event) =>
                                inputHandler(event, i.key, key)
                              }
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>

              {/* TABLE BODY */}
              {!isTranslationsExist && (
                <div className="NoTableData">{t('empty')}</div>
              )}
            </TableContainer>
          </div>
          <Box
            sx={{
              maxWidth: '200px',
              marginLeft: 'auto',
            }}
          >
            <button
              onClick={onUpdate}
              className="button_red"
              style={{ margin: '0' }}
            >
              {t('update')}
            </button>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
}
