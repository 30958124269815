import {
  Box,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_VALUES } from '../../../constants/ApplicationConstants';
import { getUploads } from './flow-helper';
import { useTranslation } from 'react-i18next';

export default function FlowDefaultValueModal({
  open,
  handleClose,
  step,
  onSelectValue,
}) {
  const { t } = useTranslation();
  const flowSteps = useSelector((s) => s.flow.flowSteps);
  const [uploads, setUploads] = useState([]);

  useEffect(() => {
    const ups = getUploads(step, flowSteps);
    setUploads(ups.filter((i) => i.code === 'upload' && i.ocr));
  }, [flowSteps, step]);

  return (
    <Dialog onClose={() => handleClose(false)} open={open}>
      <DialogTitle>{t('selectDefaultValue')}</DialogTitle>
      <List sx={{ pt: 0 }}>
        {DEFAULT_VALUES.map((i, ind) => (
          <ListItem key={ind}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                gap: '15px',
              }}
            >
              <div>{t(i.name)}</div>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {uploads.map((u, ind) => (
                  <Tooltip
                    key={ind}
                    title={
                      u.text.includes('#')
                        ? `${t(u.text.split('#')[0].trim())} #${u.text.split('#')[1]}`
                        : t(u.text)
                    }
                    placement="top"
                  >
                    <button
                      className="btn-reset"
                      onClick={() => onSelectValue(i, u)}
                    >
                      <span
                        className="upload-color"
                        style={{ backgroundColor: u.color }}
                      ></span>
                    </button>
                  </Tooltip>
                ))}
              </Box>
            </Box>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}
