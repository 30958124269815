import React, { useEffect, useState } from 'react';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import { useTranslation } from 'react-i18next';
import PandaMain from '../assets/logos_main/PandaMain.png';
import PawUI from '../components/ui/PawUI';
import { useDispatch, useSelector } from 'react-redux';
import ModalEditPD from '../features/claims/modals/ModalEditPD';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { getToken } from '../config/token';
import { Request } from '../config/requests';
import { formatAnyDate, showToast } from '../config/helpers';
import { updateUser } from '../config/actions';
import PandaLoading from '../components/common/PandaLoading';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import moment from 'moment';
import CustomSelect from '../components/inputs/select/CustomSelect';

export default function Account() {
  const token = getToken();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initialUserInfo = useSelector((s) => s.form.user);
  const [openModalEditPD, setOpenModalEditPD] = useState(false);

  const [userInfo, setUserInfo] = useState(initialUserInfo || {});
  const [isChangeRequestBtnDisabled, setIsChangeRequestBtnDisabled] =
    useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState(
    userInfo.title ? { label: userInfo.title, value: userInfo.title } : null
  );

  const titleList = [
    { label: t('Mag.'), value: 'Mag.' },
    { label: t('Dr.'), value: 'Dr.' },
    { label: t('Prof.'), value: 'Prof.' },
    { label: t('Prof. Dr.'), value: 'Prof. Dr.' },
  ];
  useEffect(() => {
    setUserInfo(initialUserInfo);
  }, [initialUserInfo]);

  const changeUserInfo = (value, key) => {
    setUserInfo((prev) => {
      const updateProperty = (obj, keys, value) => {
        const [currentKey, ...remainingKeys] = keys;
        if (remainingKeys.length === 0) {
          return {
            ...obj,
            [currentKey]: value,
          };
        }

        return {
          ...obj,
          [currentKey]: updateProperty(obj[currentKey], remainingKeys, value),
        };
      };

      return updateProperty(prev, key.split('.'), value);
    });
  };

  useEffect(() => {
    let stringifiedInitialUserInfo = JSON.stringify(initialUserInfo);
    let stringifiedUserInfo = JSON.stringify(userInfo);
    if (stringifiedInitialUserInfo === stringifiedUserInfo) {
      setIsChangeRequestBtnDisabled(true);
    } else {
      setIsChangeRequestBtnDisabled(false);
    }
  }, [userInfo]);

  const sendChangeRequest = () => {
    setIsChangeRequestBtnDisabled(true);
    setIsLoading(true);
    Request({
      method: 'post',
      token: token,
      route: 'api/users/change_data',
      values: {
        title: userInfo.title,
        gender: userInfo.gender,
        firstName: userInfo.firstName,
        name: userInfo.name,
        phoneNumber: userInfo.phone,
        city: userInfo.address.city,
        street: userInfo.address.street,
        houseNumber: userInfo.address.houseNumber,
        zip: userInfo.address.zip,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        dispatch(
          updateUser({
            ...initialUserInfo,
            title: json.data.title,
            gender: json.data.gender,
            firstName: json.data.firstName,
            name: json.data.name,
            phone: json.data.phone,
            email: json.data.email,
            birthDate: json.data.birthDate,
            address: {
              ...initialUserInfo.address,
              city: json.data.address.city,
              street: json.data.address.street,
              houseNumber: json.data.address.houseNumber,
              zip: json.data.address.zip,
            },
          })
        );
      })
      .catch(() => {
        showToast(t('somethingWentWrong'), { error: true });
      })
      .finally(() => {
        setIsChangeRequestBtnDisabled(false);
        setIsLoading(false);
      });
  };

  const submitPD = async (type, value, selectedFiles) => {
    setIsLoading(true);
    const fd = new FormData();
    fd.append('reportType', 'Change user data');
    fd.append('customerEmail', initialUserInfo?.email);
    if (selectedFiles) selectedFiles.forEach((i) => fd.append('files', i));
    if (type === 'birthDay')
      fd.append('userBD', moment(value).format('DD.MM.YYYY'));
    if (type === 'email') fd.append('newEmail', value);

    return Request({
      method: 'post',
      route: 'api/users/change_data_request',
      formData: fd,
    })
      .then((res) => {
        if (!res.ok)
          throw new Error(`Request failed with status ${res.status}`);
      })
      .catch(() => {
        setOpenModalEditPD(false);
        showToast(t('somethingWentWrong'), { error: true });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className="main-container SlowShow">
      {isLoading && <PandaLoading loadingMsg={'loadingFlowText'} />}
      <Header />
      <div className="account-content-container relative">
        <div className="first-last-name relative">
          <div className={`welcome_text ${!isMobile && 'margin-0'}`}>
            {userInfo.title ? `${userInfo.title} ` : ''}
            {userInfo.firstName} {userInfo.name}
          </div>
          {isMobile ? (
            <PawUI
              top="1vh"
              right="17vw"
              rotation="right"
              width="22vw"
              zIndex="6"
            />
          ) : (
            <PawUI
              top="-8vh"
              left="7vw"
              rotation="right"
              width="130px"
              zIndex="0"
            />
          )}
        </div>
        <div className="account page-content container-user-info relative">
          <div className="container relative">
            <div className="container-form">
              <img
                src={PandaMain}
                alt="Panda_Main_Logo"
                className="main-logo-account"
              />
              <div className="form-wrapper">
                <div className="form-body">
                  <div className="form-body__group">
                    <div className="form-body__group-title">
                      {t('personalData')}
                    </div>
                    <div className="form-body__item">
                      <span>{t('gender')}:</span>
                      <div className="form-body__group-button">
                        {['Male', 'Female', 'Miscellaneous'].map((el, ind) => (
                          <button
                            key={`btn-gender-${ind}`}
                            className="form-body__item-button"
                            style={{ opacity: userInfo.gender == el ? 1 : 0.5 }}
                            onClick={() => changeUserInfo(el, 'gender')}
                          >
                            {t(el === 'Miscellaneous' ? 'diverse' : el)}
                          </button>
                        ))}
                      </div>
                    </div>
                    <div className="form-body__item">
                      <span>{t('title')}:</span>

                      {/* input temporary hidden till approved select */}

                      {/* <input
                        className="form-body__item-input"
                        value={userInfo.title}
                        onChange={(e) =>
                          changeUserInfo(e.target.value, "title")
                        }
                      /> */}

                      <CustomSelect
                        variant="customer-title"
                        options={titleList}
                        formatCreateLabel={(i) => i}
                        placeholder={''}
                        value={selectedTitle}
                        onChange={(e) => {
                          changeUserInfo(e ? e.value : '', 'title');
                          setSelectedTitle(e);
                        }}
                        isClearable={true}
                        unstyled={true}
                        className="customer-title"
                        classNamePrefix="cust-title"
                      />
                    </div>
                    <div className="form-body__item">
                      <span>{t('firstName')}:</span>
                      <input
                        className="form-body__item-input"
                        value={userInfo.firstName}
                        onChange={(e) =>
                          changeUserInfo(e.target.value, 'firstName')
                        }
                      />
                    </div>
                    <div className="form-body__item">
                      <span>{t('lastName')}:</span>
                      <input
                        className="form-body__item-input"
                        value={userInfo.name}
                        onChange={(e) => changeUserInfo(e.target.value, 'name')}
                      />
                    </div>
                    <div className="form-body__item">
                      <span>{t('birthDate')}:</span>
                      <span className="form-body__item__readonly">
                        {formatAnyDate(userInfo.birthDate)}
                      </span>
                    </div>
                  </div>
                  <div className="form-body__group">
                    <div className="form-body__group-title">{t('address')}</div>
                    <div className="form-body__item">
                      <span>{t('street')}:</span>
                      <input
                        className="form-body__item-input"
                        value={userInfo?.address.street}
                        onChange={(e) =>
                          changeUserInfo(e.target.value, 'address.street')
                        }
                      />
                    </div>
                    <div className="form-body__item">
                      <span>{t('houseNumber')}:</span>
                      <input
                        className="form-body__item-input"
                        value={userInfo?.address.houseNumber}
                        onChange={(e) =>
                          changeUserInfo(e.target.value, 'address.houseNumber')
                        }
                      />
                    </div>
                    <div className="form-body__item">
                      <span>{t('postcode')}:</span>
                      <input
                        className="form-body__item-input"
                        value={userInfo?.address.zip}
                        onChange={(e) =>
                          changeUserInfo(e.target.value, 'address.zip')
                        }
                        type="number"
                      />
                    </div>
                    <div className="form-body__item">
                      <span>{t('city')}:</span>
                      <input
                        className="form-body__item-input"
                        value={userInfo?.address.city}
                        onChange={(e) =>
                          changeUserInfo(e.target.value, 'address.city')
                        }
                      />
                    </div>
                  </div>
                  <div className="form-body__group">
                    <div className="form-body__group-title">
                      {t('contactDetails')}
                    </div>
                    <div className="form-body__item">
                      <span>{t('email')}:</span>
                      <span className="form-body__item__readonly">
                        {userInfo.email}
                      </span>
                    </div>
                    <div className="form-body__item">
                      <span>{t('phone')}:</span>
                      <PhoneInput
                        className="custom-phone-codes"
                        country={'de'}
                        placeholder={t('phone')}
                        value={userInfo.phone}
                        onChange={(e) => {
                          if (e && !e.startsWith('+')) e = '+' + e;
                          changeUserInfo(e, 'phone');
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-body__buttons">
                  <div
                    className={
                      isMobileOnly ? 'button_account_mobile' : 'button_account'
                    }
                    onClick={() => setOpenModalEditPD(true)}
                  >
                    {t('askForEdit')}
                  </div>
                  <button
                    className={
                      isMobileOnly ? 'button_account_mobile' : 'button_account'
                    }
                    disabled={isChangeRequestBtnDisabled}
                    onClick={() => sendChangeRequest()}
                  >
                    {t('sendChangeRequest')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {!isMobile && (
            <>
              <PawUI
                top="10vh"
                right="8vw"
                rotation="right"
                width="200px"
                zIndex="-1"
              />
              <PawUI
                bottom="20vh"
                left="0vw"
                rotation="left"
                width="350px"
                zIndex="-1"
              />
            </>
          )}
        </div>
        {isMobile && (
          <PawUI
            bottom="-15vh"
            left="-5vw"
            rotation="right"
            width="42vw"
            zIndex="5"
          />
        )}
      </div>
      <Footer />
      {openModalEditPD && (
        <ModalEditPD
          openModalEditPD={openModalEditPD}
          setOpenModalEditPD={setOpenModalEditPD}
          submitPD={submitPD}
        />
      )}
    </div>
  );
}
