import React from 'react';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import FlowManagement from './flowManagement/FlowManagement';
import useLocalStorage from 'use-local-storage';
import { isMobile } from 'react-device-detect';
import PawUI from '../components/ui/PawUI';

export default function Management() {
  const [themeSetting] = useLocalStorage('theme', 'panda');
  return (
    <div className="main-container">
      <Header />
      <div className="page-content">
        <div className="claims">
          {isMobile ? (
            <>
              <PawUI
                top="1vh"
                right="6vw"
                rotation="right"
                width="25vw"
                zIndex="0"
              />
              <PawUI
                top="53vh"
                left="-20vw"
                rotation="right"
                width="60vw"
                zIndex="0"
              />
              <PawUI
                bottom="-23vh"
                right="-27vw"
                rotation="left"
                width="70vw"
                zIndex="0"
              />
            </>
          ) : (
            <>
              <PawUI
                top="20vh"
                right="1vw"
                rotation="right"
                width="350px"
                zIndex="0"
              />
              <PawUI
                bottom="-10vh"
                left="5vw"
                rotation="left"
                width="150px"
                zIndex="0"
              />
              <PawUI
                top="10vh"
                left="20vw"
                rotation="left"
                width="250px"
                zIndex="0"
              />
            </>
          )}

          <FlowManagement themeSetting={themeSetting} />
        </div>
      </div>
      <Footer />
    </div>
  );
}
