export const CHANGE_CONTRACT_FLOW = {
  scenarios: {
    changeDeductible: [
      {
        type: 'list_coverage',
        isExistingCoverage: true,
        title: 'Please make a selection for reimbursement in your new tariff',
        buttons: [
          { value: 0.7, label: '70%' },
          { value: 0.8, label: '80%' },
          { value: 1, label: '100%' },
        ],
        message1: 'yourNewPremiumIs',
      },
      {
        type: 'datepicker',
        title: 'whenShouldChange',
        date: null,
      },
      {
        type: 'date_confirm',
        title: 'changeEffectiveFrom',
        message1: 'pleaseConfirmRequest',
        runSubmit: true,
      },
      {
        type: 'thank_you',
        title: '',
        message1: 'thankYouMessage_changeDeductible',
      },
    ],

    changeTariff: [
      {
        type: 'radio',
        title: 'pleaseAnswerTruthfully',
        options: [
          { value: 'no_illness', label: 'option_noClaims' },
          { value: 'no_illness_6month', label: 'option_noClaimsSixMonths' },
          { value: 'had_illness', label: 'option_wasClaimed' },
        ],
      },
      {
        type: 'list_available_tariffs',
        title: 'pleaseSelectTariff',
        buttons: [
          { value: 'OP Basic', label: 'OP Basic' },
          { value: 'OP Comfort', label: 'OP Comfort' },
          { value: 'OP Full', label: 'OP Full' },
          { value: 'Health Basic', label: 'Health Basic' },
          { value: 'Health Comfort', label: 'Health Comfort' },
          { value: 'Health Full', label: 'Health Full' },
        ],
        message1: 'yourNewPremiumIs',
      },
    ],

    changeTariff_noIllness_withCoverage: [
      {
        type: 'list_coverage',
        isExistingCoverage: false,
        title: 'Please make a selection for reimbursement in your new tariff',
        buttons: [
          { value: 0.7, label: '70%' },
          { value: 0.8, label: '80%' },
          { value: 1, label: '100%' },
        ],
        message1: 'yourNewPremiumIs',
      },
      {
        type: 'datepicker',
        title: 'whenShouldChange',
        date: null,
      },
      {
        type: 'date_confirm',
        title: 'changeEffectiveFrom',
        message1: 'pleaseConfirmRequest',
        runSubmit: true,
      },
      {
        type: 'thank_you',
        title: '',
        message1: 'thankYouMessage_changeDeductible',
      },
    ],

    changeTariff_noIllness_withoutCoverage: [
      {
        type: 'datepicker',
        title: 'whenShouldChange',
        date: null,
      },
      {
        type: 'date_confirm',
        title: 'changeEffectiveFrom',
        message1: 'pleaseConfirmRequest',
        runSubmit: true,
      },
      {
        type: 'thank_you',
        title: '',
        message1: 'thankYouMessage_changeDeductible',
      },
    ],

    changeTariff_hadIllness_withCoverage: [
      {
        type: 'list_coverage',
        isExistingCoverage: false,
        title: 'Please make a selection for reimbursement in your new tariff',
        buttons: [
          { value: 0.7, label: '70%' },
          { value: 0.8, label: '80%' },
          { value: 1, label: '100%' },
        ],
        message1: 'yourNewPremiumIs',
      },
      {
        type: 'date_confirm',
        title: 'changeEffectiveFrom',
        message1: 'pleaseConfirmRequest',
        runSubmit: true,
      },
      {
        type: 'thank_you',
        title: '',
        message1: 'thankYouMessage_changeDeductible',
      },
    ],

    changeTariff_hadIllness_withoutCoverage: [
      {
        type: 'date_confirm',
        title: 'changeEffectiveFrom',
        message1: 'pleaseConfirmRequest',
        runSubmit: true,
      },
      {
        type: 'thank_you',
        title: '',
        message1: 'thankYouMessage_changeDeductible',
      },
    ],

    otherEnquiry: [
      {
        type: 'input_attach',
        title: '',
        placeholder_input: 'placeholder_1',
        placeholder_attach: 'attach',
        sendUserRequest: true,
      },
      {
        type: 'thank_you',
        title: '',
        message1: 'thankYouMessage_otherEnquiry',
      },
    ],
  },
};
