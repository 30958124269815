import React from 'react'

function ThanksNotice({handleClose}) {
  return (
    <div className='d-flex flex-column align-items-center gap-5'>
    <div className='d-flex w-100 h-100 align-items-center justify-content-center'>
        <p>Gut gemacht! Datensatz erfolgreich geändert!
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_5869_30181)">
<path d="M30 15C30 23.2863 23.2947 30 15.0078 30C6.72089 30 0.0263672 23.2863 0.0263672 15C0.0263672 6.71367 6.73172 0 15.0078 0C23.2839 0 30 6.71367 30 15Z" fill="#FFF2E0"/>
<path d="M29.4473 15H28.8841C28.8841 16.9207 28.4982 18.7474 27.7995 20.4071C26.7461 22.9019 24.9836 25.0209 22.7726 26.5136C20.5616 28.0063 17.8917 28.8831 15.0236 28.8831C13.1046 28.8831 11.2795 28.4969 9.62128 27.7871C7.13912 26.7328 5.01155 24.9687 3.52017 22.7557C2.02878 20.5428 1.15273 17.8706 1.15273 14.9896C1.15273 13.0689 1.53861 11.2526 2.23737 9.58246C3.29072 7.08768 5.05327 4.96868 7.26427 3.47599C9.47527 1.9833 12.1452 1.10647 15.0132 1.10647C16.9322 1.10647 18.7573 1.49269 20.4156 2.20251C22.9081 3.25678 25.0253 5.02088 26.5167 7.23382C28.008 9.44676 28.8841 12.119 28.8841 15H30C30 12.9332 29.5829 10.9499 28.8215 9.16493C27.6847 6.47182 25.7866 4.1858 23.3879 2.56785C20.9996 0.949896 18.1211 0 15.0132 0C12.9482 0 10.9667 0.417537 9.18325 1.17954C6.4925 2.31733 4.2085 4.21712 2.59196 6.61795C0.965 9.00835 0.0263672 11.8998 0.0263672 15C0.0263672 17.0668 0.443537 19.0501 1.20487 20.8351C2.34166 23.5282 4.23978 25.8142 6.63851 27.4322C9.02681 29.0501 11.9157 30 15.0236 30C17.0886 30 19.0597 29.5825 20.8536 28.8205C23.5443 27.6827 25.8283 25.7829 27.4449 23.382C29.0614 20.9916 30.0105 18.1002 30.0105 14.9896H29.4473V15Z" fill="#302E2E"/>
<path d="M6.29439 13.7473C6.29439 13.3193 6.37782 12.9226 6.53426 12.5573C6.7637 12.004 7.14959 11.5448 7.63976 11.2107C8.12994 10.8767 8.71397 10.6888 9.35016 10.6888C9.77776 10.6888 10.1741 10.7723 10.5391 10.9289C11.0918 11.1585 11.5507 11.5448 11.8845 12.0354C12.2182 12.526 12.4059 13.1105 12.4059 13.7473C12.4059 13.9247 12.5415 14.0604 12.7188 14.0604C12.8961 14.0604 13.0317 13.9247 13.0317 13.7473C13.0317 13.2358 12.9274 12.7556 12.7397 12.3068C12.4581 11.6387 11.9888 11.0855 11.4047 10.6888C10.8207 10.2921 10.1115 10.0625 9.33973 10.0625C8.8287 10.0625 8.34895 10.1669 7.90049 10.3548C7.23302 10.6366 6.68027 11.1063 6.28396 11.6909C5.88765 12.2754 5.6582 12.9957 5.6582 13.7577C5.6582 13.9352 5.79378 14.0708 5.97108 14.0708C6.14838 14.0708 6.28396 13.9352 6.28396 13.7577" fill="#302E2E"/>
<path d="M17.6108 13.7473C17.6108 13.3193 17.6942 12.9226 17.8507 12.5573C18.0801 12.004 18.466 11.5448 18.9562 11.2107C19.4463 10.8767 20.0304 10.6888 20.6666 10.6888C21.0942 10.6888 21.4905 10.7723 21.8555 10.9289C22.4083 11.1585 22.8671 11.5448 23.2009 12.0354C23.5346 12.526 23.7223 13.1105 23.7223 13.7473C23.7223 13.9247 23.8579 14.0604 24.0352 14.0604C24.2125 14.0604 24.3481 13.9247 24.3481 13.7473C24.3481 13.2358 24.2438 12.7556 24.0561 12.3068C23.7745 11.6387 23.3052 11.0855 22.7211 10.6888C22.1371 10.2921 21.4279 10.0625 20.6561 10.0625C20.1451 10.0625 19.6654 10.1669 19.2169 10.3548C18.5494 10.6366 17.9967 11.1063 17.6004 11.6909C17.2041 12.2754 16.9746 12.9957 16.9746 13.7577C16.9746 13.9352 17.1102 14.0708 17.2875 14.0708C17.4648 14.0708 17.6004 13.9352 17.6004 13.7577" fill="#302E2E"/>
<g clip-path="url(#clip1_5869_30181)">
<path d="M7.0981 19.9376C7.88976 21.4492 9.02518 22.7184 10.3793 23.6069C11.7439 24.4953 13.3273 25.003 15.0252 25.003C16.7231 25.003 18.3064 24.4953 19.671 23.6069C21.0356 22.7184 22.1606 21.4492 22.9523 19.9376C23.1085 19.6376 23.0148 19.2684 22.7439 19.0953C22.4731 18.9222 22.1293 19.0261 21.9835 19.3261C21.2856 20.653 20.296 21.7722 19.0981 22.5453C17.9002 23.3184 16.5148 23.7684 15.0252 23.7684C13.5356 23.7684 12.1502 23.3184 10.9523 22.5453C9.76476 21.7607 8.76476 20.6415 8.06685 19.3145C7.9106 19.0145 7.56685 18.9107 7.30643 19.0838C7.0356 19.2569 6.94185 19.6376 7.0981 19.9376Z" fill="#302E2E"/>
</g>
<g filter="url(#filter0_f_5869_30181)">
<circle cx="6.52637" cy="17.5" r="2.5" fill="#EB5955" fill-opacity="0.4"/>
</g>
<g filter="url(#filter1_f_5869_30181)">
<circle cx="23.5264" cy="17.5" r="2.5" fill="#EB5955" fill-opacity="0.4"/>
</g>
</g>
<defs>
<filter id="filter0_f_5869_30181" x="2.02637" y="13" width="9" height="9" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_5869_30181"/>
</filter>
<filter id="filter1_f_5869_30181" x="19.0264" y="13" width="9" height="9" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_5869_30181"/>
</filter>
<clipPath id="clip0_5869_30181">
<rect width="29.9737" height="30" fill="white" transform="translate(0.0263672)"/>
</clipPath>
<clipPath id="clip1_5869_30181">
<rect width="16" height="6" fill="white" transform="translate(7.02637 19)"/>
</clipPath>
</defs>
</svg>

</p>
    </div>
          <button
            onClick={() => {
              handleClose();
            }}
            className={`button_red_small__admin`}
            style={{ marginBottom: '10px' }}
          >
            OK
          </button>
    </div>
  )
}

export default ThanksNotice