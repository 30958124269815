import React from 'react';
import PandaAnimated from '../../assets/loader/panda_animation.gif';
import Backdrop from '@mui/material/Backdrop';
import { useTranslation } from 'react-i18next';

function PandaLoading({ loadingMsg = 'pandaLoading' }) {
  const { t } = useTranslation();

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <div className="loading_back_red">
        <img src={PandaAnimated} />
        <div className="hl2_white_big">{t(loadingMsg)}</div>
      </div>
    </Backdrop>
  );
}

export default PandaLoading;
