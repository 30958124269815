import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import { useTableScroll } from '../../../hooks/useTableScroll';
import {
  formatAnyDate,
  formatNumber,
  paymentHeaders,
  refundHeaders,
} from '../../../config/helpers';
import NotChecked from '../../../assets/iconsSmall/check_not_selected.png';
import Checked from '../../../assets/iconsSmall/check_selected_mobile.png';
import ChainRed from '../../../assets/iconsSmall/chain_btn_red.svg';
import ChainBiege from '../../../assets/iconsSmall/chain_btn_biege.svg';
import Exclamation from '../../../assets/iconsSmall/exclamation_mark_dark.svg';
import { isMobileOnly } from 'react-device-detect';

function PaymentTable({ tableDetails, setTableDetails, paymentsMode = true }) {
  const { t } = useTranslation();
  const { bodyRef, headerRef, syncScroll, listOfItemsRef } = useTableScroll();

  const [allSelected, setAllSelected] = useState(false);

  const rowClickHandler = (index) => {
    setTableDetails((prevState) => {
      const updatedData = [...prevState];
      updatedData[index] = {
        ...updatedData[index],
        selected: !updatedData[index].selected,
      };
      return updatedData;
    });
  };

  const selectAllHandler = () => {
    setTableDetails((prevState) => {
      const updatedData = prevState.map((obj) => {
        return { ...obj, selected: allSelected ? false : true };
      });
      return updatedData;
    });
    setAllSelected(!allSelected);
  };

  useEffect(() => {
    const allSelected = tableDetails.every((obj) => obj.selected === true);
    if (allSelected) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [tableDetails]);

  return (
    <>
      {tableDetails && tableDetails.length > 0 ? (
        <div className="d-flex flex-column relative" style={{ width: '100%' }}>
          <TableContainer
            className="reset-table-spacing table-reset-header"
            sx={{ margin: '0 0 -3px 0' }}
            ref={headerRef}
          >
            <Table
              className={`SlowShow reset-table-new  ${paymentsMode ? 'table-payment' : 'table-refund'}`}
              sx={{ minWidth: 600 }}
            >
              <TableHead>
                <TableRow className="reset-table-new__header edit-rows">
                  <TableCell key={`header-empty`} className="cell-transparent">
                    <div style={{ alignItems: 'flex-start' }}>
                      <img
                        src={allSelected ? Checked : NotChecked}
                        alt="item-check-box"
                        onClick={() => selectAllHandler()}
                      />
                    </div>
                  </TableCell>
                  {(paymentsMode ? paymentHeaders : refundHeaders).map(
                    (header, index) => (
                      <TableCell
                        key={`header-${index}`}
                        align={index > 0 ? 'inherit' : 'left'}
                      >
                        <div>
                          <span>{t(header.title)}</span>
                        </div>
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>

          <TableContainer
            className="reset-table-spacing"
            sx={{ maxHeight: 314 }}
            ref={bodyRef}
            onScroll={syncScroll}
          >
            <Table
              className={`SlowShow reset-table-new ${paymentsMode ? 'table-payment' : 'table-refund'}`}
              sx={{ minWidth: 600 }}
            >
              <TableBody ref={listOfItemsRef}>
                {tableDetails.map((row, index) => (
                  <>
                    {paymentsMode ? (
                      <TableRow
                        key={`row-${index}`}
                        className={`reset-table-new__body ${row.selected ? 'edit-unselected' : ''} edit-rows`}
                        onClick={() => rowClickHandler(index)}
                      >
                        <TableCell key={0} className="cell-transparent">
                          <div
                            style={{
                              alignItems: 'flex-start',
                              padding: '5px',
                            }}
                          >
                            <img
                              src={row.selected ? Checked : NotChecked}
                              alt="item-check-box"
                            />
                          </div>
                        </TableCell>
                        <TableCell key={1}>
                          <div>
                            <span>
                              {formatAnyDate(row['Date']) || t('noInfo')}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell key={2}>
                          <div>
                            <span>{row['Policy No'] || t('noInfo')}</span>
                          </div>
                        </TableCell>
                        <TableCell key={3}>
                          <div>
                            <span>{row['Claim No'] || t('noInfo')}</span>
                          </div>
                        </TableCell>
                        <TableCell key={4}>
                          <div>
                            <span>{row['Customer'] || t('noInfo')}</span>
                          </div>
                        </TableCell>
                        <TableCell key={5}>
                          <div
                            className={!row?.selected ? 'CellAmountRed' : ''}
                          >
                            <span>
                              {formatNumber(row?.['Decision Amount']) + ' €' ||
                                t('noInfo')}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell sx={{ padding: '0 5px' }}>
                          <div className="d-flex flex-row justify-content-center">
                            <img
                              src={row.selected ? ChainBiege : ChainRed}
                              alt="Link to Insly"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (row['Link to Insly'])
                                  window.open(row['Link to Insly'], '_blank');
                              }}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow
                        key={`row-${index}`}
                        className={`reset-table-new__body ${row.selected ? 'edit-unselected' : ''} edit-rows`}
                        onClick={() => rowClickHandler(index)}
                      >
                        <TableCell key={0} className="cell-transparent">
                          <div
                            style={{
                              alignItems: 'flex-start',
                              padding: '5px',
                            }}
                          >
                            <img
                              src={row.selected ? Checked : NotChecked}
                              alt="item-check-box"
                            />
                          </div>
                        </TableCell>
                        <TableCell key={1}>
                          <div>
                            <span>
                              {row?.policyNo || row?.policyID || t('noInfo')}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell key={2}>
                          <div>
                            <span>{row?.iban || t('noInfo')}</span>
                          </div>
                        </TableCell>
                        <TableCell key={3}>
                          <div>
                            <span>{row?.amount || t('noInfo')}</span>
                          </div>
                        </TableCell>
                        <TableCell key={4}>
                          <div>
                            <span>{row?.event || t('noInfo')}</span>
                          </div>
                        </TableCell>
                        <TableCell key={5}>
                          <div>
                            <span>{row?.hasOverdue || t('no')}</span>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <div
          className="d-flex flex-row justify-content-center align-items-center"
          style={{ margin: '30px 0' }}
        >
          <div
            className="modal_container__body__info"
            style={{ width: isMobileOnly ? 'unset' : '800px' }}
          >
            <span>{t('noPoliciesFound')}</span>
            <img src={Exclamation} />
          </div>
        </div>
      )}
    </>
  );
}

export default PaymentTable;
