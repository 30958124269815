// action creator
export function updateToken(token) {
  return {
    type: 'UPDATE_TOKEN',
    token,
  };
}

export function updateData(data) {
  return {
    type: 'UPDATE_DATA',
    data,
  };
}

export function updateUser(data) {
  return {
    type: 'UPDATE_USER',
    data,
  };
}

export function updateLanguage(data) {
  return {
    type: 'UPDATE_LANGUAGE',
    data,
  };
}

export function updateFlowSteps(data) {
  return {
    type: 'UPDATE_FLOW_STEPS',
    data,
  };
}

export function updateDropdowns(data) {
  return {
    type: 'UPDATE_DROPDOWNS',
    data,
  };
}

export function updateUploadValues(data) {
  return {
    type: 'UPDATE_UPLOAD_VALUES',
    data,
  };
}

export function updateFlowGroup(data) {
  return {
    type: 'UPDATE_FLOW_GROUP',
    data,
  };
}

export function updateSingleClaim(data) {
  return {
    type: 'UPDATE_SINGLE_CLAIM',
    data,
  };
}

export function updateFlowCurrentStep(data) {
  return {
    type: 'UPDATE_FLOW_CURRENT_STEP',
    data,
  };
}

export function updateFlowQuestions(data) {
  return {
    type: 'UPDATE_FLOW_QUESTIONS',
    data,
  };
}

export function updateFlowCompletion(data) {
  return {
    type: 'UPDATE_FLOW_COMPLETION',
    data,
  };
}

export function setFlowLoading(data) {
  return {
    type: 'SET_FLOW_LOADING',
    data,
  };
}

export function setShowConfirmModal(data) {
  return {
    type: 'SET_MODAL_SHOW',
    data,
  };
}

export function updateSameTitle(data) {
  return {
    type: 'UPDATE_SAME_TITLE',
    data,
  };
}

export function updateArrows(data) {
  return {
    type: 'UPDATE_ARROWS',
    data,
  };
}

export function clearArrows(data) {
  return {
    type: 'CLEAR_ARROWS',
    data,
  };
}

export function updateMode(data) {
  return {
    type: 'UPDATE_MODE',
    data,
  };
}

export function updateOcrPopup(data) {
  return {
    type: 'UPDATE_OCR_POPUP',
    data,
  };
}

export function updateLogo(data) {
  return {
    type: 'UPDATE_LOGO',
    data,
  };
}

export function updateFillLater(data) {
  return {
    type: 'UPDATE_FILL_LATER',
    data,
  };
}

export function updateSelectedPetInfo(data) {
  return {
    type: 'UPDATE_SELECTED_PET_INFO',
    data,
  };
}

export function uploadImages(data) {
  return {
    type: 'UPLOAD_IMAGES',
    data,
  };
}

export function setTranslations(data) {
  return {
    type: 'SET_TRANSLATIONS',
    data,
  };
}

export function setPets(data) {
  return {
    type: 'SET_PETS',
    data,
  };
}

export function setFlowType(data) {
  return {
    type: 'SET_FLOW_TYPE',
    data,
  };
}

export function setSelectedFlowTypeToSkip(data) {
  return {
    type: 'SET_SELECTED_FLOW_TYPE_TO_SKIP',
    data,
  };
}

export function updateIsLastStep(data) {
  return {
    type: 'UPDATE_IS_LAST_STEP',
    data,
  };
}

export function updateDocCounter(data) {
  return {
    type: 'UPDATE_DOC_COUNTER',
    data,
  };
}

export function updatePolicyReactivateModalSelect(data) {
  return {
    type: 'IS_POLICY_REACTIVATE',
    data,
  };
}

export function updateIsUserModalSelect(data) {
  return {
    type: 'IS_USER_SELECT_OPEN',
    data,
  };
}

export function updateIsAdminLoggedAsUser(data) {
  return {
    type: 'IS_ADMIN_LOGGED_AS_USER',
    data,
  };
}

export function updateIsAdminViewMode(data) {
  return {
    type: 'IS_ADMIN_VIEW_MODE',
    data,
  };
}

export function updateMissedChip(data) {
  return {
    type: 'UPDATE_MISSED_CHIP',
    data,
  };
}

export function updateMissedDiag(data) {
  return {
    type: 'UPDATE_MISSED_DIAG',
    data,
  };
}

export function updateWtdModalOpen(data) {
  return {
    type: 'UPDATE_WTD_MODAL_OPEN',
    data,
  };
}

export function updateTableDetails(data) {
  return {
    type: 'UPDATE_TABLE_DETAILS',
    data,
  };
}

export function updateExistingType(data) {
  return {
    type: 'UPDATE_EXISTING_TYPE_DOC',
    data,
  };
}

export function updateClaimIDList(data) {
  return {
    type: 'UPDATE_CLAIM_ID_LIST',
    data,
  };
}
