import React from 'react';
import PropTypes from 'prop-types';

const TooltipContent = ({ text, scrollable = false }) => {
  const items = text.split('\n').filter((item) => item.trim() !== '');

  return (
    <>
      {scrollable ? (
        <div
          style={{
            maxHeight: '70vh',
            overflowY: 'auto',
          }}
        >
          <ul style={{ padding: 0, margin: 0, listStyle: 'none' }}>
            {items.map((item, index) => (
              <li key={index} style={{ marginBottom: '4px' }}>
                {item}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <ul style={{ padding: 0, margin: 0, listStyle: 'none' }}>
          {items.map((item, index) => (
            <li key={index} style={{ marginBottom: '4px' }}>
              {item}
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

TooltipContent.propTypes = {
  text: PropTypes.string.isRequired,
};

export default TooltipContent;
