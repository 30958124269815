import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import {
  LANGUAGES,
  LANGUAGE_NAMES,
} from '../../../constants/ApplicationConstants';

const languages = Object.entries(LANGUAGES).map(([key, val]) => ({
  title: LANGUAGE_NAMES[val],
  key: val,
}));

export default function InitialLanguage({
  language,
  handleChangeLang,
  options,
}) {
  return (
    <FormControl
      sx={{ minWidth: 200, marginLeft: '1rem', ...options }}
      size="small"
    >
      <InputLabel id="lang">Initial language</InputLabel>
      <Select
        labelId="lang"
        value={language}
        label="Initial language"
        onChange={handleChangeLang}
      >
        {languages.map((i, ind) => (
          <MenuItem key={ind} value={i.key}>
            {i.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
