import React, { useEffect, useState } from 'react';
import { initData } from '../common/routes/InitValues';
import { useDispatch, useSelector } from 'react-redux';
import { updateData } from '../../config/actions';
import useLocalStorage from 'use-local-storage';
import { getToken, setToken } from '../../config/token';
import { ToastContainer } from 'react-toastify';
import { setStyleAttribute } from '../../config/helpers';
import { useTranslation } from 'react-i18next';
import ErrorBoundary from './ErrorBoundry';
import { USER_MODE } from '../../constants/ApplicationConstants';
import { useUserInfo } from '../../hooks/useGetUserInfo';

export default function MainLayout({ children }) {
  const token = getToken();
  const [data] = React.useState({ ...initData });
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const language = useSelector((s) => s.form.language);
  const userInfo = useSelector((s) => s.form.user);
  const mode = useSelector((s) => s.form.mode);
  const [themeSetting] = useLocalStorage('theme', 'panda');
  const [font] = useLocalStorage('font', 'Roboto');
  const [primaryColor] = useLocalStorage('primaryColor', '#fe4b4e');
  const [secondaryColor] = useLocalStorage('secondaryColor', '#fff9f0');
  const [expiration, setExpiration] = useState(
    localStorage.getItem('token')
      ? JSON.parse(localStorage.getItem('token')).expiration
      : null
  );
  const [currentDate] = useState(new Date());
  const { refetch } = useUserInfo();

  useEffect(() => {
    dispatch(updateData(data));
  }, [data]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, []);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setExpiration(JSON.parse(localStorage.getItem('token')).expiration);
    }
    if (expiration && currentDate.getTime() > expiration) {
      localStorage.clear();
      setToken(null);
    }
  }, [expiration, currentDate]);

  useEffect(() => {
    let bodyTag = document.getElementsByTagName('body')[0];
    bodyTag.setAttribute('data-theme', themeSetting);
  }, [themeSetting]);

  useEffect(() => {
    setStyleAttribute('--primary-font', font);
    setStyleAttribute('--primary-color', primaryColor);
    setStyleAttribute('--secondary-color', secondaryColor);
  }, [font, primaryColor, secondaryColor]);

  const body = getComputedStyle(document.body);
  const background = body.getPropertyValue('--b-color-text-primary-2').trim();

  useEffect(() => {
    document.body.style.backgroundColor = background;
  });

  useEffect(() => {
    if (mode === USER_MODE.ADMIN) {
      if (token && userInfo && userInfo.email.length > 0) {
        const script = document.createElement('script');
        script.src = '//eu.fw-cdn.com/12458148/514791.js';
        script.setAttribute('chat', 'true');
        script.setAttribute('widgetId', process.env.REACT_APP_WIDGET_ADMIN);
        document.body.appendChild(script);
        // script.onload = () => {
        //   window.fwcrm.on("user:created", function () {
        //     window.fcWidget.user.setEmail(userInfo.email);
        //     window.fcWidget.user.setFirstName("WithoutOTP");
        //   });
        // };

        return () => {
          document.body.removeChild(script);
        };
      }
    } else if (mode === USER_MODE.CLIENT) {
      if (token && userInfo && userInfo.email.length > 0) {
        const script = document.createElement('script');
        script.src = '//eu.fw-cdn.com/12458148/514791.js';
        script.setAttribute('chat', 'true');
        script.setAttribute('widgetId', process.env.REACT_APP_WIDGET_CLIENT);
        document.body.appendChild(script);
        // script.onload = () => {
        //   window.fwcrm.on("user:created", function () {
        //     window.fcWidget.user.setEmail(userInfo.email);
        //     window.fcWidget.user.setFirstName("WithoutOTP");
        //   });
        // };

        return () => {
          document.body.removeChild(script);
        };
      }
    }
  }, [mode, userInfo]);

  useEffect(() => {
    if (token && JSON.parse(token)) {
      refetch(JSON.parse(token).token);
    }
  }, [token]);

  return (
    <ErrorBoundary>
      <div data-theme={themeSetting}>
        <ToastContainer position="top-right" autoClose={2000} />
        {children}
      </div>
    </ErrorBoundary>
  );
}
