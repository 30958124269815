import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import randomColor from 'randomcolor';
import { useSelector } from 'react-redux';
import uuidv4 from 'react-uuid';
import { useTranslation } from 'react-i18next';
import { Request } from '../../../config/requests';
import { getToken } from '../../../config/token';
import { useState, useEffect } from 'react';
import { renameDropdownIdtoId, showToast } from '../../../config/helpers';

const FlowStepsAdd = ({
  steps,
  setSteps,
  openOptions,
  toggleOptions,
  subFlow,
  listDiagnosis,
}) => {
  const token = getToken();
  const { t } = useTranslation();
  const flowSteps = useSelector((s) => s.flow.flowSteps);
  const [available, setAvailable] = useState([]);

  const initOptions = [
    {
      text: 'New Document',
      label: 'New Document',
      code: 'new_claim',
      options: [],
      inputWidth: '87%',
      withText: false,
      value: 'New claim',
      withDescription: false,
      basic: true,
      width: 'Full width',
      groupTitle: '',
    },
    {
      text: 'Additional questions',
      label: 'Additional questions',
      code: 'existing_claim',
      options: [],
      inputWidth: '87%',
      withText: false,
      value: 'Existing claim',
      withDescription: false,
      basic: true,
      width: 'Full width',
      groupTitle: '',
    },
    {
      text: 'Type',
      label: 'Type',
      code: 'type',
      options: [
        {
          name: 'General',
          value: 'General',
        },
        {
          name: 'General to Vets',
          value: 'General to Vets',
        },
        {
          name: 'Liability claim',
          value: 'Liability claim',
        },
        {
          name: 'Cost estimation',
          value: 'Cost estimation',
        },
      ],
      inputWidth: '87%',
      withText: false,
      value: null,
      withDescription: false,
      basic: true,
      width: 'Full width',
      groupTitle: '',
    },
    {
      text: 'Diagnosis',
      label: 'Diagn.',
      code: 'diagnosis',
      options: listDiagnosis,
      inputWidth: '87%',
      withText: true,
      value: null,
      withDescription: false,
      basic: true,
      width: 'Full width',
      groupTitle: '',
    },
    {
      text: 'Trigger',
      label: 'Trigger',
      code: 'trigger',
      inputWidth: '87%',
      withText: false,
      value: null,
      withDescription: false,
      basic: true,
      width: 'Full width',
      groupTitle: '',
    },
    // {
    //   text: "GOT",
    //   label: "GOT",
    //   code: "got",
    //   options: gotDetails,
    //   inputWidth: "87%",
    //   withText: true,
    //   value: null,
    //   withDescription: false,
    //   basic: true,
    // },
    {
      text: 'Upload',
      label: 'Upload',
      code: 'upload',
      inputWidth: '100%',
      withText: false,
      value: null,
      withDescription: false,
      title: '',
      description: '',
      basic: false,
      id: 1,
      width: 'Full width',
      groupTitle: '',
      ocr: false,
    },
    {
      text: 'Dropdown',
      label: 'Dropdown',
      code: 'dropdown',
      inputWidth: '100%',
      withText: false,
      value: null,
      withDescription: false,
      withTitle: true,
      title: '',
      description: '',
      basic: false,
      width: 'Full width',
      groupTitle: '',
    },
    {
      text: 'Date',
      label: 'Date',
      code: 'date',
      inputWidth: '100%',
      withText: false,
      value: null,
      withDescription: true,
      title: '',
      description: '',
      basic: false,
      width: 'Full width',
      groupTitle: '',
    },
    {
      text: 'Invoice Table',
      label: 'Invoice Table',
      code: 'invoice_table',
      inputWidth: '100%',
      withText: false,
      value: null,
      withDescription: false,
      title: '',
      description: '',
      basic: false,
      width: 'Full width',
      groupTitle: '',
    },
    {
      text: 'Date range',
      label: 'From',
      label_2: 'To',
      code: 'date_range',
      inputWidth: '100%',
      withText: false,
      value: null,
      value_2: null,
      withDescription: true,
      title: '',
      description: '',
      basic: false,
      width: 'Full width',
      groupTitle: '',
    },
    {
      text: 'Multiple Choice Question',
      label: 'MCQ',
      code: 'mcq',
      options: [],
      content: [],
      inputWidth: '100%',
      withText: false,
      value: null,
      withDescription: true,
      title: '',
      description: '',
      basic: false,
      width: 'Full width',
      groupTitle: '',
    },
    {
      text: 'Open question',
      label: 'Question',
      code: 'oq',
      inputWidth: '100%',
      withText: false,
      value: null,
      withDescription: true,
      title: '',
      description: '',
      basic: false,
      width: 'Full width',
      groupTitle: '',
    },
    {
      text: 'Payment',
      label: 'Payment',
      code: 'payment',
      inputWidth: '100%',
      withText: false,
      value: null,
      withDescription: true,
      title: '',
      description: '',
      basic: false,
      width: 'Full width',
      groupTitle: '',
    },
    {
      text: 'End flow',
      label: 'Status',
      code: 'status',
      options: [
        { name: 'to be paid' },
        { name: 'to reject' },
        { name: 'to review' },
      ],
      inputWidth: '87%',
      withText: false,
      withDescription: false,
      value: null,
      basic: false,
      width: 'Full width',
      groupTitle: '',
    },
  ];

  const [options, setOptions] = useState(initOptions);

  async function fetchData() {
    try {
      const response = await Request({
        method: 'get',
        token: token,
        route: 'api/flows/get_available',
      });

      if (response.ok) {
        let json = await response.json();
        json = renameDropdownIdtoId(json);
        setAvailable(json.data);
        let changedOptions = options.map((option) => {
          if (option.code === 'type') {
            let availableOptions = json.data.map((el) => ({
              name: el,
              value: el,
            }));
            return { ...option, options: availableOptions };
          }
          return option;
        });
        setOptions(changedOptions);
      } else {
        showToast(t('somethingWentWrong'), { error: true });
      }
    } catch (error) {
      console.error(error);
      showToast(t('somethingWentWrong'), { error: true });
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleOption = (option) => {
    const step = steps.find((item) => item.text === option.text);
    const prev = steps[steps.length - 1];
    option.group = prev?.group + 1 || 1;
    option.uniqueId = uuidv4();

    if (!step) {
      if (option.code === 'new_claim') {
        const type = options.find((i) => i.code === 'type');
        option.group = 1;
        type.group = 2;
        steps.push(option);
        steps.push(type);
      } else if (option.code === 'existing_claim') {
        const trigger = options.find((i) => i.code === 'trigger');
        option.group = 1;
        trigger.group = 2;
        steps.push(option);
        steps.push(trigger);
      } else if (
        (option.code === 'diagnosis' || option.code === 'got') &&
        steps[0] &&
        steps[0].code === 'new_claim'
      ) {
        if (!steps[1]) {
          steps[1] = [];
        }
        steps[1].push(option);
      } else if (option.code === 'upload') {
        option.id = calcUploads(flowSteps) + 1 || 1;
        option.text += ' #' + option.id;
        option.color = randomColor();
        steps.push(option);
      } else {
        steps.push(option);
      }
    } else if (
      ['oq', 'date', 'date_range', 'diagnosis', 'upload', 'dropdown'].includes(
        option.code
      )
    ) {
      option.id = calcUploads(flowSteps) + 1 || 1;
      option.text += ' #' + option.id;
      steps.push(option);
    }
    setSteps([...steps]);

    toggleOptions();
  };

  const calcUploads = (steps) => {
    return steps.reduce((acc, step) => {
      if (step.code === 'upload') {
        return acc + 1;
      } else if (step.code === 'mcq') {
        return (
          acc +
          step.content.reduce((a, s) => {
            return a + calcUploads(s.content);
          }, 0)
        );
      }
      return acc;
    }, 0);
  };

  return (
    <div className="FSEnd">
      <AddCircleOutlineIcon
        sx={{ fontSize: 20 }}
        className="FSBtn"
        onClick={toggleOptions}
      />

      <div
        className={`FSEndOptions ${openOptions ? 'FSEndOptions--active' : ''}`}
        onBlur={toggleOptions}
      >
        {options.map((option, idx) => {
          // INITIAL
          if (!subFlow && option.basic && !steps[0]) {
            // DIAGNOSIS IS CHOSEN

            return (
              ((option.code === 'new_claim' && available.length > 0) ||
                option.code === 'existing_claim') && (
                <div
                  key={`option-${idx}`}
                  className="FSEndOptionsItem"
                  onClick={() => handleOption(option)}
                >
                  {t(option.text)}
                </div>
              )
            );
          }

          if (
            !subFlow &&
            option.basic &&
            (!steps[1] || steps[1]?.length < 2) &&
            !steps[2]
          ) {
            // DIAGNOSIS IS CHOSEN
            if (steps[1]?.find((item) => item.code === 'diagnosis')) {
              return (
                option.code === 'got' && (
                  <div
                    key={`option-${idx}`}
                    className="FSEndOptionsItem"
                    onClick={() => handleOption(option)}
                  >
                    {t(option.text)}
                  </div>
                )
              );
            }

            // GOT IS CHOSEN
            if (steps[1]?.find((item) => item.code === 'got')) {
              return (
                option.code === 'diagnosis' && (
                  <div
                    key={`option-${idx}`}
                    className="FSEndOptionsItem"
                    onClick={() => handleOption(option)}
                  >
                    {t(option.text)}
                  </div>
                )
              );
            }

            // NOTHING
            return (
              option.code !== 'new_claim' &&
              option.code !== 'existing_claim' && (
                <div
                  key={`option-${idx}`}
                  className="FSEndOptionsItem"
                  onClick={() => handleOption(option)}
                >
                  {t(option.text)}
                </div>
              )
            );
          }

          // AFTER DIAGNOSIS & GOT WERE CHOSEN
          if (
            !option.basic &&
            (steps.length || subFlow) &&
            // additional condition below used to hide "Invoice table" in list of available flow step types in case of "existing claim"
            !(
              (
                flowSteps[0]?.code === 'existing_claim' &&
                option.code === 'invoice_table'
              )
              // additional condition below used to hide "payment" option in list of available flow step types in case of "payment" step already used in flow
            ) &&
            !(
              steps.find((step) => step.code === 'payment') &&
              option.code === 'payment'
            )
          ) {
            return (
              <div
                key={`option-${idx}`}
                className="FSEndOptionsItem"
                onClick={() => handleOption(option)}
              >
                {t(option.text)}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default FlowStepsAdd;
