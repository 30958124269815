import CustomTooltip from '../../../components/ui/CustomTooltip';
import BlackCross from '../../../assets/PetPage/black_cross.svg';
import { useTranslation } from 'react-i18next';

export default function CheckDetails({ tariff, selectedPackage }) {
  const { t } = useTranslation();

  return (
    <div className="tariff_details__item__right__info SlowShow">
      {tariff[selectedPackage].additional_info.not_available.map(
        (option, ind) => (
          <div className="tariff_details__item__right__option" key={ind}>
            <div className="tariff_details__item__right__info__line">
              <img src={BlackCross} width={16} height={19} />
              <div>
                <span>{t(option.title)}</span>
                {option.tooltip && <CustomTooltip name={option.tooltip} />}
                {option.children && (
                  <div className="tariff_details__item__right__info__ul">
                    <ul>
                      {option.children.map((child, ind) => (
                        <li key={ind}>
                          {t(child.content)}
                          {child.tooltip && (
                            <CustomTooltip name={child.tooltip} />
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
}
