import React, { useState } from 'react';
import FormButtonFM from './FormButtonFM';
import QuestionContainer from './QuestionContainer';
import { useTranslation } from 'react-i18next';

function Type({ onUpdate, width, single = false }) {
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState('');

  return (
    <QuestionContainer width={width}>
      <div className="NavButtonContainer">
        <FormButtonFM name="Back" route="back" />

        <div className="Distance" />

        <FormButtonFM
          name="Next"
          route="next"
          onUpdate={() => onUpdate(selectedType)}
          disabled={selectedType == ''}
        />
      </div>

      <div
        className="TitleQuestion"
        style={
          single
            ? { padding: '0 40px', alignSelf: 'center' }
            : { alignSelf: 'center' }
        }
      >
        {t('typeSelect')}
      </div>

      <div
        className="d-flex flex-row justify-content-center w-100 p-3"
        style={single ? { padding: '0 40px' } : {}}
      >
        <div
          className="ButtonReverse Enabled"
          onClick={() => setSelectedType('accident')}
        >
          {t('accident')}
        </div>
        <div
          className="ButtonReverse Enabled"
          onClick={() => setSelectedType('prevention')}
        >
          {t('prevention')}
        </div>
      </div>
    </QuestionContainer>
  );
}

export default Type;
