export const manageUploads = (steps, index = 0, step) => {
  let uploads = {};
  steps.forEach((s) => {
    if (s.code === 'upload' || s.uniqueId === step?.uniqueId) {
      if (!uploads[index]) uploads[index] = [s];
      else uploads[index].push(s);
    } else if (s.code === 'mcq') {
      s.content.forEach((sub_step, ind) => {
        uploads = {
          ...uploads,
          ...manageUploads(sub_step.content, `${index}-${ind}`, step),
        };
      });
    }
  });
  return uploads;
};

export const getUploads = (step, flowSteps) => {
  const ups = manageUploads(flowSteps, 0, step);
  let k;
  for (const [key, val] of Object.entries(ups)) {
    if (val.some((i) => i.uniqueId === step?.uniqueId)) {
      k = key;
      break;
    }
  }
  const keys = k?.split('-');
  const upls = [];
  let kk = '';
  keys?.forEach((i) => {
    if (!kk) kk = i;
    else kk = kk + '-' + i;
    let upsContent = ups[kk] || [];
    const currStepIndex = upsContent?.findIndex(
      (r) => r.uniqueId === step?.uniqueId
    );
    if (currStepIndex !== -1) {
      upsContent = upsContent?.filter((r, ind) => ind < currStepIndex);
    }
    upls.push(upsContent);
  });
  let flat = [];
  upls.forEach((i) => (flat = [...flat, ...i]));
  return flat;
};
