import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setPets } from '../../config/actions';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { isMobile } from 'react-device-detect';
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
import PawUI from '../../components/ui/PawUI';
import Skeleton from '@mui/material/Skeleton';
import ErrorModal from '../../components/common/errorModal';
import RenderPets from './RenderPets';
import { useGetPets } from '../../hooks/useGetPets';

export default function Insurances() {
  const { t } = useTranslation();
  const getPets = useGetPets();
  const dispatch = useDispatch();
  const userInfo = useSelector((s) => s.form.user);
  const userPets = useSelector((s) => s.flow.pets);
  const [fetchingPets, setFetchingPets] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const fetchData = async () => {
      try {
        setFetchingPets(true);
        const { data } = await getPets();
        dispatch(setPets(data));
      } catch (error) {
        console.error(error);
        setError(true);
      } finally {
        setFetchingPets(false);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="main-container SlowShow">
      <Header />
      {userInfo ? (
        <div className="paws_layer relative">
          <div className="insurances_container">
            <div className="welcome_text">
              {t('hallo')},
              <br />
              <span>
                {userInfo.title ? `${userInfo.title} ` : ''}
                {userInfo.firstName} {userInfo.name}
              </span>
            </div>
            <div
              className="headline1_red_title"
              style={{
                margin: isMobile ? '3.5vh 0' : '5vh 0',
                textAlign: 'center',
              }}
            >
              {t('whichPet')}
            </div>
            <div className="pet_container">
              <>
                {!fetchingPets && userPets?.length > 0 ? (
                  <RenderPets userPets={userPets} />
                ) : (
                  <div className="pet_container__item">
                    <Skeleton
                      variant="rounded"
                      width={200}
                      height={256}
                      sx={{ borderRadius: '36px' }}
                    />
                    <Skeleton
                      variant="rounded"
                      width={100}
                      height={30}
                      sx={{ margin: '3px 0 0 0' }}
                    />
                  </div>
                )}
              </>
            </div>
          </div>
          {isMobile ? (
            <>
              <PawUI
                top="10vh"
                right="15vw"
                rotation="left"
                width="25%"
                zIndex="0"
              />
              <PawUI
                bottom="-20vh"
                left="-3vw"
                rotation="right"
                width="35%"
                zIndex="0"
              />
            </>
          ) : (
            <>
              <PawUI
                top="6vh"
                right="19vw"
                rotation="right"
                width="200px"
                zIndex="0"
              />
              <PawUI
                bottom="-30vh"
                left="-3vw"
                rotation="left"
                width="350px"
                zIndex="0"
              />
            </>
          )}
        </div>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{ width: '1100px' }}>
            <Skeleton
              variant="rounded"
              width={180}
              height={50}
              sx={{ margin: '10vh 0 5vh 0' }}
            />
          </Box>
          <Skeleton
            variant="rounded"
            width={700}
            height={50}
            sx={{ margin: '0 0 10vh 0' }}
          />
          <Skeleton
            variant="rounded"
            width={'100%'}
            height={300}
            sx={{ margin: '0 0 10vh 0' }}
          />
        </Box>
      )}
      <Footer />
      {error && (
        <ErrorModal openModal={error} setErrorModal={() => setError(!error)} />
      )}
    </div>
  );
}
