import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';
import ModalWrapper from '../../../components/modalWrapper';
import { useNavigate } from 'react-router-dom';
import { attachToExistingClaim } from '../../../services/clams';
import {
  setFlowLoading,
  updateMissedChip,
  updateMissedDiag,
} from '../../../config/actions';
import { FLOW_TYPES } from '../../../constants/Flow';
import { useSearchParams } from 'react-router-dom';
import { showToast } from '../../../config/helpers';

function ModalMissingOCR({
  openModalMissingOCR,
  setOpenModalMissingOCR,
  reportType,
  onFinish,
  duplicatesListClaimNumber,
  duplicatesList,
}) {
  const { t } = useTranslation();
  const router = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const uploadedImages = useSelector((s) => s.flow.uploadedImages);
  const flowType = useSelector((s) => s.flow.flowType);
  const selectedFlowType = useSelector((s) => s.flow.selectedFlowTypeToSkip);
  const existingTypeDocument = useSelector((s) => s.flow.existingTypeDocument);
  const [hasDuplicate, setHasDuplicate] = useState(true);
  const [reuploadClaim, setReuploadClaim] = useState(false);
  const [showThanks, setShowThanks] = useState(false);
  const [needChipYes, setNeedChipYes] = useState(false);
  const [needChipNo, setNeedChipNo] = useState(false);
  const [needDiag, setNeedDiag] = useState(false);
  const [docType, setDocType] = useState('invoice');
  const [attachDocType, setAttachDocType] = useState('');

  const headerLabels = {
    duplicates: t('submitInvoice'),
    diagnosis: t('missingDiag'),
    'chipnumber does not match': t('chipDoesNotMatch'),
    'chipnumber not found': t('missingChip'),
  };

  async function handleAttachToExisting() {
    dispatch(setFlowLoading(true));
    const isSuccess = await attachToExistingClaim(
      duplicatesList,
      'Additional attachment',
      uploadedImages,
      searchParams,
      attachDocType
    );

    if (isSuccess) {
      showToast(t('claimSuccess'));
      router('/flow-completed');
    } else {
      showToast(t('somethingWentWrong'), { error: true });
      router('/');
      console.error('handleAttachToExisting: One or more requests failed.');
    }
    dispatch(setFlowLoading(false));
    setOpenModalMissingOCR(false);
  }

  function handleClose() {
    onFinish();
    setOpenModalMissingOCR(false);
  }

  useEffect(() => {
    if (
      flowType === FLOW_TYPES['General'] ||
      flowType === FLOW_TYPES['General to Vets'] ||
      (flowType === FLOW_TYPES['Existing Claim'] &&
        existingTypeDocument === 'Subsequent invoice')
    ) {
      setDocType('invoice');
    } else {
      setDocType('document');
    }
  }, [flowType, existingTypeDocument]);

  useEffect(() => {
    if (
      flowType === FLOW_TYPES['General'] ||
      flowType === FLOW_TYPES['General to Vets'] ||
      (flowType === FLOW_TYPES['Existing Claim'] &&
        selectedFlowType === FLOW_TYPES['General']) ||
      selectedFlowType === FLOW_TYPES['General to Vets'] ||
      existingTypeDocument === 'Subsequent invoice'
    ) {
      setAttachDocType('Subsequent invoice');
    } else if (
      flowType === FLOW_TYPES['Existing Claim'] &&
      existingTypeDocument !== 'Subsequent invoice'
    ) {
      setAttachDocType(existingTypeDocument);
    }
  }, [flowType, selectedFlowType, existingTypeDocument]);

  return (
    <ModalWrapper
      headerTitle={headerLabels[reportType]}
      openModal={openModalMissingOCR}
      setModalWrapper={() => handleClose()}
      noClose
      longTitle={isMobileOnly && reportType === 'chipnumber does not match'}
    >
      {reportType === 'diagnosis' && (
        <>
          {needDiag ? (
            <>
              <div
                className="modal_container__body__text"
                style={{
                  maxWidth: '540px',
                  margin: '0 0 5vh 0',
                }}
              >
                <span>
                  {t(
                    flowType === 'Existing Claim' &&
                      existingTypeDocument === 'Diagnosis'
                      ? 'needDiag_EC'
                      : docType === 'invoice'
                        ? 'needDiag_inv'
                        : 'needDiag_doc'
                  )}
                </span>
              </div>
              <div className="modal_container__body__row-modal">
                <div
                  className={`button_red_small ${isMobileOnly && 'mobile'}`}
                  onClick={() => router('/')}
                >
                  Ok
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className="modal_container__body__text"
                style={{
                  maxWidth: '540px',
                  margin: '0 0 5vh 0',
                }}
              >
                {t(
                  docType === 'invoice'
                    ? 'cantFindDiag_inv'
                    : 'cantFindDiag_doc'
                )}
              </div>
              <div className="modal_container__body__row-modal">
                <div
                  className={`button_red_small ${isMobileOnly && 'mobile'}`}
                  onClick={() => {
                    dispatch(updateMissedDiag(true));
                    onFinish();
                  }}
                >
                  {t('yes')}
                </div>
                <div
                  className={`button_red_small ${isMobileOnly && 'mobile'}`}
                  onClick={() => setNeedDiag(true)}
                >
                  {t('no')}
                </div>
              </div>
            </>
          )}
        </>
      )}
      {reportType === 'chipnumber not found' && (
        <>
          <div
            className="modal_container__body__text"
            style={{
              maxWidth: '540px',
              margin: '0 0 5vh 0',
            }}
          >
            <span>
              {t(
                docType === 'invoice' ? 'cantFindChip_inv' : 'cantFindChip_doc'
              )}
            </span>
          </div>
          <div className="modal_container__body__row-modal">
            <div
              className={`button_red_small ${isMobileOnly && 'mobile'}`}
              onClick={() => {
                dispatch(updateMissedChip(false));
                onFinish();
              }}
            >
              {t('yes')}
            </div>
            <div
              className={`button_red_small ${isMobileOnly && 'mobile'}`}
              onClick={() => router('/')}
            >
              {t('no')}
            </div>
          </div>
        </>
      )}
      {reportType === 'chipnumber does not match' && (
        <>
          {!needChipYes && !needChipNo && (
            <>
              <div
                className="modal_container__body__text"
                style={{
                  maxWidth: '540px',
                  margin: '0 0 5vh 0',
                }}
              >
                <span>
                  {t(
                    docType === 'invoice'
                      ? 'chipDoesNotMatch1_inv'
                      : 'chipDoesNotMatch1_doc'
                  )}
                </span>
                <br />
                <span>
                  {t(
                    docType === 'invoice'
                      ? 'chipDoesNotMatch2_inv'
                      : 'chipDoesNotMatch2_doc'
                  )}
                </span>
              </div>
              <div className="modal_container__body__row-modal">
                <div
                  className={`button_red_small ${isMobileOnly && 'mobile'}`}
                  onClick={() => {
                    dispatch(updateMissedChip(false));
                    setNeedChipYes(true);
                  }}
                >
                  {t('yes')}
                </div>
                <div
                  className={`button_red_small ${isMobileOnly && 'mobile'}`}
                  onClick={() => {
                    setNeedChipNo(true);
                  }}
                >
                  {t('no')}
                </div>
              </div>
            </>
          )}
          {needChipYes && (
            <>
              <div
                className="modal_container__body__text"
                style={{
                  maxWidth: '540px',
                  margin: '0 0 5vh 0',
                }}
              >
                <span>{t('needChipYes')}</span>
              </div>
              <div className="modal_container__body__row-modal">
                <div
                  className={`button_red_small ${isMobileOnly && 'mobile'}`}
                  onClick={() => onFinish()}
                >
                  Ok
                </div>
              </div>
            </>
          )}
          {needChipNo && (
            <>
              <div
                className="modal_container__body__text"
                style={{
                  maxWidth: '540px',
                  margin: '0 0 5vh 0',
                }}
              >
                <span>
                  {t(
                    docType === 'invoice' ? 'needChipNo_inv' : 'needChipNo_doc'
                  )}
                </span>
              </div>
              <div className="modal_container__body__row-modal">
                <div
                  className={`button_red_small ${isMobileOnly && 'mobile'}`}
                  onClick={() => router('/')}
                >
                  Ok
                </div>
              </div>
            </>
          )}
        </>
      )}
      {reportType === 'duplicates' && (
        <>
          {hasDuplicate && (
            <>
              <div
                className="modal_container__body__text"
                style={{
                  maxWidth: '540px',
                  margin: '5vh 0 1vh 0',
                }}
              >
                {t(
                  docType === 'invoice'
                    ? 'hasDuplicate1_inv'
                    : 'hasDuplicate1_doc'
                )}
              </div>
              {duplicatesListClaimNumber?.split(',').map((el, ind) => (
                <span key={ind}>{el}</span>
              ))}
              <div
                className="modal_container__body__text"
                style={{
                  maxWidth: '540px',
                  margin: '1vh 0 5vh 0',
                }}
              >
                {t('hasDuplicate2')}
              </div>
              <div className="modal_container__body__row-modal">
                <div
                  className={`button_red_small ${isMobileOnly && 'mobile'}`}
                  onClick={() => handleAttachToExisting()}
                >
                  {t('yes')}
                </div>
                <div
                  className={`button_red_small ${isMobileOnly && 'mobile'}`}
                  onClick={() => {
                    setHasDuplicate(false);
                    setReuploadClaim(true);
                  }}
                >
                  {t('no')}
                </div>
              </div>
            </>
          )}
          {reuploadClaim && (
            <>
              <div
                className="modal_container__body__text"
                style={{
                  maxWidth: '540px',
                  margin: '0 0 5vh 0',
                }}
              >
                {t(
                  docType === 'invoice'
                    ? 'sureReuploadClaim_inv'
                    : 'sureReuploadClaim_doc'
                )}
              </div>
              <div className="modal_container__body__row-modal">
                <div
                  className={`button_red_small ${isMobileOnly && 'mobile'}`}
                  onClick={() => handleAttachToExisting()}
                >
                  {t('yes')}
                </div>
                <div
                  className={`button_red_small ${isMobileOnly && 'mobile'}`}
                  onClick={() => {
                    setReuploadClaim(false);
                    setShowThanks(true);
                  }}
                >
                  {t('no')}
                </div>
              </div>
            </>
          )}
          {showThanks && (
            <>
              <div
                className="modal_container__body__text"
                style={{
                  maxWidth: '540px',
                  margin: '0 0 5vh 0',
                }}
              >
                {t(docType === 'invoice' ? 'thankModal_inv' : 'thankModal_doc')}
              </div>
              <div
                className={`button_red_small ${isMobileOnly && 'mobile'}`}
                style={{ minWidth: '225px' }}
                onClick={() => router('/claims')}
              >
                {t('myDamages')}
              </div>
              <div
                className={`button_red_small ${isMobileOnly && 'mobile'}`}
                style={{ minWidth: '225px', margin: '15px 0 0 0' }}
                onClick={() => router('/')}
              >
                {t('customerPortal')}
              </div>
            </>
          )}
        </>
      )}
    </ModalWrapper>
  );
}

export default ModalMissingOCR;
