import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import { useSelector } from 'react-redux';
import PawUI from '../ui/PawUI';
import { isMobileOnly } from 'react-device-detect';
import Exclamation from '../../assets/iconsSmall/exclamation_mark_dark.svg';

const FlowCompleted = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const flowType = useSelector((s) => s.flow.flowType);
  const docCounter = useSelector((s) => s.flow.docCounter);
  const existingTypeDocument = useSelector((s) => s.flow.existingTypeDocument);

  function flowTypeHandler(flowType) {
    switch (flowType) {
      case 'General':
        return [
          t(
            docCounter > 1 ? 'thankForUpload_G_few_inv' : 'thankForUpload_G_inv'
          ),
          t('nowProcessed_G'),
        ];
      case 'General to Vets':
        return [
          t(
            docCounter > 1 ? 'thankForUpload_G_few_inv' : 'thankForUpload_G_inv'
          ),
          t('nowProcessed_GV'),
        ];
      default:
      case 'Liability claim':
        return [
          t(docCounter > 1 ? 'thankForUpload_LC_few' : 'thankForUpload_LC'),
          t('nowProcessed_LC'),
        ];
      case 'Cost estimation':
        return [
          t(docCounter > 1 ? 'thankForUpload_CE_few' : 'thankForUpload_CE'),
          t('nowProcessed_CE'),
        ];
      case 'Existing Claim':
        return [
          t(
            docCounter > 1
              ? existingTypeDocument === 'Subsequent invoice'
                ? 'thankForUpload_G_few_inv'
                : 'thankForUpload_G_few_doc'
              : existingTypeDocument === 'Subsequent invoice'
                ? 'thankForUpload_G_inv'
                : 'thankForUpload_G_doc'
          ),
          null,
        ];
    }
  }

  return (
    <div className="main-container SlowShow">
      <Header />
      <div className="claim__content">
        <div className="flow-final-step">
          {isMobileOnly ? (
            <>
              <PawUI
                top="25vh"
                right="8vw"
                rotation="right"
                width="130px"
                zIndex="-1"
              />
              <PawUI
                bottom="25vh"
                left="0vw"
                rotation="left"
                width="200px"
                zIndex="-1"
              />
            </>
          ) : (
            <>
              <PawUI
                top="25vh"
                right="8vw"
                rotation="right"
                width="200px"
                zIndex="-1"
              />
              <PawUI
                bottom="25vh"
                left="0vw"
                rotation="left"
                width="300px"
                zIndex="-1"
              />
            </>
          )}
          <div className="SlowShow show-final-body">
            <div className="headline1_red_title">
              {flowTypeHandler(flowType)[0]}
            </div>
            {/* Temporary hidden subtext with smiley */}
            {/* {flowTypeHandler(flowType)[1] &&
              <div className="show-final-body__subtext">
                <b>{flowTypeHandler(flowType)[1]} &#x1F60A;</b>
              </div>} */}
            {flowType === 'General to Vets' && (
              <div
                className="modal_container__body__info"
                style={{ width: isMobileOnly ? 'unset' : '800px' }}
              >
                <span>{t('achtung')}</span>
                {t('thankYouPageIngo')} &#x1F60A;
                <img src={Exclamation} />
              </div>
            )}
            <div className="button_red_main" onClick={() => history('/')}>
              {t('toStartPage')}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FlowCompleted;
