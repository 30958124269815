export const FormConstants = {
  DROP_DOWN_TYPE: 'dropDowns',
  INPUT_TYPE: 'inputs',
  DIAGNOSIS_SELECT: 'diagnosisSelect',
};

export const FMConstants = {
  PAYMENT: 'payment',
  DATE: 'date',
  DATE_RANGE: 'date_range',
  MCQ: 'mcq',
  OQ: 'oq',
  DD: 'dd',
  STATUS: 'status',
  UPLOAD: 'upload',
  DROPDOWN: 'dropdown',
  INVOICE_TABLE: 'invoice_table',
  EXISTING_CLAIM: 'existing_claim',
  TYPE: 'type',
};

export const FormComponentsWithImage = {
  date: 'date',
  date_range: 'date_range',
  mcq: 'mcq',
  oq: 'oq',
  dropdown: 'dropdown',
  type: 'type',
};
