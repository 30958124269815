export const mockExistingClaimFlow = [
  [
    {
      type: 'dropdown',
      content: {
        value: null,
        title: 'Welches Dokument möchtest Du nachreichen?',
        selected: '655b7b2a195758408b78e452',
        isOcr: false,
        groupTitle: '',
        width: 'Full width',
        description: '',
      },
      group: 3,
      uniqueId: '184e6ab7-3be0-f5ad-3cfc-4d38aa4ff125',
    },
  ],
  [
    {
      type: 'upload',
      content: {
        value: null,
        isSingle: true,
        title: 'You can upload your documents here',
        ocr: true,
        uniqueId: '3bb754a2-5151-5859-95b9-3ef5340aa099',
        groupTitle: '',
        width: 'Full width',
        description: '',
      },
      group: 4,
      uniqueId: '3bb754a2-5151-5859-95b9-3ef5340aa099',
    },
  ],
  [
    {
      type: 'existing_claim',
      content: {
        value: null,
        title: null,
        description: '',
        isOcr: false,
        uploadId: '3bb754a2-5151-5859-95b9-3ef5340aa099',
        groupTitle: '',
        width: 'Full width',
      },
      group: 5,
      uniqueId: '1cadc72a-4217-7bf6-64c5-30ccb6fdc1a2',
    },
  ],
];

export const mockExistingClaimFlow_subsequent = [
  [
    {
      type: 'dropdown',
      content: {
        value: null,
        title: 'Welches Dokument möchtest Du nachreichen?',
        selected: '655b7b2a195758408b78e452',
        isOcr: false,
        groupTitle: '',
        width: 'Full width',
        description: '',
      },
      group: 3,
      uniqueId: '184e6ab7-3be0-f5ad-3cfc-4d38aa4ff125',
    },
  ],
  [
    {
      type: 'upload',
      content: {
        value: null,
        isSingle: true,
        title: 'You can upload your invoices here',
        ocr: true,
        uniqueId: '3bb754a2-5151-5859-95b9-3ef5340aa099',
        groupTitle: '',
        width: 'Full width',
        description: '',
      },
      group: 4,
      uniqueId: '3bb754a2-5151-5859-95b9-3ef5340aa099',
    },
  ],
  [
    {
      type: 'invoice_table',
      content: {
        value: null,
        isSingle: true,
        title: null,
        isOcr: true,
        uploadId: '3bb754a2-5151-5859-95b9-3ef5340aa099',
        description: null,
      },
      group: 5,
      uniqueId: 'b555be56-cfb2-deee-3139-de6d6b2e35db',
    },
  ],
  [
    {
      type: 'existing_claim',
      content: {
        value: null,
        title: null,
        description: '',
        isOcr: false,
        uploadId: '3bb754a2-5151-5859-95b9-3ef5340aa099',
        groupTitle: '',
        width: 'Full width',
      },
      group: 6,
      uniqueId: '1cadc72a-4217-7bf6-64c5-30ccb6fdc1a2',
    },
  ],
  [
    {
      type: 'payment',
      content: {
        value: null,
        ocr: false,
        uniqueId: '3bb754a2-5151-5859-95b9-3ef5340aa099',
        groupTitle: '',
        description: 'Which account should we transfer the payment to?',
        title: 'Desired account for transfer',
      },
      group: 7,
      uniqueId: 'c24c4d8a-889a-ce29-cf7d-e75c0e458377',
    },
  ],
];

export const mockExistingClaimTranslations = {
  initial_language: 'en',
  translations: {
    en: {
      'Upload title': 'Upload title',
      'Upload description': 'Upload description',
      'Incedent date': 'Incedent date',
      Adress: 'Adress',
      Name: 'Name',
      'Welches Dokument möchtest Du nachreichen?':
        'Which document do you want to submit later?',
    },
    de: {
      'You can upload your invoices here':
        'Hier kannst Du Deine Rechnungen hochladen',
      'You can upload your documents here':
        'Hier kannst Du Deine Dokumente hochladen',
    },
  },
};

export const existingClaimsDropwodns = {
  'Subsequent invoice': 'Subsequent invoice',
  Findings: 'Findings',
  Anamnese: 'Anamnese',
  'Vet report': 'Vet report',
  Diagnosis: 'Diagnosis',
  'Chip number': 'Chip number',
  'Operation report': 'Operation report',
  'Pet ID card': 'Pet ID card',
  Other: 'Other',
};

export const existingClaimsDropwodns_DE = {
  'Subsequent invoice': 'Folgerechnung',
  Findings: 'Befund',
  Anamnese: 'Anamnese',
  'Vet report': 'Bericht Tierarzt',
  Diagnosis: 'Diagnose',
  'Chip number': 'Chipnummer',
  'Operation report': 'Operationsbericht',
  'Pet ID card': 'Heimtierausweis',
  Other: 'anderes Dokument',
};
