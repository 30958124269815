import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import FullScreen from '../../../assets/iconsSmall/full-screen.svg';
import Collapse from '../../../assets/iconsSmall/collapse.svg';
import Close from '../../../assets/iconsSmall/close.svg';
import { isMobile } from 'react-device-detect';

function ModalAdvice({
  isModalAdviceOpen,
  setIsModalAdviceOpen,
  themeSetting,
  advice,
}) {
  const { t } = useTranslation();
  const [isExpand, setIsExpand] = useState(false);

  const style = {
    position: 'absolute',
    left: '50%',
    width: isMobile ? '90%' : '80%',
    boxShadow: 12,
    p: 4,
    borderRadius: isMobile ? '20px' : '45px',
    backgroundColor: '#FFF9F0 !important',
    padding: isMobile ? '20px' : '40px',
    transform: 'translateX(-50%)',
    margin: '5vh 0 0 0',
    outline: 'none',
  };

  const styleFS = {
    position: isMobile ? 'absolute' : 'relative',
    width: '100%',
    boxShadow: 12,
    p: 4,
    borderRadius: isMobile ? '20px' : '45px',
    backgroundColor: '#FFF9F0 !important',
    padding: isMobile ? '20px' : '40px',
    outline: 'none',
  };

  function handleClose() {
    setIsModalAdviceOpen(false);
  }

  return (
    <div className="ContainerVendor" data-theme={themeSetting}>
      <Modal
        open={isModalAdviceOpen}
        onClose={handleClose}
        className="ModalCustom"
      >
        <Box sx={isExpand ? styleFS : style} data-theme={themeSetting}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '0 0 20px 0',
            }}
          >
            <div>
              <button
                className="btn-reset"
                onClick={() => setIsExpand(!isExpand)}
              >
                {isExpand ? (
                  <img
                    src={Collapse}
                    width={isMobile ? 18 : 34}
                    height={isMobile ? 18 : 34}
                  />
                ) : (
                  <img
                    src={FullScreen}
                    width={isMobile ? 18 : 34}
                    height={isMobile ? 18 : 34}
                  />
                )}
              </button>
              <button className="btn-reset" onClick={handleClose}>
                <img
                  src={Close}
                  width={isMobile ? 18 : 34}
                  height={isMobile ? 18 : 34}
                />
              </button>
            </div>
          </Box>

          {isMobile ? (
            <div>
              <div
                className="invoice-table-title column-centered"
                style={{ margin: '0 0 1vh 0' }}
              >
                <div className="invoice-table-title__title">
                  {t('adjudication')}
                </div>
              </div>
              <div>{advice}</div>
            </div>
          ) : (
            <div className="table_wrapper" style={{ margin: '0' }}>
              <div
                className="invoice-table-title column-centered"
                style={{ margin: '0 0 2vh 0' }}
              >
                <div className="invoice-table-title__title">
                  {t('adjudication')}
                </div>
              </div>
              <div style={{ whiteSpace: 'break-spaces' }}>{advice}</div>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}

export default ModalAdvice;
