import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import TranslateIcon from '@mui/icons-material/Translate';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Request } from '../../../config/requests';
import { getToken } from '../../../config/token';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTableScroll } from '../../../hooks/useTableScroll';

function FlowManagementTable({ tableData, setCurrentIndex }) {
  const headers = ['Category', 'Trigger', 'actions'];
  const history = useNavigate();
  const { t } = useTranslation();

  const deleteFlow = (id) => {
    Request({ method: 'delete', route: `api/flows/${id}`, token: getToken() })
      .then((res) => {
        return res.json();
      })
      .then(() => {
        setCurrentIndex(id);
      });
  };

  const goToEdit = (flowId) => {
    history(`/management/edit/${flowId}`);
  };

  const { bodyRef, headerRef, syncScroll, listOfItemsRef } = useTableScroll();

  return (
    <div className="d-flex flex-column" style={{ width: '100%' }}>
      <TableContainer
        className="reset-table-spacing table-reset-header"
        sx={{ margin: '0 0 -3px 0' }}
        ref={headerRef}
      >
        <Table
          className={`SlowShow reset-table-new table-flows`}
          sx={{ minWidth: 600 }}
        >
          <TableHead>
            <TableRow className="reset-table-new__header">
              {headers.map((header, index) => (
                <TableCell
                  key={`header-${index}`}
                  align={index > 0 ? 'inherit' : 'left'}
                >
                  <div>
                    <span>{t(header)}</span>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>

      <TableContainer
        className="reset-table-spacing"
        sx={{ maxHeight: 314 }}
        ref={bodyRef}
        onScroll={syncScroll}
      >
        <Table
          className={`SlowShow reset-table-new table-flows`}
          sx={{ minWidth: 600 }}
        >
          {tableData.length > 0 && (
            <TableBody ref={listOfItemsRef}>
              {tableData.map(
                (row, index) =>
                  Object.keys(row.data).length && (
                    <>
                      <TableRow
                        key={`row-${index}`}
                        className="reset-table-new__body"
                      >
                        <TableCell>
                          <div>
                            <span>
                              {(row.data.content &&
                              Array.isArray(row.data.content[0].content?.value)
                                ? row.data.content[0].content?.value.join(', ')
                                : t(row.data.content[0].content?.value)) || '-'}
                            </span>
                          </div>
                        </TableCell>

                        <TableCell>
                          <div>
                            <span>
                              {(row.data.content &&
                              Array.isArray(row.data.content[1].content?.values)
                                ? row.data.content[1].content?.values.join(', ')
                                : t(row.data.content[1].content?.value)) || '-'}
                            </span>
                          </div>
                        </TableCell>

                        <TableCell sx={{ padding: '0 5px' }}>
                          <div className="d-flex flex-row justify-content-center">
                            <div
                              className="back_icon_red"
                              style={{ marginRight: '10px' }}
                            >
                              <EditOutlinedIcon
                                sx={{
                                  color: '#FFFAF0',
                                  top: '5px',
                                  position: 'relative',
                                }}
                                onClick={() => goToEdit(row.id)}
                              />
                            </div>
                            <div
                              className="back_icon_red"
                              style={{ marginRight: '10px' }}
                            >
                              <DeleteOutlineOutlinedIcon
                                sx={{
                                  color: '#FFFAF0',
                                  top: '5px',
                                  position: 'relative',
                                }}
                                onClick={() => deleteFlow(row.id)}
                              />
                            </div>
                            <div className="back_icon_red">
                              <TranslateIcon
                                sx={{
                                  color: '#FFFAF0',
                                  top: '5px',
                                  position: 'relative',
                                }}
                                onClick={() =>
                                  history(
                                    `/management/translations?flow_id=${row.id}`
                                  )
                                }
                              />
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    </>
                  )
              )}
            </TableBody>
          )}
          {!tableData.length && (
            <div className="NoTableData">{t('noFlowsCreateNew')}</div>
          )}
        </Table>
      </TableContainer>
    </div>
  );
}

export default FlowManagementTable;
