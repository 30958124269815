import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import React from 'react';
import Modal from '@mui/material/Modal';
import Close from '../assets/modal/close-x-white.svg';
import PandaHeader from '../assets/modal/panda-header-logo.svg';
import PandaFooter from '../assets/modal/panda-footer-logo.svg';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';

const ModalWrapper = ({
  headerTitle,
  openModal,
  setModalWrapper,
  children,
  noClose = false,
  longTitle = false,
  selectedDataToChange,
  petData,
}) => {
  const { t } = useTranslation();
  function handleClose() {
    setModalWrapper(false);
  }

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      className="custom_blured_background FastShow"
      disableEscapeKeyDown
    >
      <div className="modal_container">
        <div className="modal_container__main">
          <div className="modal_container__header">
            {!noClose && (
              <img
                className="modal_container__header__close"
                src={Close}
                onClick={handleClose}
              />
            )}
            <img
              className="modal_container__header__logo"
              style={{ margin: longTitle ? '10px 0 0 0' : '' }}
              src={PandaHeader}
            />

            {headerTitle ? (
              <div className="text-centered">{headerTitle}</div>
            ) : (
              <div className="text-centered">
                {selectedDataToChange
                  ? petData.data.petType == 'Dog' &&
                    selectedDataToChange.title == 'dogHeightChange'
                    ? t('dogHeightChange')
                    : petData.data.petType == 'Cat'
                      ? t('catHabitatChange')
                      : t(selectedDataToChange.title)
                  : t('changeData')}
              </div>
            )}
          </div>
          <div className="modal_container__body">{children}</div>

          <img className="modal_container__footer" src={PandaFooter} />
        </div>
      </div>
    </Modal>
  );
};

export default ModalWrapper;
import { styled, Menu } from '@mui/material';

export const Label = styled(StepLabel)(() => ({
  display: 'flex',
  gap: '0',
  '&  .MuiSvgIcon-root': {
    color: '#ffffffb0 ',
  },
  '& .MuiSvgIcon-root.Mui-active': {
    color: 'white ',
  },
  '& .Mui-completed  .MuiSvgIcon-root': {
    color: 'white!important',
  },
  '& .MuiStepIcon-text': {
    fill: '#fe4b4e',
  },
  '& .MuiStepLabel-label': {
    color: 'white!important',
    display: 'flex',
    gap: 0,
  },
}));
