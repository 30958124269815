import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Account from '../Account';
import Claims from '../claims/Claims';
import ContactClaim from '../ContactClaim';
import FlowSteps from '../flowManagement/FlowSteps';
import Insurances from '../insurances/Insurances';
import PetPage from '../petPage/PetPage';
import Login from '../Login';
import Management from '../Management';
import Audit from '../Audit';
import AuditDuplicated from '../auditClaims/auditDuplicated/AuditDuplicated.js';
import RequireAuth from './RequireAuth';
import Settings from '../Settings';
import MainLayout from '../../components/common/MainLayout';
import AuditOcr from '../auditClaims/auditOcr/AuditOcr';
import DropdownPage from '../dropdown/DropdownPage';
import AuditPrevention from '../auditClaims/auditPrevention/auditPrevention.js';
import Page404 from './Page404';
import FlowTranslations from '../flowManagement/FlowTranslations';
import FlowCompleted from '../../components/common/FlowCompleted.js';
import ReadClaim from '../auditClaims/readClaim/ReadClaim.js';
import PaymentPage from '../auditClaims/paymentPage/PaymentPage.js';
import AdminChanges from '../adminAutomation/AdminChanges';
import { getToken, getUser } from '../../config/token.js';

const CLIENT_ROUTES = [
  {
    path: '/',
    element: (
      <MainLayout>
        <RequireAuth>
          <Insurances />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: '/pet/:id',
    element: (
      <MainLayout>
        <RequireAuth>
          <PetPage />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: '/account',
    element: (
      <MainLayout>
        <RequireAuth>
          <Account />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: '/claims',
    element: (
      <MainLayout>
        <RequireAuth>
          <Claims />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: '/contact/:id/claim',
    element: (
      <MainLayout>
        <RequireAuth>
          <ContactClaim />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: '/flow-completed',
    element: (
      <MainLayout>
        <FlowCompleted />
      </MainLayout>
    ),
  },
];

const ADMIN_ROUTES = [
  ...CLIENT_ROUTES,
  {
    path: '/management',
    element: (
      <MainLayout>
        <RequireAuth>
          <Management />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: '/admin',
    element: (
      <MainLayout>
        <RequireAuth>
          <AdminChanges />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: '/dropdown',
    element: (
      <MainLayout>
        <RequireAuth>
          <DropdownPage />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: '/audit',
    element: (
      <MainLayout>
        <RequireAuth>
          <Audit />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: '/audit-duplicated',
    element: (
      <MainLayout>
        <RequireAuth>
          <AuditDuplicated />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: '/audit-prevention',
    element: (
      <MainLayout>
        <RequireAuth>
          <AuditPrevention />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: '/audit-ocr',
    element: (
      <MainLayout>
        <RequireAuth>
          <AuditOcr />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: '/read-claim',
    element: (
      <MainLayout>
        <RequireAuth>
          <ReadClaim />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: '/payment-page',
    element: (
      <MainLayout>
        <RequireAuth>
          <PaymentPage />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: '/management/create',
    element: (
      <MainLayout>
        <RequireAuth>
          <FlowSteps />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: '/management/translations',
    element: (
      <MainLayout>
        <RequireAuth>
          <FlowTranslations />
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: '/management/edit/:flow_id',
    element: (
      <MainLayout>
        <RequireAuth>
          <div className="flow-steps-container">
            <FlowSteps />
          </div>
        </RequireAuth>
      </MainLayout>
    ),
  },
  {
    path: '/settings',
    element: (
      <MainLayout>
        <RequireAuth>
          <Settings />
        </RequireAuth>
      </MainLayout>
    ),
  },
];

const CATCH_ALL_ROUTE = {
  path: '/*',
  element: (
    <MainLayout>
      <RequireAuth>
        <Page404 />
      </RequireAuth>
    </MainLayout>
  ),
};

const GUEST_ROUTES = {
  path: '/',
  element: (
    <MainLayout>
      <Login />
    </MainLayout>
  ),
};

function getAvailableRoutes() {
  const { token } = getToken() ? JSON.parse(getToken()) : {};
  const user = getUser();

  if (!token || !user) {
    return [GUEST_ROUTES, CATCH_ALL_ROUTE];
  }

  const routes = user.role === 'admin' ? ADMIN_ROUTES : CLIENT_ROUTES;

  return [...routes, CATCH_ALL_ROUTE];
}

const router = createBrowserRouter(getAvailableRoutes());

export default router;
