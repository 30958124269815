import { Request } from '../../config/requests';
import { getToken } from '../../config/token';
import { recordImage } from '../../services/images';

export async function handleParagraphOcr({
  allPhotos,
  ocrDetails,
  data,
  setOcrDetails,
  claim_id,
  noAnalyze = false,
  currentPetInfo,
  setDocCounter,
  errorHandler,
}) {
  let attachedFiles = [];
  setDocCounter(allPhotos.length);

  for (let i = 0; i < allPhotos.length; i++) {
    attachedFiles.push(allPhotos[i]);
  }

  const attachedFilesId = await recordImage(attachedFiles)
    .then((res) => {
      const data = res.json();
      return data;
    })
    .then((res) => {
      return res.data;
    })
    .catch(console.error);

  if (!noAnalyze) {
    try {
      setOcrDetails({
        ...ocrDetails,
        loading: true,
        pictures: attachedFiles,
      });

      const claimRecord = await Request({
        method: 'post',
        route: 'api/claims/analyse-image',
        token: getToken(),
        values: {
          picturesId: attachedFilesId,
          ocrDetails,
          claim_id,
          data,
          policy_id: currentPetInfo.policyId,
          pet: currentPetInfo.nameOfPet,
        },
      }).then((res) => res.json());

      setOcrDetails(claimRecord.newOcrDetails);
    } catch (e) {
      console.error('Error: ', e);
      errorHandler(e);
    }
  }

  return { attachedFilesId };
}
