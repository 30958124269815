import Header from '../../components/common/Header';
import React from 'react';

export default function Page404() {
  return (
    <div className="main-container SlowShow">
      <Header />
      <div className="ErrorPage">
        <h1>Page not found</h1>
        <img src="/404/1.jpg" />
      </div>
    </div>
  );
}
