import {
  Box,
  FormControl,
  InputLabel,
  Input,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  Tooltip,
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateFlowGroup, updateFlowSteps } from '../../config/actions';

export default function FlowGroup() {
  const body = getComputedStyle(document.body);
  const primaryColor = body.getPropertyValue('--b-color-main-2').trim();
  const secondaryColor = body.getPropertyValue('--b-color-bg-main-1').trim();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const steps = useSelector((s) => s.flow.flowSteps);
  const [switched, setSwitched] = useState(false);

  const onGroup = (e, step) => {
    step.group = e.target.value;
    dispatch(updateFlowSteps([...steps]));
  };

  const onWidth = (e, step) => {
    step.width = e.target.value;
    dispatch(updateFlowSteps([...steps]));
  };

  const onTitle = (e, index, groupNum) => {
    steps.forEach((step) => {
      if (step.group === groupNum) {
        step.groupTitle = e.target.value;
      }
    });

    dispatch(updateFlowSteps([...steps]));
  };

  const callSteps = (steps, subFlow, color) => {
    return steps.map((step, index) => tableRow(index, step, subFlow, color));
  };

  const tableRow = (index, step, subFlow, color) => {
    let condition =
      [
        'new_claim',
        'type',
        'upload',
        'invoice_table',
        'mcq',
        'status',
        'payment',
      ].includes(steps[index - 1]?.code) ||
      [
        'new_claim',
        'type',
        'upload',
        'invoice_table',
        'mcq',
        'status',
        'payment',
      ].includes(step.code);
    return (
      <TableRow
        key={`row-${index}`}
        className="ItemizedView"
        style={{ background: color ? color : 'inherit' }}
      >
        <TableCell>{t(step.text)}</TableCell>

        <TableCell>{step.title}</TableCell>

        <TableCell>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">{t('group')}</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={step.group}
              onChange={(e) => onGroup(e, step)}
              label={t('group')}
              disabled={index === 0 && !subFlow}
            >
              {index === 0 || index === 1 ? (
                <MenuItem value={index + 1}>{index + 1}</MenuItem>
              ) : !condition ? (
                new Array(2).fill(1).map((i, ind) => (
                  <MenuItem key={ind} value={steps[index - 1].group + ind}>
                    {steps[index - 1].group + ind}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={steps[index - 1].group + 1}>
                  {steps[index - 1].group + 1}
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </TableCell>

        <TableCell>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">{t('width')}</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={step.width}
              onChange={(e) => onWidth(e, step)}
              label={t('group')}
              disabled={[
                'new_claim',
                'type',
                'upload',
                'invoice_table',
                'mcq',
                'status',
                'date_range',
                'payment',
              ].includes(step.code)}
            >
              <MenuItem value={'Half width'}>{t('halfWidth')}</MenuItem>
              <MenuItem value={'Full width'}>{t('fullWidth')}</MenuItem>
            </Select>
          </FormControl>
        </TableCell>
      </TableRow>
    );
  };

  const callStepsTitle = (steps) => {
    let groupList = new Set();
    steps.forEach((step) => {
      groupList.add(step.group);
    });
    let groupListArr = Array.from(groupList);
    let groupArr = groupListArr.map((el) => ({
      number: el,
      title: steps[steps.findIndex((step) => step.group === el)].groupTitle,
      disabled:
        steps.filter((step) => step.group === el).length > 1 ? false : true,
    }));

    return groupArr.map((group, index) => tableRowTitle(index, group));
  };

  const tableRowTitle = (index, group, color) => {
    return (
      <TableRow
        key={`row-${index}`}
        className="ItemizedView"
        style={{ background: color ? color : 'inherit' }}
      >
        <TableCell>{t('group') + ' ' + group.number}</TableCell>

        <TableCell>
          <FormControl
            sx={{
              padding: '0 !important',
              height: '56px !important',
              width: '100%',
            }}
            disabled={group.disabled}
          >
            <InputLabel
              id="demo-multiple-name-label-title"
              sx={{
                background: 'var(--background)',
                color: group.disabled ? 'darkred !important' : '',
              }}
            >
              {group.disabled ? t('disabled') : t('title')}
            </InputLabel>

            {group.disabled ? (
              <Tooltip
                title={
                  'You can add title for groups with more than one question.'
                }
                placement="top"
              >
                <Input
                  id="demo-multiple-name-title"
                  className="TitleInput TitleInputDisabled"
                  sx={{ margin: '0 !important', height: '100% !important' }}
                  disableUnderline
                />
              </Tooltip>
            ) : (
              <Input
                id="demo-multiple-name-title"
                className="TitleInput"
                type="text"
                value={group.title}
                onChange={(e) => onTitle(e, index, group.number)}
                sx={{
                  margin: '0 !important',
                  height: '100% !important',
                  paddingLeft: '10px',
                }}
                disableUnderline
              />
            )}
          </FormControl>
        </TableCell>
      </TableRow>
    );
  };

  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 160,
    height: 44,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 1,
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(94px)',
        '& .MuiSwitch-thumb': {
          backgroundColor: secondaryColor,
          '&:before': {
            content: `'${t('title')}'`, // Right label when switched on
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '16px',
            color: 'black',
            top: '0px',
          },
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: primaryColor,
          '&:before': {
            content: `'${t('group')}'`, // Left label when switched on
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            left: '10px',
            fontSize: '16px',
            color: '#fff',
          },
        },
      },
      '&:not(.Mui-checked)': {
        '& .MuiSwitch-thumb': {
          backgroundColor: secondaryColor,
          '&:before': {
            content: `'${t('group')}'`, // Left label when switched off
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '16px',
            color: 'black',
            top: '0px',
          },
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: primaryColor,
          '&:before': {
            content: `'${t('title')}'`, // Right label when switched off
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            left: '120px',
            fontSize: '16px',
            color: '#fff',
          },
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
      width: 62,
      height: 40,
      borderRadius: 10,
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      borderRadius: 20 / 2,
      position: 'relative',
    },
  }));

  return (
    <>
      <div className="FMHeader-wrapper">
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <button
            className="ButtonReverse Enabled"
            onClick={() => dispatch(updateFlowGroup(false))}
          >
            {t('goBack')}
          </button>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormControlLabel
              control={
                <MaterialUISwitch
                  sx={{ m: 1 }}
                  checked={switched}
                  onChange={() => setSwitched(!switched)}
                />
              }
            />
          </div>
          <div className="FMHeader" style={{ width: 'auto' }}>
            {t('group')}
          </div>
        </Box>
      </div>

      {switched ? (
        <TableContainer className="FMTable">
          {/* TABLE */}
          <Table
            className="SlowShow"
            sx={{
              border: 'none',
              width: '50%',
              margin: '0 auto 0 auto',
            }}
            stickyHeader
          >
            {/* TABLE HEAD */}
            <TableHead>
              <TableRow>
                <TableCell className="HeaderTableReverse">
                  {t('group')}
                </TableCell>
                <TableCell className="HeaderTableReverse">
                  {t('title')}
                </TableCell>
              </TableRow>
            </TableHead>

            {/* TABLE BODY */}
            {steps.length > 0 && <TableBody>{callStepsTitle(steps)}</TableBody>}
          </Table>
        </TableContainer>
      ) : (
        <TableContainer className="FMTable">
          {/* TABLE */}
          <Table
            className="SlowShow"
            sx={{ minWidth: 400, border: 'none' }}
            stickyHeader
          >
            {/* TABLE HEAD */}
            <TableHead>
              <TableRow>
                <TableCell className="HeaderTableReverse">
                  {t('type')}
                </TableCell>
                <TableCell className="HeaderTableReverse">
                  {t('question')}
                </TableCell>
                <TableCell className="HeaderTableReverse">
                  {t('group')}
                </TableCell>
                <TableCell className="HeaderTableReverse">
                  {t('width')}
                </TableCell>
              </TableRow>
            </TableHead>

            {/* TABLE BODY */}
            {steps.length > 0 && <TableBody>{callSteps(steps)}</TableBody>}
          </Table>
        </TableContainer>
      )}
    </>
  );
}
