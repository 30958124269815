import * as React from 'react';
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Datepicker from '../../../components/common/components/Datepicker';
import { isMobile, isMobileOnly } from 'react-device-detect';
import ModalWrapper from '../../../components/modalWrapper';
import moment from 'moment';
import Attach from '../../../assets/iconsSmall/paper_clip.svg';
import Remove from '../../../assets/iconsSmall/cross_black.svg';
import Upload from '../../../assets/iconsSmall/upload_icon.svg';

function ModalEditPD({
  openModalEditPD,
  setOpenModalEditPD,
  outerDialog = 'main',
  submitPD,
}) {
  const { t } = useTranslation();
  const [dialog, setDialog] = useState(outerDialog);
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [confirmEmail, setConfirmEmail] = useState('');
  const [date, setDate] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const headerLabels = {
    main: t('changeData'),
    changeBD: t('changeBD'),
    changeEmail: t('changeEmail'),
    confirm: t('changeData'),
    attachFiles: t('changeBD'),
  };

  const handleEmail = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(inputValue);
    setIsValidEmail(isValid);
  };

  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const files = event.target.files;
    const newFiles = Array.from(files).filter((file) => {
      return !selectedFiles.some(
        (selectedFile) => selectedFile.name === file.name
      );
    });
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    const newFiles = Array.from(files).filter((file) => {
      return !selectedFiles.some(
        (selectedFile) => selectedFile.name === file.name
      );
    });
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  function handleClose() {
    setOpenModalEditPD(false);
  }

  return (
    <ModalWrapper
      headerTitle={headerLabels[dialog]}
      openModal={openModalEditPD}
      setModalWrapper={() => setOpenModalEditPD(false)}
    >
      {dialog === 'main' && (
        <>
          <div className="modal_container__body__text">{t('whatChange')}</div>
          <div className="modal_container__body__buttons">
            <div
              className="modal_container__body__button"
              onClick={() => setDialog('changeEmail')}
            >
              {t('changeEmail')}
            </div>
            <div
              className="modal_container__body__button"
              onClick={() => setDialog('changeBD')}
            >
              {t('changeBD')}
            </div>
          </div>
          <div className={`button_red_small ${isMobileOnly && 'mobile'}`}>
            {t('Next')}
          </div>
        </>
      )}

      {dialog === 'changeBD' && (
        <>
          <div className="modal_container__body__text">{t('enterBD')}</div>
          <div className="modal_container__body__dropdowns">
            <Datepicker
              date={date}
              setDate={(newValue) => setDate(newValue)}
              disabled={false}
              type={'reset-dp-modal'}
            />
          </div>
          <div
            className={
              date !== null ? 'button_red_small' : 'button_red_smal_disabled'
            }
            onClick={
              date !== null
                ? () => setDialog('attachFiles')
                : (e) => e.stopPropagation()
            }
          >
            {t('Next')}
          </div>
        </>
      )}

      {dialog === 'changeEmail' && (
        <>
          <div className="modal_container__body__text">{t('enterEmail')}</div>
          <div className="modal_container__body__inputs">
            <input
              className="modal_container__body__input"
              style={isValidEmail ? {} : { border: '3px solid red' }}
              placeholder={t('yourNewEmail') + '*'}
              value={email}
              onChange={handleEmail}
            />
            {!isValidEmail && (
              <div className="modal_container__body__error FastShow">
                {t('emailWrong')}
              </div>
            )}
            <input
              className="modal_container__body__input"
              placeholder={t('confirmEmail') + '*'}
              value={confirmEmail}
              onChange={(e) => setConfirmEmail(e.target.value)}
            />
            {email.length === confirmEmail.length && email !== confirmEmail && (
              <div className="modal_container__body__error FastShow">
                {t('confirmEmailWrong')}
              </div>
            )}
          </div>
          <div
            className={
              isValidEmail && email === confirmEmail && email.length
                ? 'button_red_small'
                : 'button_red_smal_disabled'
            }
            onClick={
              isValidEmail && email === confirmEmail && email.length
                ? () => {
                  submitPD('email', email);
                  setDialog('confirm');
                }
                : (e) => e.stopPropagation()
            }
          >
            {t('Next')}
          </div>
        </>
      )}

      {dialog === 'attachFiles' && (
        <>
          <div
            className="modal_container__body__text"
            style={{ maxWidth: '540px' }}
          >
            {t('pleaseAttachCustomer')}
          </div>
          <div
            className="modal_container__body__uploader"
            style={{ maxWidth: '540px' }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={handleButtonClick}
          >
            <input
              type="file"
              ref={fileInputRef}
              // accept=".jpg,.png"
              onChange={handleFileChange}
              hidden
              multiple
            />
            <img src={Upload} />
            <span>{t('docUploadPlaceholder')}</span>
            <div
              className={`button_red_small ${isMobileOnly ? 'mobile' : ''}`}
              style={{
                width: 'unset',
                padding: isMobile ? '0 20px' : '0 39px',
              }}
            >
              {t('uploadDocument')}
            </div>
          </div>
          {selectedFiles.length ? (
            <div
              className="modal_container__body__text"
              style={{ maxWidth: '540px', margin: '0 0 15px 0' }}
            >
              {t('uploadedDocuments')}
            </div>
          ) : null}
          {selectedFiles.length
            ? selectedFiles.map((file, ind) => (
              <div
                key={ind}
                className="modal_container__body__attach"
                style={{ maxWidth: '440px', margin: '0 0 20px 0' }}
              >
                <img
                  src={Attach}
                  className="pointer"
                  width={isMobile ? 30 : 40}
                  alt="Attach"
                />
                <input
                  readOnly
                  className="modal_container__body__input-file"
                  placeholder={t('addDocument')}
                  value={file.name || ''}
                />
                <img
                  src={Remove}
                  alt="Remove"
                  style={{
                    position: 'absolute',
                    right: '12px',
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    setSelectedFiles((prevSelectedFiles) =>
                      prevSelectedFiles.filter((el) => el.name !== file.name)
                    )
                  }
                />
              </div>
            ))
            : null}
          <div
            className="modal_container__body__row-modal"
            style={{ margin: '10px 0 0 0' }}
          >
            <div
              className={
                selectedFiles.length
                  ? `button_red_small ${isMobileOnly ? 'mobile' : ''}`
                  : `button_red_smal_disabled ${isMobileOnly ? 'mobile' : ''}`
              }
              onClick={
                selectedFiles.length
                  ? () => {
                    submitPD(
                      'birthDay',
                      moment(date).format('YYYY-MM-DD'),
                      selectedFiles
                    );
                    setDialog('confirm');
                  }
                  : (e) => e.stopPropagation()
              }
            >
              {t('Next')}
            </div>
          </div>
        </>
      )}

      {dialog === 'confirm' && (
        <>
          <div className="modal_container__body__inputs">
            <div
              className="modal_container__body__text"
              style={{ textAlign: 'left' }}
            >
              {t('confirmChangeData_1')}
              <br />
              <br />
              {t('confirmChangeData_2')}
              <br />
              <br />
              {t('confirmChangeData_3')} &#x1F60A;
            </div>
          </div>
          <div
            className="button_red_small"
            onClick={() => {
              handleClose();
            }}
          >
            OK
          </div>
        </>
      )}
    </ModalWrapper>
  );
}

export default ModalEditPD;
